import React, { useEffect, useRef, useState } from "react";
import styles from "./TV.module.scss";
import { usePage } from "@inertiajs/react";
import NewsCard from "../../News/NewsCard/Index";
import logoValleSabbiaNewsTV from "./../../../../img/VallesabbianewsTV.png";

function ValleSabbiaNewsTV({ wrapperCSSClasses, ...props }) {
    const { locale } = usePage().props;

    const componentRef = useRef(); // Crea una ref per accedere all'elemento DOM del componente
    const [large, setLarge] = useState(false); // Utilizza lo stato per memorizzare se il componente è all'interno di <main> o <aside>

    useEffect(() => {
        const element = componentRef.current; // Ottiene l'elemento DOM corrente
        if (element) {
            // Verifica se l'elemento è contenuto all'interno di un <main> o <aside>
            setLarge(element.closest("main") !== null);
        }
    }, []);

    let data = props.data;

    if (props.data.keyType == "news") {
        data = data.data;
    } else if (props.data.keyType == "newsTags" || props.data.keyType == "newsGroups") {
        data = [].concat(...data.data.map((item) => item.news));
    } else {
        return <>Impossibile visualizzare le notizie ValleSabbiaNewsTV.</>;
    }

    const uniqueData = {};
    data.forEach((item) => {
        uniqueData[item.id] = item;
    });
    data = Object.values(uniqueData);

    return (
        <section
            ref={componentRef}
            className={`${large ? styles.TVWrapperLarge : styles.TVWrapper} ${wrapperCSSClasses}`}
        >
            <img src={logoValleSabbiaNewsTV} alt="ValleSabbia News TV" width={240} className="mb-3" />
            <ul className={`${large ? styles.TVWrapperGridLarge : styles.TVWrapperGrid}`}>
                {data.map((news, index) => (
                    <>
                        {index % 2 != 0 && large && <li className={`${styles.TVCardColSeparator}`}></li>}
                        <li className={`${styles.TVCardWrapper}`}>
                            <NewsCard horizontal={false} large={false} imageAspectRatio="3/2" data={news} />
                        </li>
                        {data.length - 1 !== index && !large && (
                            <li>
                                <hr className={`separatore ${styles.separatore}`} />
                            </li>
                        )}
                    </>
                ))}
            </ul>
        </section>
    );
}

export default ValleSabbiaNewsTV;
