import React from "react";
import styles from "./DailyWeather.module.scss";
import { usePage } from "@inertiajs/react";
import DateUtils from "../../../Utils/DateUtils";

function DailyWeather({ weather }) {
    const { locale } = usePage().props;

    if (!Array.isArray(weather)) {
        return <div>Nessuna informazione meteo disponibile al momento.</div>;
    }

    const getTitle = (item) => {
        if (item && item.title) {
            return item.title[locale] || item.title;
        }
        return "";
    };

    const weatherLabels = ["Mattina", "Pomeriggio", "Sera", "Temperatura Min", "Temperatura Max"];

    const weatherRows = weather.map((weatherItem, index) => {
        if (!weatherItem) return null;

        const formattedDate = DateUtils.formatDataDayOfTheWeek(weatherItem.day);
        const components = formattedDate.split(" ");
        const dayOfWeek = components[0];
        const dayOfMonth = components[1];
        const month = components[2];

        return (
            <div className={index === 0 ? "mt-4 mt-lg-5" : ""}>
                <div className={styles.grid} key={index}>
                    <div className={`${styles.day}`}>
                        <h5 className="pb-2">
                            <span className={styles.dayOfWeek}>{dayOfWeek}</span>
                            &nbsp;
                            <span className={styles.dayOfMonth}>{dayOfMonth}</span>
                            &nbsp;<span className={styles.month}>{month}</span>
                        </h5>
                    </div>

                    {[
                        "climate_morning",
                        "climate_afternoon",
                        "climate_evening",
                        "min_temperature",
                        "max_temperature",
                    ].map((timeOfDay, index) => {
                        const weatherClimate = weatherItem[`weather_${timeOfDay}`];
                        if (!weatherClimate || !weatherClimate.image || weatherClimate.image.length === 0) {
                            return null;
                        }
                        return (
                            <div className="text-lg-center" key={timeOfDay}>
                                <h6 className={`pb-2 ${styles.titolo}`}>{weatherLabels[index]}</h6>
                                <img
                                    src={weatherClimate.image[0].url}
                                    alt=""
                                    className={`svg-with-shadow mt-2 mb-1 ${styles.img}`}
                                    conversions={weatherClimate.image[0].conversions}
                                    data={weatherClimate.image[0].data}
                                />
                                <p className="font-sans">
                                    <small className={styles.meteoTesto}>{getTitle(weatherClimate)}</small>
                                </p>
                            </div>
                        );
                    })}
                </div>
                {index < weather.length - 1 && <hr className="separatore" />}
            </div>
        );
    });

    return <div className="dailyWeather mb-4">{weatherRows}</div>;
}

export default DailyWeather;
