import RedazioneList from '../Components/About/RedazioneList/Index';
import AdvertisementBanner from '../Components/Advertising/Banner';
import DossierLinkList from '../Components/Dossier/DossierLinkList/Index';
import EventsCalendar from '../Components/Event/EventsCalendar/Index';
import NewsCard from '../Components/News/NewsCard/Index';
import ValleSabbiaNewsCasa from '../Components/ValleSabbiaNews/Casa/Index';
import ValleSabbiaNewsLavoro from '../Components/ValleSabbiaNews/Lavoro/Index';
import ValleSabbiaNewsTV from '../Components/ValleSabbiaNews/TV/Index';
import NewsCategory from '../Components/News/NewsCategory/Index';
import PartnersLinkList from '../Components/Partner/PartnersLinkList/Index'
import BlogLinkList from '../Components/Blog/BlogLinkList/Index';
import EventsDailyList from "../Components/Event/EventsDailyList/Index";
import NewsTopTen from '../Components/News/NewsTopTen/Index';
import GalleryDailyPictureSidebar from '../Components/Gallery/GalleryDailyPictureSidebar/Index';
import BasicImage from '../Components/Images/BasicImage/Index';
import Popup from '../Components/Popup/Index';
import BannerOnlyContent from '../Components/BannerOnlyContent/Index';
import AdSense from '../Components/AdSense/Index';
import Book from '../Components/Book/Index';

export const componentFactory = () => {
  return {
    'AdvertisementBanner': AdvertisementBanner,
    'ValleSabbiaNewsCasa': ValleSabbiaNewsCasa,
    'ValleSabbiaNewsLavoro': ValleSabbiaNewsLavoro,
    'ValleSabbiaNewsTV': ValleSabbiaNewsTV,
    'RedazioneList': RedazioneList,
    'NewsCard': NewsCard,
    'DossierLinkList': DossierLinkList,
    'NewsCategory': NewsCategory,
    'EventsCalendar': EventsCalendar,
    'PartnersLinkList': PartnersLinkList,
    'BlogLinkList': BlogLinkList,
    'EventsDailyList': EventsDailyList,
    'NewsTopTen': NewsTopTen,
    'GalleryDailyPictureSidebar': GalleryDailyPictureSidebar,
    'BasicImage': BasicImage,
    'Popup': Popup,
    'BannerOnlyContent': BannerOnlyContent,
    'AdSense': AdSense,
    'Book': Book
  }
}