import React from "react";
import styles from "./UserProfile.module.scss";

import DateUtils from "../../../Utils/DateUtils";

export default function UserProfile({ user }) {
	return (
		<div className={`${styles.profile} text-center`}>
			{user?.profile_photo_url && <img src={user.profile_photo_url} alt="Foto profilo" />}
			<h2 className="h4 mt-2">
				{user?.name} {user?.surname}
			</h2>
			<p className="font-sans mt-2">
				Iscritto dal {DateUtils.convertDateFromString(user.created_at, false)}
				{user.email && (
					<>
						<br />
						via mail <strong>{user.email}</strong>
					</>
				)}
			</p>
		</div>
	);
}
