import React from "react";
import styles from "./Banner.module.scss";
import { usePage } from "@inertiajs/react";
import GTrackUtils from "../../Utils/GTrackUtils";

const AdvertisementBanner = ({ ...props }) => {
    const { locale } = usePage().props;

    const { shouldIncludeDataGTrack, gTrackAction } = GTrackUtils.generateGTrackAction(
        props.data?.custom_fields?.gTrack_click,
        props.data?.custom_fields?.gTrack_view
    );

    return (
        <a
            className={`${styles.advertisementBanner} ${props?.wrapperCSSClasses}`}
            target="_blank"
            href={props.data?.url?.[locale]}
            {...shouldIncludeDataGTrack && {
                'data-gtrack': true,
                'data-gtrack-id': props.data.id,
                'data-gtrack-type': 'core_banner',
                'data-gtrack-action': gTrackAction,
                'data-gtrack-link': props.data?.url?.[locale],
            }}
        >
            <img
                src={props.data?.media1 && props.data?.media1?.[0]?.url}
                alt="Banner"
                className={styles.advertisementBannerImage}
            />
        </a>
    );
};

export default AdvertisementBanner;