import React, { useState, useEffect, useRef } from "react";
import styles from "./SearchBox.module.scss";
import { usePage } from "@inertiajs/react";
import SVG from "../../../Utils/SVG";
import FormUtils from "../../../Utils/FormUtils";

const SearchBox = ({ setInputRef }) => {
	const { locale, modulesRoutes } = usePage().props;

	const [showSearchBox, setShowSearchBox] = useState(false);
	const inputRef = useRef();

	useEffect(() => {
		setInputRef(inputRef.current);
	}, [setInputRef]);

	useEffect(() => {
		if (showSearchBox) {
			FormUtils.focusAndOpenKeyboard(inputRef.current);
		}
	}, [showSearchBox]);

	const handleClick = () => {
		setShowSearchBox(!showSearchBox);
	};

	return (
		<li>
			<div
				onClick={handleClick}
				className={`${styles.searchButtonWrapper} ${showSearchBox ? styles.searchClose : styles.searchOpen}`}
			>
				<div dangerouslySetInnerHTML={{ __html: showSearchBox ? SVG.close : SVG.search }} />
			</div>

			<div className={`${styles.searchbarWrapper} ${showSearchBox ? styles.show : ""}`}>
				<div className={`container ${styles.searchbar}`}>
					<form className={`${styles.searchbarForm}`} action={`${modulesRoutes.search[locale]}`}>
						<input
							ref={inputRef}
							type="text"
							name="keyword"
							className="form-control"
							placeholder="Cerca in ValleSabbiaNews"
						/>
						<button aria-label="Cerca">
							<div dangerouslySetInnerHTML={{ __html: SVG.search }} />
						</button>
					</form>
				</div>
			</div>
		</li>
	);
};

export default SearchBox;
