import React, { useState, useEffect } from "react";
import styles from "./RedazioneList.module.scss";
import RedazioneCard from "../RedazioneCard/Index";
import { usePage } from "@inertiajs/react";
import axios from "axios";
import CacheUtils from "../../../Utils/CacheUtils";
import GTrackUtils from "../../../Utils/GTrackUtils";

//const CACHE_DURATION = 60 * 60 * 1000 * 24 * 7; // Durata della cache in millisecondi (24 ore * 7 giorni)
const CACHE_DURATION = 60 * 1000 * 2; // 2 minuti di cache

const RedazioneList = ({ ...props }) => {
	const { locale } = usePage().props;
	const redazioneListIDs = props.data?.components?.[0]?.banner || [];
	const [redazione, setRedazione] = useState([]);
	const redazioneListTitle = props?.data?.title?.[locale] || props?.data?.name;

	const { shouldIncludeDataGTrack, gTrackAction } = GTrackUtils.generateGTrackAction(
		props.data?.custom_fields?.gTrack_click,
		props.data?.custom_fields?.gTrack_view
	);

	useEffect(() => {
		const cancelTokenSource = axios.CancelToken.source();

		Promise.all(redazioneListIDs.map((id) => CacheUtils.fetchBanner(id, CACHE_DURATION))).then((responses) => {
			const banners = responses.map((response) => response.data);
			setRedazione(banners);
		});
		/*.catch(error => {
                if (axios.isCancel(error)) {
                    console.log('Richiesta annullata', error.message);
                } else {
                    console.error('Si è verificato un errore:', error);
                }
            });*/

		return () => {
			cancelTokenSource.cancel("Componente smontato");
		};
	}, [redazioneListIDs]);

	const linkElements = redazione.map((link, index) => {
		return (
			<>
				<li key={`RedazioneCard-${index}`}>
					<RedazioneCard data={link} />
				</li>
				<li>
					<hr className="my-4" />
				</li>
			</>
		);
	});

	return (
		<div
			{...(shouldIncludeDataGTrack && {
				"data-gtrack": true,
				"data-gtrack-id": props.data.id,
				"data-gtrack-type": "core_banner",
				"data-gtrack-action": gTrackAction,
			})}
		>
			{/* La redazione */}
			<h3 className="my-4">{redazioneListTitle}</h3>
			<ul>{linkElements}</ul>

			{/* Elenco collaboratori */}
			<h3 className="my-4">{props?.data?.summary?.[locale]}</h3>
			<div
				className={`font-serif-alt ${styles.collaboratoriGrid}`}
				dangerouslySetInnerHTML={{
					__html: props?.data?.content?.[locale],
				}}
			/>
		</div>
	);
};

export default RedazioneList;
