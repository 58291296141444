import React from "react";
import PagiantionUtils from "../../Utils/PaginationUtils";
import SVG from "../../Utils/SVG";

function Pagination({ currentPage, totalPages, onPageChange, ...props }) {
	// Non ritorna nulla se ci sono meno di 2 pagine totali
	if (totalPages < 2) return null;

	const paginationItems = PagiantionUtils.generatePagination(currentPage, totalPages);

	return (
		<nav aria-label="Paginazione">
			<ul className="pagination font-sans justify-content-center">
				{/** Freccia indietro */}
				<li className="page-item">
					<a
						className={`page-link ${currentPage === 1 ? "disabled" : ""}`}
						href={`?p=${Math.max(1, currentPage - 1)}`}
						aria-label="Precedente"
						//onClick={() => onPageChange(Math.max(1, currentPage - 1))}
					>
						<div dangerouslySetInnerHTML={{ __html: SVG.chevronLeft }} />
					</a>
				</li>

				{/** Numeri pagine */}
				{paginationItems.map((item, index) => {
					if (item === "...") {
						// Se l'item è '...', lo rende come un elemento di testo non cliccabile
						return (
							<li key={index} className="page-item page-item-dotted">
								<span className="page-link">{item}</span>
							</li>
						);
					} else {
						// Altrimenti lo rende come un link
						return (
							<li key={index} className={`page-item ${item === currentPage ? "active" : ""}`}>
								<a
									className="page-link"
									href={`?p=${item}`}
									onClick={(e) => {
										if (item === currentPage) e.preventDefault();
										onPageChange(item);
									}}
								>
									{item}
								</a>
							</li>
						);
					}
				})}

				{/** Freccia avanti */}
				<li className="page-item">
					<a
						className={`page-link ${currentPage === totalPages ? "disabled" : ""}`}
						href={`?p=${Math.min(totalPages, currentPage + 1)}`}
						aria-label="Successiva"
						//onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
					>
						<div dangerouslySetInnerHTML={{ __html: SVG.chevronRight }} />
					</a>
				</li>
			</ul>
		</nav>
	);
}

export default Pagination;
