import React from "react";
import DossierLink from "../DossierLink/Index";
import styles from "./DossierLinkList.module.scss";
import { usePage } from "@inertiajs/react";

function DossierLinkList({ wrapperCSSClasses, ...props }) {
    const { locale } = usePage().props;

    let newsGroups = props.data.data;
    let dossierLinkListTitle = props.data?.component?.["title_" + locale];

    const linkElements = newsGroups?.map((newsGroup, index) => (
        <li key={index}>
            <DossierLink
                href={newsGroup?.group?.url?.[locale].url}
                title={newsGroup?.group?.title[locale] ? newsGroup?.group?.title[locale] : newsGroup?.group?.name}
                countArticles={newsGroup?.totalNews || newsGroup?.news.length}
            />
        </li>
    ));

    return (
        <section className={`${styles.dossierLinkListWrapper} ${wrapperCSSClasses} mb-4`}>
            <h3 className="text-uppercase fw-bold mb-3 h5 font-sans">{dossierLinkListTitle}</h3>
            <ul className={styles.DossierLinkList}>{linkElements}</ul>
        </section>
    );
}

export default DossierLinkList;
