import axios from "axios";
import { useRefreshProps } from "../Hooks";
import CookieUtils from "./CookieUtils";

const PrivateAreaUtils = {
    user: {
        logout: async () => {
            try {
                const device = CookieUtils.getCookie("device");
                const requestBody = { device: device };
                const response = await axios.post("/api/auth/logout", requestBody);
                if (response.data.result) {
                    useRefreshProps("*", 0, true); // Aggiorno le props di pagina per "confermare" il logout dell'utente
                    // Verifico se "userAddresses" esiste nel sessionStorage e lo elimino
                    if (sessionStorage.getItem("userAddresses")) {
                        sessionStorage.removeItem("userAddresses");
                    }
                }
            } catch (error) {
                console.error("Errore nel logout:", error);
            }
        },
        update: async (userId, userData) => {
            try {
                const response = await axios.put(`/api/users/${userId}/update`, userData);
                return response.data;
            } catch (error) {
                console.error("Errore nell'aggiornamento dell'utente:", error);
                return null;
            }
        },
    },
    device: {
        getAll: async () => {
            try {
                const response = await axios.get("/api/users/devices/active");
                return response.data.result ? response.data.data : [];
            } catch (error) {
                console.error("Errore nel recupero dei dispositivi:", error);
                return [];
            }
        },
        remove: async (deviceID) => {
            try {
                const response = await axios.delete(`/api/users/devices/${deviceID}/remove`);
                return response.data.result;
            } catch (error) {
                console.error("Errore nella rimozione del dispositivo:", error);
                return false;
            }
        },
    },
    subscription: {
        getAll: async (excludeDefaultFreeSubscription = true) => {
            try {
                const response = await axios.get("/api/private/package/get/all");
                let subscriptions = response.data.result ? response.data.data : [];
                return excludeDefaultFreeSubscription
                    ? subscriptions.filter((subscription) => !subscription.is_default)
                    : subscriptions;
            } catch (error) {
                console.error("Errore nel recupero degli abbonamenti:", error);
                return [];
            }
        },
        get: async (subscriptionID) => {
            try {
                const response = await axios.get(`/api/private/package/${subscriptionID}/get`);
                return response.data.result ? response.data.data : null;
            } catch (error) {
                console.error("Errore nel recupero dell'abbonamento:", error);
                return null;
            }
        },
        log: async (requestBody, paymentMethod) => {
            const genericError = {
                result: false,
                error: "Errore nella sottoscrizione dell'abbonamento",
                error_description:
                    "Non è stato possibile completare la sottoscrizione del tuo abbonamento. Si prega di riprovare più tardi.",
            };
            console.log("PayPal LOG request");
            console.log("requestBody", requestBody);
            let config = {};
            if (
                paymentMethod === "paypal" ||
                paymentMethod === "paypal_creditcard_subscription" ||
                paymentMethod === "paypal_subscription"
            ) {
                console.log("Tipologia pagamento PayPal");
                paymentMethod = "paypal";
            }
            try {
                const response = await axios.post(
                    `/api/payment/${paymentMethod}/subscription/log`,
                    requestBody,
                    config
                );
                return response.data;
            } catch (error) {
                console.error("Errore nel recupero dell'abbonamento:", error);
                return genericError;
            }
        },
        payPalApprove: async (requestBody) => {
            console.log("payPalApprove() called");
            const genericError = {
                result: false,
                error: "Errore nella sottoscrizione dell'abbonamento",
                error_description:
                    "Non è stato possibile completare la sottoscrizione del tuo abbonamento. Si prega di riprovare più tardi.",
            };
            console.log("PayPal onApprove request");
            console.log("requestBody", requestBody);
            let config = {};

            try {
                const response = await axios.post(`/api/payment/paypal/subscription/create`, requestBody, config);
                return response.data;
            } catch (error) {
                console.error("Errore nel recupero dell'abbonamento:", error);
                return genericError;
            }
        },
        cancel: async (subscriptionID, paymentMethod) => {
            const genericError = {
                result: false,
                error: "Errore nella cancellazione dell'abbonamento",
                error_description:
                    "Non è stato possibile completare la cancellazione del tuo abbonamento. Si prega di riprovare più tardi.",
            };

            paymentMethod = paymentMethod.toLowerCase();

            let config = {};
            let extraRequestBody = {};
            let requestBody = { ...extraRequestBody };

            if (
                paymentMethod === "paypal" ||
                paymentMethod === "paypal_creditcard_subscription" ||
                paymentMethod === "paypal_subscription"
            ) {
                console.log("Tipologia pagamento PayPal");
                paymentMethod = "paypal";
            } else if (paymentMethod === "nexi" || paymentMethod === "nexi_subscription") {
                paymentMethod = "nexi";
                console.log("Tipologia pagamento Nexi");
            } else if (paymentMethod == "rid") {
                console.log("Tipologia pagamento RID");
            } else if (paymentMethod == "satispay" || paymentMethod === "satispay_subscription") {
                paymentMethod = "satispay";
                console.log("Tipologia pagamento Satispay");
            }

            console.log("requestBody", requestBody);

            try {
                const response = await axios.post(
                    `/api/payment/${paymentMethod}/subscription/${subscriptionID}/cancel`,
                    requestBody,
                    config
                );
                return response.data;
            } catch (error) {
                console.error("Errore nel recupero dell'abbonamento:", error);
                return genericError;
            }
        },
        create: async (subscriptionID, paymentMethod, subscriptionExtraData = {}) => {
            const genericError = {
                result: false,
                error: "Errore nella sottoscrizione dell'abbonamento",
                error_description:
                    "Non è stato possibile completare la sottoscrizione dell'abbonamento. Si prega di riprovare più tardi.",
            };

            console.log("subscriptionID", subscriptionID);
            console.log("paymentMethod", paymentMethod);
            console.log("subscriptionExtraData", subscriptionExtraData);

            paymentMethod = paymentMethod.toLowerCase();

            let config = {};
            let requestBody = { plan_id: subscriptionID, ...subscriptionExtraData };

            if (
                paymentMethod === "paypal" ||
                paymentMethod === "paypal_creditcard_subscription" ||
                paymentMethod === "paypal_subscription"
            ) {
                paymentMethod = "paypal";
                console.log("Tipologia pagamento PayPal");
            } else if (paymentMethod === "nexi" || paymentMethod === "nexi_subscription") {
                paymentMethod = "nexi";
                console.log("Tipologia pagamento Nexi");
            } else if (paymentMethod === "rid") {
                console.log("Tipologia pagamento RID");
            } else if (paymentMethod === "satispay_subscription") {
                console.log("Tipologia pagamento Satispay");
                paymentMethod = "satispay";
            }

            try {
                const response = await axios.post(
                    `/api/payment/${paymentMethod}/subscription/create`,
                    requestBody,
                    config
                );
                return response.data;
            } catch (error) {
                console.error("Errore nel recupero dell'abbonamento:", error);
                return genericError;
            }
        },
        /**
         * Tenta di verificare il successo del pagamento via Satispay ripetutamente fino al successo o al raggiungimento del numero massimo di tentativi.
         *
         * @param {string} subscriptionCode Il codice della sottoscrizione che identifica univocamente la transazione di pagamento con Satispay. Questo codice corrisponde al valore di 'id' ottenuto dalla risposta di Satispay quando un pagamento viene pre-autorizzato e intercettato dal backend come $preAuthorizedPaymentToken->id.
         * @returns {Promise<boolean>} Una promessa che risolve a true se l'operazione ha successo, altrimenti rifiuta con un errore.
         */
        checkSatispaySubscriptionSuccess: async (subscriptionCode) => {
            const maxAttempts = 50; // Numero massimo di tentativi
            const interval = 5000; // Intervallo tra i tentativi in millisecondi - (5s * 50) ~ 4min

            for (let attempt = 1; attempt <= maxAttempts; attempt++) {
                try {
                    const response = await axios.get(
                        `/api/payment/satispay/subscription/${subscriptionCode}/checksuccess`
                    );
                    //console.log("checkSuccess response:", response);
                    if (response.data.result) {
                        console.log("Operazione completata con successo.");
                        return true;
                    }
                    // Se l'API risponde ma non indica successo, si attende prima del prossimo tentativo
                    //console.log(`Operazione non ancora riuscita, tentativo ${attempt} di ${maxAttempts}.`);
                } catch (error) {
                    console.error(`Errore nel tentativo ${attempt}: ${error.message}`);
                }

                // Aspetta per l'intervallo specificato prima di procedere al prossimo tentativo, se non siamo all'ultimo tentativo
                if (attempt < maxAttempts) {
                    await new Promise((resolve) => setTimeout(resolve, interval));
                }
            }

            throw new Error("La sessione di pagamento è scaduta. Si prega di aggiornare la pagina e riprovare.");
        },
    },
    address: {
        getByID: async (privateUserID) => {
            try {
                const response = await axios.get(`/api/private/address/${privateUserID}/get`);
                return response.data.result ? response.data.data : null;
            } catch (error) {
                console.error("Errore nel recupero degli indirizzi:", error);
                return null;
            }
        },
        getAll: async (privateUserID) => {
            try {
                const response = await axios.get(`/api/users/${privateUserID}/address/get`);
                return response.data.result ? response.data.data : [];
            } catch (error) {
                console.error("Errore nel recupero degli indirizzi:", error);
                return [];
            }
        },
        getDefaultAddress: async (addresses) => {
            if (addresses && addresses.length > 0) {
                // Se viene fornito un array di indirizzi, cerca l'indirizzo di default
                return addresses.find((address) => address.is_default === 1) || null;
            } else {
                // Se non viene fornito alcun array, chiama l'API per ottenere l'indirizzo di default
                try {
                    const response = await axios.get("/api/users/address/default");
                    if (response.data && response.data.result) {
                        return response.data.data;
                    } else {
                        console.error("No default address found:", response);
                        return null;
                    }
                } catch (error) {
                    console.error("Error fetching default address:", error);
                    return null;
                }
            }
        },
        setDefaultAddress: async (addressID) => {
            try {
                const response = await axios.put(`/api/users/address/default/${addressID}`);
                return response.data;
            } catch (error) {
                console.error("Errore nell'impostazione dell'indirizzo di default:", error);
                return null;
            }
        },
        store: async (addressData) => {
            try {
                const response = await axios.post("/api/users/addresses", addressData);
                return response.data;
            } catch (error) {
                console.error("Errore nel salvataggio dell'indirizzo:", error);
                return null;
            }
        },
        update: async (addressID, addressData) => {
            try {
                const response = await axios.put(`/api/users/addresses/${addressID}`, addressData);
                return response.data;
            } catch (error) {
                console.error("Errore nell'aggiornamento dell'indirizzo:", error);
                return null;
            }
        },
        remove: async (addressID) => {
            try {
                const response = await axios.delete(`/api/users/addresses/${addressID}`);
                return response.data;
            } catch (error) {
                console.error("Errore nella rimozione dell'indirizzo:", error);
                return null;
            }
        },
        saveToLocalStorage: (key, data) => {
            const item = {
                value: data,
                expiry: new Date().getTime() + 30 * 24 * 60 * 60 * 1000, // 30 giorni
            };
            localStorage.setItem(key, JSON.stringify(item));
        },
        loadFromLocalStorage: (key) => {
            const itemStr = localStorage.getItem(key);
            if (!itemStr) return null;

            const item = JSON.parse(itemStr);
            const now = new Date().getTime();

            if (now > item.expiry) {
                localStorage.removeItem(key);
                return null;
            }

            return item.value;
        },
        fetchAddressPartFromApi: async (endpoint) => {
            try {
                const response = await axios.get(endpoint);
                return response.data.result ? response.data.data : [];
            } catch (error) {
                console.error(`Errore nel recupero dei dati da ${endpoint}:`, error);
                return null;
            }
        },
        getAllCities: async () => {
            let cities = PrivateAreaUtils.address.loadFromLocalStorage("cities");
            if (!cities) {
                cities = await PrivateAreaUtils.address.fetchAddressPartFromApi("/api/cities/get/all");
                PrivateAreaUtils.address.saveToLocalStorage("cities", cities);
            }
            return cities;
        },
        getCityNameById: async (id) => {
            let cities = await PrivateAreaUtils.address.getAllCities();
            const city = cities.find((city) => city.id === id);
            return city ? city.name : "";
        },
        getAllProvinces: async () => {
            let provinces = PrivateAreaUtils.address.loadFromLocalStorage("provinces");
            if (!provinces) {
                provinces = await PrivateAreaUtils.address.fetchAddressPartFromApi("/api/provinces/get/all");
                PrivateAreaUtils.address.saveToLocalStorage("provinces", provinces);
            }
            return provinces;
        },
        getProvinceNameById: async (id) => {
            let provinces = await PrivateAreaUtils.address.getAllProvinces();
            const province = provinces.find((province) => province.id === id);
            return province ? province.name : "";
        },
        getAllRegions: async () => {
            let regions = PrivateAreaUtils.address.loadFromLocalStorage("regions");
            if (!regions) {
                regions = await PrivateAreaUtils.address.fetchAddressPartFromApi("/api/regions/get/all");
                PrivateAreaUtils.address.saveToLocalStorage("regions", regions);
            }
            return regions;
        },
        getRegionNameById: async (id) => {
            let regions = await PrivateAreaUtils.address.getAllRegions();
            const region = regions.find((region) => region.id === id);
            return region ? region.name : "";
        },
        getCountryNameById: async (id) => {
            let countries = PrivateAreaUtils.address.loadFromLocalStorage("countries");
            if (!countries) {
                countries = await PrivateAreaUtils.address.fetchAddressPartFromApi("/api/countries/get/all");
                PrivateAreaUtils.address.saveToLocalStorage("countries", countries);
            }
            const country = countries.find((country) => country.id === id);
            return country ? country.name : "";
        },
        getRegionsByCountryId: async (countryId) => {
            let regions = await PrivateAreaUtils.address.getAllRegions();
            let filteredRegions = regions.filter((region) => region.core_country_id === Number(countryId));
            return filteredRegions;
        },
        getProvincesByRegionId: async (regionId) => {
            let provinces = await PrivateAreaUtils.address.getAllProvinces();
            let filteredProvinces = provinces.filter((province) => province.core_region_id === Number(regionId));
            return filteredProvinces;
        },
        getCitiesByProvinceId: async (provinceId) => {
            let cities = await PrivateAreaUtils.address.getAllCities();
            let filteredCities = cities.filter((city) => city.core_province_id === Number(provinceId));
            return filteredCities;
        },
        format: async (address, options = {}) => {
            const {
                showCity = true,
                showProvince = true,
                showRegion = true,
                showCountry = true,
                showAddress = true,
                showCap = true,
            } = options;

            const city = showCity ? await PrivateAreaUtils.address.getCityNameById(address.core_city_id) : "";
            const province = showProvince
                ? await PrivateAreaUtils.address.getProvinceNameById(address.core_province_id)
                : "";
            const region = showRegion ? await PrivateAreaUtils.address.getRegionNameById(address.core_region_id) : "";
            const country = showCountry
                ? await PrivateAreaUtils.address.getCountryNameById(address.core_country_id)
                : "";

            const parts = [
                showAddress ? address.address || "" : "",
                showCap ? address.cap || "" : "",
                city,
                province ? `(${province})` : "",
                region,
                country,
            ];

            return parts.filter((part) => part).join(" ");
        },
    },
    payment: {
        getAll: async (privateUserID) => {
            try {
                const response = await axios.get(`/api/private/payment/${privateUserID}/get`);
                return response.data.result ? response.data.data : [];
            } catch (error) {
                console.error("Errore nel recupero dei pagamenti:", error);
                return [];
            }
        },
    },
};

export default PrivateAreaUtils;
