import React, { useEffect } from "react";
import HeadSEO from "../../Components/Head/Index";
//import styles from './Home.module.scss'
import Layout from "../../Layout/Main";
import NewsCard from "../../Components/News/NewsCard/Index";
import NewsCategory from "../../Components/News/NewsCategory/Index";
import { componentFactory } from "../../Utils/ComponentsFactory";
import ComponentsUtils from "../../Utils/ComponentsUtils";
import { useRefreshProps } from "../../Hooks";
import SEOUtils from "../../Utils/SEOUtils";

function Home(props) {
	const { page, locale, components, coreBannersAllPage, userLogged, ziggy } = props;

	const componentMap = componentFactory();
	const zones = ["main", "asideTop", "asideMiddle", "asideBottom", "popup"];
	const FINAL_COMPONENTS = ComponentsUtils.getComponents(components, coreBannersAllPage, zones);

	{
		/** Aggiorna le props principali una volta ogni 5 minuti */
	}
	useEffect(() => {
		useRefreshProps(["components", "coreBannersAllPage"], 300000);
	}, []);

	let newsCount = 0;

	const main = (
		<>
			{/** Componenti main */}
			{FINAL_COMPONENTS.main?.map((componentObj, indexMain) => {
				{
					/* News */
				}
				if (componentObj.keyType === "news") {
					// Verifica se esiste la chiave componentObj.component.template ed è uguale a "ValleSabbiaNewsTV"
					if (componentObj.component && componentObj.component.template === "ValleSabbiaNewsTV") {
						return ComponentsUtils.renderComponent(
							componentObj,
							componentMap,
							"",
							`ValleSabbiaNewsTV-Main-${indexMain}`
						);
					}
					return componentObj?.data?.map((currentNews, index) => {
						let isHorizontal = newsCount !== 0;
						let imageAspectRatio = newsCount === 0 ? "2/0.95" : "3/2";
						newsCount++;
						return (
							<>
								<NewsCard
									key={`NewsCardMain-${indexMain}-${index}`}
									horizontal={isHorizontal}
									large={true}
									imageAspectRatio={imageAspectRatio}
									data={currentNews}
								/>
								{/* Separatore */}
								<hr className="separatore"></hr>
							</>
						);
					});
				}

				{
					/* Banners */
				}
				if (componentObj.keyType === "banners") {
					return ComponentsUtils.renderComponent(
						componentObj,
						componentMap,
						"",
						`BannerMain-${indexMain}`,
						undefined,
						<hr className="separatore"></hr>,
						userLogged
					);
				}

				{
					/* News Tags */
				}
				if (componentObj.keyType === "newsTags") {
					return componentObj?.data?.map((newsTag, index) => (
						<NewsCategory wrapperCSSClasses="mt-4 mb-4" data={newsTag} key={`newsTags-${index}`} />
					));
				}

				{
					/* News Groups */
				}
				if (componentObj.keyType === "newsGroups") {
					return ComponentsUtils.renderComponent(componentObj, componentMap, "", `NewsGroups-${indexMain}`);
				}

				return null;
			})}
		</>
	);

	const aside = (
		<>
			{/** Componenti asideTop */}
			{FINAL_COMPONENTS.asideTop?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					`asideTop-${index}`,
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}

			{/** Componenti asideMiddle */}
			{FINAL_COMPONENTS.asideMiddle?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					`asideMiddle-${index}`,
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}

			{/** Componenti asideBottom */}
			{FINAL_COMPONENTS.asideBottom?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					`asideBottom-${index}`,
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}
		</>
	);

	const seoData = SEOUtils.generateSEOData({
		page: page,
		locale: locale,
		type: "home",
		ziggy: ziggy,
		otherProps: {},
	});

	return (
		<>
			<HeadSEO data={seoData} />
			<Layout {...props} main={main} aside={aside} />
			{/** Componenti popup */}
			{ComponentsUtils.renderPopupComponents(FINAL_COMPONENTS.popup, componentMap)}
		</>
	);
}

export default Home;
