import React, { useState, useEffect } from "react";
import styles from "./NewsCategory.module.scss";
import Layout from "../../Layout/Main";
import NewsCard from "../../Components/News/NewsCard/Index";
import HeadSEO from "../../Components/Head/Index";
import SVG from "../../Utils/SVG";
import SmartImage from "../../Components/Images/SmartImage/Index";
import Swiper, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipeDynamicCaption from "photoswipe-dynamic-caption-plugin";
import "photoswipe/dist/photoswipe.css";
import "photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css";
import ImageUtils from "../../Utils/ImageUtils";
import { componentFactory } from "../../Utils/ComponentsFactory";
import ComponentsUtils from "../../Utils/ComponentsUtils";
import Pagination from "../../Components/Pagination/Index";
import { useRefreshProps } from "../../Hooks";
import { usePage, router } from "@inertiajs/react";
import SEOUtils from "../../Utils/SEOUtils";

function NewsCategory(props) {
	const { page, locale, components, coreBannersAllPage, magazineGroup, userLogged, ziggy } = props;
	const { url } = usePage();
	const { config } = usePage().props;

	const [currentPage, setCurrentPage] = useState(1);
	const [pagesData, setPagesData] = useState({ 1: magazineGroup.news.magazineNews });

	const componentMap = componentFactory();
	const zones = ["newsTop", "newsMiddle", "newsBottom", "asideTop", "asideMiddle", "asideBottom"];
	const FINAL_COMPONENTS = ComponentsUtils.getComponents(components, coreBannersAllPage, zones);

	const urlObj = new URL(url, ziggy.url);
	const pageParam = urlObj.searchParams.get("p") || null;

	{
		/** Aggiorna le props principali una volta ogni 5 minuti */
	}
	useEffect(() => {
		useRefreshProps(["components", "coreBannersAllPage", "magazineGroup"], 300000);
	}, []);

	useEffect(() => {
		// Ottieni il numero di pagina dall'URL, se presente
		const page = parseInt(pageParam) || 1;
		setCurrentPage(page);
	}, [pageParam]);

	useEffect(() => {
		fetchMoreNews(currentPage);
	}, [currentPage]);

	const [swiper, setSwiper] = useState(null);
	useEffect(() => {
		if (!swiper && magazineGroup.cover_image) {
			const swiper = new Swiper(".swiper.swiperNewsHeader", {
				modules: [Navigation],
				slidesPerView: 1,
				navigation: {
					nextEl: ".swiper.swiperNewsHeader .swiper-button-next",
					prevEl: ".swiper.swiperNewsHeader .swiper-button-prev",
				},
			});
			setSwiper(swiper);
			async function prepareLightbox() {
				let imagesList = Array.from(document.querySelectorAll(".swiper.swiperNewsHeader a"));
				await ImageUtils.prepareImagesForPhotoswipe(imagesList);
			}
			prepareLightbox();
			let lightbox = new PhotoSwipeLightbox({
				gallery: ".swiper.swiperNewsHeader",
				children: "a",
				pswpModule: () => import("photoswipe"),
			});
			const captionPlugin = new PhotoSwipeDynamicCaption(lightbox, { type: "below" });
			lightbox.init();
		} else if (swiper && magazineGroup.cover_image) {
			swiper.update();
		}
	}, [swiper]);

	const newsCategoryID = magazineGroup.id;
	const newsCategoryTitle = magazineGroup.title[locale] || magazineGroup.name;
	const newsCategorySummary = magazineGroup.summary[locale];
	const newsCategoryContent = magazineGroup.content[locale];
	const newsCategoryNews = pagesData[currentPage] || [];
	{
		/** Gestione paginazione */
	}
	const newsCategoryPaginationMaxItems = config.conf_modules.magazine.group.pagination || null;
	const totalPages = Math.ceil(magazineGroup.news.total / newsCategoryPaginationMaxItems);

	const fetchMoreNews = async (page) => {
		// If the data for the requested page is already in the state, don't fetch it again
		if (pagesData[page]) {
			return;
		}

		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();

		try {
			const response = await axios.get(
				`/api/news/load-more?page=${page - 1}&magazine_group_id=${newsCategoryID}`,
				{
					cancelToken: source.token,
				}
			);

			if (response.data.result && response.data.data.magazineNews.length > 0) {
				setPagesData((prevData) => {
					return {
						...prevData,
						[page]: response.data.data.magazineNews,
					};
				});
			}
		} catch (error) {
			/*if (axios.isCancel(error)) {
				console.log("Request cancelled", error.message);
			} else {
				console.log("Something went wrong: ", error.message);
			}*/
		}

		return () => {
			source.cancel("Operation canceled by the user.");
		};
	};

	const main = (
		<>
			{/** Banner newsTop */}
			{FINAL_COMPONENTS.newsTop?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"newsTop",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}

			{/* Titolo + Sommario + Content */}
			<section>
				{/** Titolo */}
				<h1 className="text-uppercase h2">{newsCategoryTitle}</h1>

				{/** Sommario */}
				{newsCategorySummary && <p className="mt-2 font-serif-alt">{newsCategorySummary}</p>}

				{/** Immagini */}
				{magazineGroup.cover_image && magazineGroup.cover_image.length > 0 && (
					<div className="swiper swiperNewsHeader">
						<div className="swiper-wrapper">
							{magazineGroup.cover_image.map((image, index) => {
								const imageTitle = image.data?.title?.[0] ?? "";
								const imageAlt = image.data?.alt?.[0] ?? "";
								const imageCopyright = image.data?.copyright?.[0]?.value ?? "";

								return (
									<div
										className="swiper-slide"
										key={`swiperNewsHeader-${index}`}
										itemProp="associatedMedia"
										itemScope
										itemType="http://schema.org/ImageObject"
									>
										<a
											style={{ "--aspect-ratio": "16/9" }}
											className={`my-3 d-block ${styles.mainImageWrapper}`}
											itemProp="contentUrl"
											href={image.url}
											data-pswp-width={image.width}
											data-pswp-height={image.height}
											target="_blank"
											rel="noreferrer"
											data-cropped="true"
										>
											<SmartImage
												url={image.url}
												conversions={image.conversions}
												data={image.data}
											/>
											<span className="pswp-caption-content">
												{imageTitle.trim() !== "" && <strong>{imageTitle}</strong>}
												{imageCopyright.trim() !== "" && (
													<small className="d-block mt-1">{imageCopyright}</small>
												)}
											</span>
										</a>
										<small className="d-block mt-1 fst-italic">{imageCopyright}</small>
									</div>
								);
							})}
						</div>

						<div
							className={`swiper-button-prev swiperButtonPrev ${magazineGroup.cover_image.length === 1 &&
								"d-none"}`}
							dangerouslySetInnerHTML={{
								__html: SVG.leftSwiperArrow,
							}}
						></div>
						<div
							className={`swiper-button-next swiperButtonNext ${magazineGroup.cover_image.length === 1 &&
								"d-none"}`}
							dangerouslySetInnerHTML={{
								__html: SVG.rightSwiperArrow,
							}}
						></div>
					</div>
				)}

				{/** Contenuto */}
				{newsCategoryContent && (
					<div className="mt-2 font-serif-alt" dangerouslySetInnerHTML={{ __html: newsCategoryContent }} />
				)}
			</section>

			{/* Separatore */}
			<hr className="separatore"></hr>

			{/* Elenco articoli */}
			{newsCategoryNews?.length > 0 &&
				newsCategoryNews.map((news, index) => (
					<>
						<NewsCard
							horizontal={index === 0 ? false : true}
							large={true}
							imageAspectRatio={index === 0 ? "2/0.95" : "3/2"}
							data={news}
							showDate={true}
						/>

						<hr className="separatore" />

						{index === 0 && (
							<>
								{/** Banner newsMiddle */}
								{FINAL_COMPONENTS.newsMiddle?.map((componentObj, index) => {
									return ComponentsUtils.renderComponent(
										componentObj,
										componentMap,
										"",
										"newsMiddle",
										undefined,
										<hr className="separatore"></hr>,
										userLogged
									);
								})}
							</>
						)}
					</>
				))}

			{/* Paginazione */}
			<Pagination
				currentPage={currentPage}
				totalPages={totalPages}
				onPageChange={(page) => {
					// const urlObj = new URL(url, ziggy.url);
					// urlObj.searchParams.set("p", page);
					// router.visit(`${urlObj.href}`);
					// setCurrentPage(page);
				}}
			/>

			{/* Separatore */}
			<hr className="separatore"></hr>

			{/** Banner newsBottom */}
			{FINAL_COMPONENTS.newsBottom?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"newsBottom",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}
		</>
	);

	const aside = (
		<>
			{FINAL_COMPONENTS.asideTop?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"asideTop",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}
			{FINAL_COMPONENTS.asideMiddle?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"asideMiddle",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}
			{FINAL_COMPONENTS.asideBottom?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"asideBottom",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}
		</>
	);

	const seoData = SEOUtils.generateSEOData({
		page: page,
		locale: locale,
		type: "newsCategory",
		ziggy: ziggy,
		otherProps: {
			newsCategory: magazineGroup,
		},
	});

	return (
		<>
			<HeadSEO data={seoData} />
			<Layout {...props} main={main} aside={aside} />
			{/** Componenti popup */}
			{ComponentsUtils.renderPopupComponents(FINAL_COMPONENTS.popup, componentMap)}
		</>
	);
}

export default NewsCategory;
