import React from "react";
import BlogLink from "../BlogLink/Index";
import styles from "./BlogLinkList.module.scss";
import { usePage } from "@inertiajs/react";

function BlogLinkList({ ...props }) {
	const { locale } = usePage().props;

	let blogLinkListTitle = props.data.component["title_" + locale];

	const linkElements = props.data.data.map((link, index) => {
		return (
			<li key={`BlogLink-${index}`}>
				<BlogLink
					url={link.group.url?.[locale].url}
					title={link.group.title[locale] ? link.group.title[locale] : link.group.name}
					author={link.group.summary[locale]}
				/>
			</li>
		);
	});

	return (
		<>
			<hr className="separatore"></hr>
			<h3 className="text-center text-uppercase h5 font-sans mt-5 mb-4 pb-2">{blogLinkListTitle}</h3>
			<ul className={styles.blogLinkListWrapper}>{linkElements}</ul>
		</>
	);
}

export default BlogLinkList;
