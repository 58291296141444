import React, { useState, useEffect, useMemo } from "react";
import styles from "./TopbarWeather.module.scss";
import { Link, usePage } from "@inertiajs/react";
import axios from "axios";

function WeatherOfTheDay() {
    const { locale, modulesRoutes } = usePage().props;

    const [weatherData, setWeatherData] = useState(null);
    const [currentSvg, setCurrentSvg] = useState(null);

    useEffect(() => {
        const isDataStale = () => {
            if (typeof window === "undefined") return true;

            const now = new Date().getTime();
            const cacheLifetime = 1 * 60 * 60 * 1000; // 1 ora
            const storedTimestamp = localStorage.getItem("weatherTimestamp");

            return !storedTimestamp || now - parseInt(storedTimestamp, 10) > cacheLifetime;
        };

        if (isDataStale()) {
            const fetchData = async () => {
                try {
                    const response = await axios.post("/api/weather/get/day");
                    const data = response.data.data[0];
                    if (data) {
                        setWeatherData(data);
                        if (typeof window !== "undefined") {
                            localStorage.setItem("weatherData", JSON.stringify(data));
                            localStorage.setItem("weatherTimestamp", new Date().getTime().toString());
                        }
                    } else {
                        console.error("Nessuna data meteo ricevuta");
                    }
                } catch (error) {
                    console.error("Errore nella richiesta del meteo", error);
                }
            };
            fetchData();
        } else if (typeof window !== "undefined") {
            const storedData = localStorage.getItem("weatherData");
            if (storedData) {
                setWeatherData(JSON.parse(storedData));
            }
        }
    }, []);

    const currentWeather = useMemo(() => {
        if (!weatherData) return null;
        const currentHour = new Date().getHours();
        return currentHour < 12
            ? weatherData.weather_climate_morning
            : currentHour < 18
            ? weatherData.weather_climate_afternoon
            : weatherData.weather_climate_evening;
    }, [weatherData]);

    useEffect(() => {
        if (currentWeather && currentWeather.image[0]?.url) {
            const fetchSvg = async () => {
                let storedSvg;
                if (typeof window !== "undefined") {
                    storedSvg = localStorage.getItem(`weatherSvg-${currentWeather.image[0]?.id}`);
                }

                if (storedSvg) {
                    setCurrentSvg(storedSvg);
                } else {
                    try {
                        const response = await axios.get(currentWeather.image[0]?.url);
                        const svgContent = response.data;
                        setCurrentSvg(svgContent);
                        if (typeof window !== "undefined") {
                            localStorage.setItem(`weatherSvg-${currentWeather.image[0]?.id}`, svgContent);
                        }
                    } catch (error) {
                        console.error("Error fetching SVG:", error);
                    }
                }
            };
            fetchSvg();
        }
    }, [currentWeather]);

    {
        /*if (!currentWeather) {
        return <div>Loading...</div>;
    }*/
    }

    return (
        <Link
            className={styles.wrapper}
            title={currentWeather?.title?.[locale] || currentWeather?.name}
            href={modulesRoutes?.weather[locale]}
        >
            {currentSvg && (
                <div
                    className={styles.svgWrapper}
                    dangerouslySetInnerHTML={{ __html: currentSvg }}
                    style={{ filter: "url(#f1)" }}
                />
            )}
            <div className={`${styles.weatherWrapper} font-sans`}>
                <p className={styles.text}>{currentWeather?.title?.[locale] || currentWeather?.name}</p>
            </div>
        </Link>
    );
}

export default WeatherOfTheDay;
