import React, { useState } from "react";
import styles from "./PDFViewer.module.scss";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import SVG from "../../Utils/SVG";

pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();

const PDFViewer = ({ file }) => {
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}
	function changePage(offset) {
		setPageNumber((prevPageNumber) => prevPageNumber + offset);
	}
	function previousPage() {
		changePage(-1);
	}
	function nextPage() {
		changePage(1);
	}

	return (
		<>
			<Document
				file={file}
				onLoadSuccess={onDocumentLoadSuccess}
				renderTextLayer={false}
				renderAnnotationLayer={false}
				customTextRenderer={false}
				loading={"Caricamento PDF..."}
			>
				<Page pageNumber={pageNumber} />
			</Document>
			{numPages > 1 && (
				<div className={`text-center`}>
					<div>
						<nav aria-label="Paginazione">
							<ul className={`pagination font-sans justify-content-center ${styles.pagination}`}>
								{/** Freccia indietro */}
								<li className="page-item">
									<button
										className={`page-link ${pageNumber <= 1 ? "disabled" : ""} ${styles.pageLink}`}
										disabled={pageNumber <= 1}
										onClick={previousPage}
										aria-label="Precedente"
									>
										<div dangerouslySetInnerHTML={{ __html: SVG.chevronLeft }} />
									</button>
								</li>

								{/** Freccia avanti */}
								<li className="page-item">
									<button
										className={`page-link ${pageNumber >= numPages ? "disabled" : ""} ${
											styles.pageLink
										}`}
										disabled={pageNumber >= numPages}
										onClick={nextPage}
										aria-label="Successiva"
									>
										<div dangerouslySetInnerHTML={{ __html: SVG.chevronRight }} />
									</button>
								</li>
							</ul>
						</nav>
					</div>
					<small className="font-sans mt-1 d-block">
						Pagina {pageNumber} di {numPages}
					</small>
				</div>
			)}
		</>
	);
};

export default PDFViewer;
