import React, { useEffect } from "react";
//import styles from "./News.module.scss";
import Layout from "../../Layout/Main";
import NewsArticle from "../../Components/News/NewsArticle/Index";
import NewsCategory from "../../Components/News/NewsCategory/Index";
import HeadSEO from "../../Components/Head/Index";
import { componentFactory } from "../../Utils/ComponentsFactory";
import ComponentsUtils from "../../Utils/ComponentsUtils";
import { useRefreshProps } from "../../Hooks";
import SEOUtils from "../../Utils/SEOUtils";

function News(props) {
	const { page, locale, components, coreBannersAllPage, magazineNews, userLogged, ziggy } = props;

	const componentMap = componentFactory();
	const zones = ["newsTop", "newsMiddle", "newsBottom", "asideTop", "asideMiddle", "asideBottom"];
	const FINAL_COMPONENTS = ComponentsUtils.getComponents(components, coreBannersAllPage, zones);

	{
		/** Aggiorna le props principali una volta ogni 5 minuti */
	}
	useEffect(() => {
		useRefreshProps(["components", "coreBannersAllPage", "magazineNews"], 300000);
	}, []);

	const main = (
		<>
			{/** Banner newsTop */}
			{FINAL_COMPONENTS.newsTop?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"newsTop",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}

			{/* Corpo articolo */}
			<NewsArticle />

			{/** Banner newsMiddle */}
			{FINAL_COMPONENTS.newsMiddle?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"newsMiddle",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}

			{/* Articoli correlati */}
			{magazineNews?.relateds?.length > 0 && (
				<NewsCategory
					wrapperCSSClasses="mt-4 mb-4 no-bg border-bottom-0 pb-0"
					data={{ news: magazineNews?.relateds?.slice(0, 6), title: "Leggi anche..." }}
				/>
			)}

			{/* Separatore */}
			<hr className="separatore" />

			{/** Banner newsBottom */}
			{FINAL_COMPONENTS.newsBottom?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"newsBottom",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}
		</>
	);

	const aside = (
		<>
			{/** Componenti asideTop */}
			{FINAL_COMPONENTS.asideTop?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"asideTop",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}

			{/** Componenti asideMiddle */}
			{FINAL_COMPONENTS.asideMiddle?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"asideMiddle",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}

			{/** Componenti asideBottom */}
			{FINAL_COMPONENTS.asideBottom?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"asideBottom",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}
		</>
	);

	const seoData = SEOUtils.generateSEOData({
		page: page,
		locale: locale,
		type: "newsArticle",
		ziggy: ziggy,
		otherProps: {
			newsArticle: magazineNews,
		},
	});

	return (
		<>
			<HeadSEO data={seoData} />
			<Layout {...props} main={main} aside={aside} />
			{/** Componenti popup */}
			{ComponentsUtils.renderPopupComponents(FINAL_COMPONENTS.popup, componentMap)}
		</>
	);
}

export default News;
