import React from "react";
// import styles from "./PersonalDataTab.module.scss";
import { usePage } from "@inertiajs/react";

import UserAddresses from "../../UserAddresses/Index";
import Form from "../../../Form/Index";

export default function PersonalDataTab({ user }) {
    const { userLogged } = usePage().props;

    const passwordsAreEqualValidation = (formData, formElements) => {
        let errors = {};

        const password = formData.get("password");
        const password2 = formData.get("password2");

        // console.log("formData passwordsAreEqualValidation", formData);

        // Controlla se le password sono presenti
        if (!password || !password2) {
            if (!password) {
                errors.password = "La password è richiesta.";
            }

            if (!password2) {
                errors.password2 = "La conferma della password è richiesta.";
            }
        }
        // Controlla se le password corrispondono
        else if (password !== password2) {
            errors.password2 = "Le password non corrispondono.";
        }
        // Controllo per la lunghezza della password
        /*else if (password.length < 8 || password2.length < 8) {
            errors.password = 'La password deve contenere almeno 8 caratteri.';
            errors.password2 = 'La password deve contenere almeno 8 caratteri.';
        }*/

        return errors;
    };

    return (
        <div
            className="tab-pane fade show active"
            id="personalDataTab"
            role="tabpanel"
            aria-labelledby="personalDataTab-pill"
            tabIndex="0"
        >
            <h1 className="h2 mb-4">Il mio profilo</h1>

            <h2 className="h4 font-sans">Dati personali</h2>

            {/* Form dati personali - Nome e cognome */}
            <Form
                apiUrl={`/api/users/${userLogged?.id}/update`}
                method="PUT"
                extraProps={{
                    className: `mt-3`,
                }}
                refreshDelay={0}
                stateResetDelay={5000}
                fieldsHTML={
                    <>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="username" className="mb-1">
                                        Nome*
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        defaultValue={userLogged?.name}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="username" className="mb-1">
                                        Cognome*
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="surname"
                                        defaultValue={userLogged?.surname}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="offset-md-6 col-md-6 offset-lg-8 col-lg-4 mt-3">
                                <input type="submit" className="btn btn-primary w-100" value="Aggiorna profilo" />
                            </div>
                        </div>
                    </>
                }
            />

            <hr className="separatore" />

            <h2 className="h4 font-sans">Dettagli di fatturazione</h2>

            <p className="mt-2">Gestisci i tuoi indirizzi di fatturazione.</p>

            {/* Elenco indirizzi attualmente inseriti */}
            <div className="mt-3">
                <UserAddresses user={user} />
            </div>

            <hr className="separatore" />

            <h2 className="h4 font-sans">Dati di accesso</h2>
            <p className="mt-2">Se vuoi cambiare la tua password, inserisci la vecchia e la nuova password.</p>

            {/* Form dati di accesso - Password */}
            <Form
                apiUrl={`/api/users/${userLogged?.id}/update`}
                method="PUT"
                extraProps={{
                    className: `mt-3`,
                }}
                refreshDelay={0}
                stateResetDelay={30000}
                customValidation={passwordsAreEqualValidation}
                fieldsHTML={
                    <>
                        <div className="row mt-3">
                            <div className="form-group col-lg-6">
                                <label htmlFor="username" className="mb-1">
                                    Nuova password
                                </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Inserisci qui la tua nuova password"
                                    name="password"
                                />
                            </div>
                            <div className="form-group col-lg-6">
                                <label htmlFor="username" className="mb-1">
                                    Conferma nuova password
                                </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Conferma tua nuova password"
                                    name="password2"
                                />
                            </div>
                        </div>
                        <div className="offset-md-6 col-md-6 offset-lg-8 col-lg-4 mt-3">
                            <input type="submit" className="btn btn-primary w-100" value="Aggiorna la password" />
                        </div>
                    </>
                }
            />
        </div>
    );
}
