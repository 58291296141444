import React from "react";
import styles from "./SubscriptionCard.module.scss";
import { Link, usePage } from "@inertiajs/react";
import SubscriptionsUtils from "../../../Utils/SubscriptionUtils";
import DateUtils from "../../../Utils/DateUtils";

function SubscriptionCard({ ...props }) {
	const { userLogged, locale, modulesRoutes } = usePage().props;

	const isOffer = Boolean(props.reduction != "0.00");

	const generateCheckoutURL = (subscriptionId) => {
		return `${modulesRoutes.private[locale]}?openCheckoutModal=true&subscriptionId=${subscriptionId}&activeTab=subscriptionTab`;
	};

	// Funzione per calcolare la data di rinnovo
	const calculateNextSubscriptionDate = () => {
		const today = new Date();
		const nextDate = new Date(today);
		nextDate.setDate(nextDate.getDate() + props.day_of_validity);

		return DateUtils.convertDateFromString(nextDate, false);
	};

	// Card usata nel checkout
	if (props?.isCheckout) {
		return (
			<div
				className={`${styles.card} ${styles.checkoutCard} subscriptionCard`}
				itemScope
				itemType="http://schema.org/Product"
				{...(isOffer ? { "data-ribbon": "Offerta" } : {})}
			>
				<div className={`font-sans ${styles.header}`}>
					<h3 className="h6" itemProp="name">
						{props.title[locale]} {props.day_of_validity === 365 ? "annuale" : "mensile"}
					</h3>
					<span className="text-primary">
						{SubscriptionsUtils.toEuroString(props.price)}{" "}
						{props.day_of_validity === 365 ? "all'anno" : "al mese"}
					</span>
				</div>

				<div dangerouslySetInnerHTML={{ __html: props.content[locale] }} />

				<hr className="separatore my-3" />

				<div className={`font-sans ${styles.totalWrapper}`}>
					<strong>Totale dovuto:</strong>
					<strong className={`text-primary`}>{SubscriptionsUtils.toEuroString(props.finalPrice)}</strong>
				</div>

				<small className={`d-block mt-2`}>
					Puoi disdire quando vuoi.
					<br />
					<strong>L'abbonamento si rinnova in automatico</strong>, prossimo rinnovo:{" "}
					{calculateNextSubscriptionDate()}.
				</small>
			</div>
		);
	}

	// Card usata altrove
	return (
		<div
			className={`${styles.card} subscriptionCard`}
			itemScope
			itemType="http://schema.org/Product"
			{...(isOffer ? { "data-ribbon": "Offerta" } : {})}
		>
			<h3 className="h5" itemProp="name">
				{props.title[locale]}
			</h3>

			<div itemProp="description" dangerouslySetInnerHTML={{ __html: props.content[locale] }} />

			<h4 className="h4 font-sans mt-4">
				{isOffer && (
					<span className={styles.originalPrice}>{SubscriptionsUtils.toEuroString(props.price)}</span>
				)}
				<span itemProp="price">{SubscriptionsUtils.toEuroString(props.finalPrice)}</span>
				<span>/{props.day_of_validity === 365 ? "anno" : "mese"}</span>
				<meta itemProp="priceCurrency" content="EUR" />
			</h4>

			<small className={`d-block ${styles.small}`}>Rinnovo automatico</small>

			{props.summary?.[locale] && (
				<strong className={`d-block ${styles.paymentInfo}`}>{props.summary[locale]}</strong>
			)}

			{userLogged ? (
				<Link
					href={generateCheckoutURL(props.id)}
					title="Scegli questo abbonamento"
					className="w-100 btn btn-primary mt-4"
				>
					Scegli questo abbonamento
				</Link>
			) : (
				<button
					title="Accedi per proseguire"
					className="w-100 btn btn-primary mt-4"
					data-bs-toggle="modal"
					data-bs-target="#loginModal"
				>
					Accedi per proseguire
				</button>
			)}
		</div>
	);
}

export default SubscriptionCard;
