import React, { useState, useEffect, useRef } from "react";
import styles from "./UserAddresses.module.scss";
import PrivateAreaUtils from "../../../Utils/PrivateAreaUtils";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import UserAddressModal from "./Modals/UserAddress";
import SVG from "../../../Utils/SVG";

export default function UserAddresses({
    user,
    showActions = true,
    onSelectAddress = null,
    extraBackdrop = false,
    customCSSClass = null,
    isCheckoutView = false,
}) {
    // Bootstrap
    const [Bootstrap, setBootstrap] = useState(null);

    // Importa bootstrap asincronamente
    useEffect(() => {
        import("bootstrap")
            .then((bootstrap) => {
                setBootstrap(bootstrap);
            })
            .catch((error) => {
                console.error("Errore durante l'importazione di bootstrap", error);
            });
    }, []);

    // Expanded indirizzo
    const [expandedAddressId, setExpandedAddressId] = useState(null);
    const toggleExpand = (addressId) => (event) => {
        event.stopPropagation();
        // Controlla se l'ID cliccato è già espanso, in tal caso lo nasconde, altrimenti lo espande
        setExpandedAddressId((prevId) => (prevId === addressId ? null : addressId));
    };

    // Indirizzo attualmente attivo
    const [currentActiveAddress, setCurrentActiveAddress] = useState(null);
    const [currentActiveAction, setCurrentActiveAction] = useState(null);

    // Caricamento degli indirizzi
    const [isLoading, setIsLoading] = useState(true);
    const [userAddresses, setUserAddresses] = useState([]);
    useEffect(() => {
        const loadUserAddresses = async (userId) => {
            if (!userId) return;

            // Verifica se gli indirizzi sono già in sessionStorage
            const cachedAddresses = sessionStorage.getItem("userAddresses");
            if (cachedAddresses) {
                let userAddresses = JSON.parse(cachedAddresses);
                setUserAddresses(userAddresses);
                const defaultAddress = await PrivateAreaUtils.address.getDefaultAddress(userAddresses);
                if (defaultAddress) {
                    setDefaultAddressId(defaultAddress.id);
                    onSelectAddress && onSelectAddress(defaultAddress.id, defaultAddress);
                }
            } else {
                // Carica gli indirizzi dall'API
                setIsLoading(true);
                const userAddresses = await PrivateAreaUtils.address.getAll(userId);

                if (userAddresses.length > 0) {
                    setUserAddresses(userAddresses);
                    sessionStorage.setItem("userAddresses", JSON.stringify(userAddresses));
                    const defaultAddress = await PrivateAreaUtils.address.getDefaultAddress(userAddresses);
                    if (defaultAddress) {
                        setDefaultAddressId(defaultAddress.id);
                        onSelectAddress && onSelectAddress(defaultAddress.id, defaultAddress);
                    }
                }
            }
            setIsLoading(false);
        };

        loadUserAddresses(user.id);
    }, []);

    // Callback da usare per aggiornare gli indirizzi
    const updateAddresses = async () => {
        setIsLoading(true);

        // Ottengo gli indirizzi dell'utente aggiornati post submit form
        const userAddresses = await PrivateAreaUtils.address.getAll(user.id);

        // Aggiorno indirizzi utente
        setUserAddresses(userAddresses);
        sessionStorage.setItem("userAddresses", JSON.stringify(userAddresses));

        // Aggiorno indirizzo di default
        const defaultAddress = await PrivateAreaUtils.address.getDefaultAddress(userAddresses);
        if (defaultAddress) {
            setDefaultAddressId(defaultAddress.id);
            onSelectAddress && onSelectAddress(defaultAddress.id, defaultAddress);
        }

        setIsLoading(false);
    };

    // Formattazione degli indirizzi
    const [formattedAddresses, setFormattedAddresses] = useState([]);
    useEffect(() => {
        const loadFormattedAddresses = async () => {
            const formatted = await Promise.all(
                userAddresses.map(async (address) => {
                    const formattedAddress = await PrivateAreaUtils.address.format(address, {
                        showRegion: false,
                        showCountry: false,
                    });
                    return { address, formattedAddress };
                })
            );
            setFormattedAddresses(formatted);
        };

        if (userAddresses.length) {
            loadFormattedAddresses();
        }
    }, [userAddresses]);

    // Gestione aggiornamento indirizzi
    const [defaultAddressId, setDefaultAddressId] = useState(null);

    const handleDefaultAddressChange = async (newDefaultId) => {
        if (newDefaultId === defaultAddressId) return;

        try {
            // FARE CHIAMATA API per aggiornare l'indirizzo predefinito
            const response = await PrivateAreaUtils.address.setDefaultAddress(newDefaultId);
            if (response && response.result) {
                setDefaultAddressId(newDefaultId);
                onSelectAddress &&
                    onSelectAddress(
                        newDefaultId,
                        userAddresses.filter((addr) => addr.id === newDefaultId)
                    );

                // Aggiorno anche l'array userAddresses per riflettere il cambiamento
                const updatedAddresses = userAddresses.map((addr) => ({
                    ...addr,
                    is_default: addr.id === newDefaultId ? 1 : 0,
                }));

                // Salvo i nuovi indirizzi aggiornati nello stato e nella sessione
                setUserAddresses(updatedAddresses);
                sessionStorage.setItem("userAddresses", JSON.stringify(updatedAddresses));
            } else {
                console.error("Impossibile aggiornare l'indirizzo di default:", response);
            }
        } catch (error) {
            console.error("Errore nella chiamata API per l'aggiornamento dell'indirizzo di default:", error);
        }
    };

    // Gestione delle azioni sugli indirizzi
    // --- Aggiunta
    const handleAddClick = (event, address) => {
        event.stopPropagation();
        openAddressModal(address, "add");
    };
    // --- Modifica
    const handleEditClick = (event, address) => {
        event.stopPropagation();
        openAddressModal(address, "edit");
    };
    // --- Cancella
    const handleDeleteClick = (event, addressId) => {
        event.stopPropagation();
        deleteAddress(addressId);
    };

    // Gestione apertura modale indirizzi
    const openAddressModal = (address, action) => {
        setCurrentActiveAddress(address);
        setCurrentActiveAction(action);
        if (Bootstrap) {
            const modal = modalRef.current;
            if (modal) {
                new Bootstrap.Modal(modal).show();
            }
        }
    };

    // Gestione cancellazione indirizzo
    const deleteAddress = async (addressId) => {
        const confirmDelete = window.confirm("Sei sicuro di voler cancellare questo indirizzo?");
        if (confirmDelete) {
            // L'utente ha confermato, procedi con la cancellazione
            try {
                await PrivateAreaUtils.address.remove(addressId);
                updateAddresses();
            } catch (error) {
                console.error("Errore durante la cancellazione dell'indirizzo", error);
            }
        } else {
            // L'utente ha annullato la cancellazione
            //console.log("Cancellazione annullata");
        }
    };

    /*
        {
            "private_user_id": 9,
            "is_default": true,
            "is_company": true,
            "name": "Ciao sono io",
            "surname": "Ous",
            "company": "Ous",
            "piva": "Ous",
            "codfisc": "Ous",
            "address": "Ous",
            "cap": "Ous",
            "core_country_id": 1,
            "core_region_id": 1,
            "core_province_id": 1,
            "core_city_id": 1,
            "email": "ousseni.bara@glacomtech.com",
            "telephone": "Ous",
            "mobile": "Ous",
            "cod_dest": "Ous",
            "email_pec": "ousseni.bara@glacomtech.com",
            "note": "Ous"
        }
    */
    const modalRef = useRef(null);
    return (
        <>
            {isLoading ? (
                <Skeleton count={2} containerClassName={`mt-2 ${styles.skeletonAddressCardWrapper}`} inline={true} />
            ) : userAddresses.length === 0 ? (
                <>
                    <p className="mt-2">
                        {isCheckoutView ? (
                            <strong>Non hai ancora inserito un indirizzo di fatturazione.</strong>
                        ) : (
                            <>Non hai ancora inserito un indirizzo di fatturazione.</>
                        )}
                        <br />
                        Per aggiungerne uno, clicca sul bottone qui sotto.
                    </p>
                    {isCheckoutView && (
                        <button
                            className={`${styles.addNewAddressBtn} mt-3 font-sans`}
                            onClick={(e) => handleAddClick(e, null)}
                        >
                            <div
                                className={`me-2 d-flex align-items-center ${styles.svgWrapper}`}
                                dangerouslySetInnerHTML={{ __html: SVG.plus }}
                            />
                            <strong>Aggiungi nuovo indirizzo</strong>
                        </button>
                    )}
                </>
            ) : (
                <ul className={`mt-2`}>
                    {formattedAddresses.map(({ address, formattedAddress }) => (
                        <li
                            key={`PersonalDataTab-UserAddresses-${address.id}`}
                            className={`${styles.addressCard} ${defaultAddressId === address.id ? styles.active : ""} ${
                                showActions ? styles.showActions : ""
                            } ${customCSSClass ? customCSSClass : ""} font-sans`}
                            onClick={() => handleDefaultAddressChange(address.id)}
                        >
                            <div className={`${styles.wrapper}`}>
                                {/** Checkbox */}
                                <input
                                    className={`form-check-input mt-0`}
                                    type="radio"
                                    name="defaultAddress"
                                    checked={defaultAddressId === address.id}
                                    readOnly
                                />

                                {/** Nome + Indirizzo */}
                                <div className={`${styles.nameAddress}`}>
                                    <div className={`${styles.name}`}>
                                        <strong className={`font-serif`}>
                                            {address.name} {address.surname}
                                        </strong>
                                        <span className="badge bg-secondary">
                                            {address.is_company === 1 ? "Azienda" : "Privato"}
                                        </span>
                                    </div>
                                    <p className={`${styles.formattedAddress} mt-1`}>{formattedAddress}</p>
                                </div>

                                {/** Azioni sull'indirizzo: Modifica / Cancella */}
                                {showActions && (
                                    <div className={`${styles.actions}`}>
                                        {/** Espandi dettagli */}
                                        <button className={`text-primary`} onClick={toggleExpand(address.id)}>
                                            {expandedAddressId === address.id ? "Riduci" : "Espandi"}
                                        </button>
                                        <hr />
                                        {/** Modifica */}
                                        <button onClick={(e) => handleEditClick(e, address)}>Modifica</button>
                                        <hr />
                                        {/** Cancella */}
                                        <button
                                            className={`text-danger`}
                                            onClick={(e) => handleDeleteClick(e, address.id)}
                                        >
                                            Cancella
                                        </button>
                                    </div>
                                )}
                            </div>

                            {expandedAddressId === address.id && (
                                <div className={styles.expanded}>
                                    {/* Nome azienda */}
                                    {address.is_company === 1 && address.company && (
                                        <div>
                                            <strong>Nome azienda:</strong> <span>{address.company}</span>
                                        </div>
                                    )}
                                    {/* Partita IVA */}
                                    {address.is_company === 1 && address.piva && (
                                        <div>
                                            <strong>Partita IVA:</strong> <span>{address.piva}</span>
                                        </div>
                                    )}
                                    {/* Codice destinatario */}
                                    {address.is_company === 1 && address.cod_dest && (
                                        <div>
                                            <strong>Codice destinatario:</strong> <span>{address.cod_dest}</span>
                                        </div>
                                    )}
                                    {/* PEC */}
                                    {address.is_company === 1 && address.email_pec && (
                                        <div>
                                            <strong>PEC:</strong> <span>{address.email_pec}</span>
                                        </div>
                                    )}
                                    {/* Codice fiscale */}
                                    {address.codfisc && (
                                        <div>
                                            <strong>Codice fiscale:</strong> <span>{address.codfisc}</span>
                                        </div>
                                    )}
                                    {/* E-Mail */}
                                    {address.email && (
                                        <div>
                                            <strong>E-Mail:</strong> <span>{address.email}</span>
                                        </div>
                                    )}
                                    {/* Telefono */}
                                    {address.telephone && (
                                        <div>
                                            <strong>Telefono:</strong> <span>{address.telephone}</span>
                                        </div>
                                    )}
                                    {/* Cellulare */}
                                    {address.mobile && (
                                        <div>
                                            <strong>Cellulare:</strong> <span>{address.mobile}</span>
                                        </div>
                                    )}
                                    {/* Note */}
                                    {address.note && (
                                        <div className={styles.note}>
                                            <strong>Note:</strong> <span>{address.note}</span>
                                        </div>
                                    )}
                                </div>
                            )}
                        </li>
                    ))}

                    {/* Aggiungi nuovo indirizzo */}
                    {isCheckoutView && (
                        <li
                            key={`PersonalDataTab-UserAddresses-addNewAddress`}
                            className={`${styles.addressCard} ${
                                customCSSClass ? customCSSClass : ""
                            } font-sans bg-white`}
                            onClick={(e) => handleAddClick(e, null)}
                        >
                            <div className={`${styles.wrapper}`}>
                                <div className={`${styles.nameAddress} ms-0`}>
                                    <div className={`${styles.name}`}>
                                        <div
                                            className={`me-2 d-flex align-items-center ${styles.svgWrapper}`}
                                            dangerouslySetInnerHTML={{ __html: SVG.plus }}
                                        />
                                        <strong>Aggiungi nuovo indirizzo</strong>
                                    </div>
                                </div>
                            </div>
                        </li>
                    )}
                </ul>
            )}
            {/** Bottone aggiunta nuovo indirizzo - No checkout */}
            {!isCheckoutView && (
                <div className="offset-md-6 col-md-6 offset-lg-8 col-lg-4 mt-3">
                    <button className="btn btn-primary w-100" onClick={(e) => handleAddClick(e, null)}>
                        Aggiungi nuovo indirizzo
                    </button>
                </div>
            )}

            {/** Modale aggiunta / modifica indirizzi */}
            <UserAddressModal
                address={currentActiveAddress}
                action={currentActiveAction}
                ref={modalRef}
                extraBackdrop={extraBackdrop}
                onUpdateAddresses={updateAddresses}
            />
        </>
    );
}
