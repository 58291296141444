import React from "react";
import styles from "./ShareList.module.scss";

const ShareButton = ({ icon, href, title }) => (
    <li>
        <a href={href} title={title} rel="nofollow noopener" target="_blank">
            <div dangerouslySetInnerHTML={{ __html: icon }} />
        </a>
    </li>
);

const ShareList = ({ links }) => (
    <ul className={styles.shareButtonsWrapper}>
        {links.map((link, index) => (
            <ShareButton key={index} {...link} />
        ))}
    </ul>
);

export default ShareList;