import React, { useState, useEffect } from "react";
import PrivateAreaUtils from "../../../../Utils/PrivateAreaUtils";
import DateUtils from "../../../../Utils/DateUtils";
import SubscriptionsUtils from "../../../../Utils/SubscriptionUtils";

export default function PaymentsTab({ user }) {
	const [isLoading, setIsLoading] = useState(true);
	const [userPayments, setUserPayments] = useState([]);

	useEffect(() => {
		const loadUserPayments = async () => {
			setIsLoading(true);
			const userPayments = await PrivateAreaUtils.payment.getAll(user.id);
			setIsLoading(false);
			if (userPayments.length > 0) {
				setUserPayments(userPayments);
			}
		};

		loadUserPayments();
	}, []);

	return (
		<div className="tab-pane fade" id="paymentsTab" role="tabpanel" aria-labelledby="paymentsTab-pill" tabIndex="0">
			<h1 className="h2 mb-4">Pagamenti</h1>
			<p className="mt-2">
				In questa sezione puoi visionare una cronologia dettagliata di tutti i pagamenti che hai effettuato a
				ValleSabbiaNews.it per il tuo abbonamento.
				<br />
				Qui avrai la possibilità di monitorare ciascun pagamento, verificarne la data e l'importo, oltre a
				confermare che ogni transazione sia stata registrata correttamente.
			</p>

			{/** Elenco pagamenti */}
			{isLoading ? (
				<p className="mt-3">Caricamento dei pagamenti in corso...</p>
			) : userPayments.length === 0 ? (
				<p className="mt-3">Non ci sono ancora pagamenti da visualizzare.</p>
			) : (
				<div className="table-responsive mt-3">
					<table className="table table-striped align-middle">
						<thead>
							<tr>
								<th scope="col">Data pagamento</th>
								<th scope="col">Codice abbonamento</th>
								<th scope="col">Metodo di pagamento</th>
								<th scope="col">Importo</th>
							</tr>
						</thead>
						<tbody className="font-sans">
							{userPayments.map((payment) => (
								<tr key={payment.id}>
									<th scope="row">{DateUtils.formatDateToDDMMYYYY(payment.created_at)}</th>
									<td>{payment.subscription_code}</td>
									<td>{payment.payment_type}</td>
									<td>{SubscriptionsUtils.toEuroString(payment.total_amount)}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
}
