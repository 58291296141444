import React from "react";
import { Link, usePage } from "@inertiajs/react";
import styles from "./GalleryDailyPictureSidebar.module.scss"
import SmartImage from "../../Images/SmartImage/Index";

const GalleryDailyPictureSidebar = ({ ...props }) => {
    const { locale, modulesRoutes } = usePage().props;

    const wrapperCSSClasses = props.wrapperCSSClasses;

    const dailyPicture = props.data.data[0];
    const dailyPictureImage = dailyPicture.cover_image[0] ? dailyPicture.cover_image[0] : '';
    const dailyPictureTitle = dailyPicture.title[locale] ? dailyPicture.title[locale] : dailyPicture.name;
    const dailyPictureSummary = dailyPicture.summary[locale] ? dailyPicture.summary[locale] : '';

    return (
        <section className={`${styles.wrapper} ${wrapperCSSClasses}`}>
            <h3 className='text-uppercase fw-bold mt-2 mb-3 h5 font-sans'>Foto del giorno</h3>
            <Link href={modulesRoutes?.gallery[locale]} title='La foto del giorno di ValleSabbiaNews.it'>
                {dailyPictureImage && (<div style={{'--aspect-ratio': '3/2'}}>
                    <SmartImage
                        url={dailyPictureImage.url}
                        conversions={dailyPictureImage.conversions}
                        data={dailyPictureImage.data}
                    />
                </div>
                )}
                <h5 className="mt-3">{dailyPictureTitle}</h5>
                {dailyPictureSummary != '' && <span className="mt-2 d-block">{dailyPictureSummary}</span>}
            </Link>
            <small className="mt-4 d-block font-small font-sans">
                <a href="mailto:info@vallesabbianews.it">
                    Inviate a Vallesabbianews.it le vostre foto più belle
                </a>
            </small>
        </section>
    );
};

export default GalleryDailyPictureSidebar;