import React, { useState, useEffect } from "react";
import { usePage } from "@inertiajs/react";
import styles from "./NecrologiCard.module.scss";
import DateUtils from "../../../Utils/DateUtils";
import Form from "../../Form/Index";
import PrivateAreaUtils from "../../../Utils/PrivateAreaUtils";

const NecrologiCard = ({ ndata }) => {
    const { userLogged, config } = usePage().props;
    const [canComment, setCanComment] = useState(false);

    const defaultActiveCommentStatus = config.conf_modules.obituary.comment.defaultActiveCommentStatus;

    const additionalProps = defaultActiveCommentStatus
        ? { refreshDelay: 0, stateResetDelay: 2000 }
        : { stateReset: false };

    useEffect(() => {
        const checkMagazineCommentPermission = async () => {
            if (userLogged && userLogged.private_package_active) {
                try {
                    const activePackageInfo = await PrivateAreaUtils.subscription.get(
                        userLogged.private_package_active.private_package_id
                    );
                    // console.log("activePackageInfo", activePackageInfo);
                    if (activePackageInfo.configuration.ObituaryComment == "1") {
                        setCanComment(true);
                    }
                } catch (error) {
                    console.error("Errore nel recuperare le informazioni del pacchetto: ", error);
                }
            }
        };

        checkMagazineCommentPermission();
    }, [userLogged]);

    return (
        <li className={`py-3 py-sm-4 ${styles.card}`}>
            {/* Immagine + Bottone */}
            <div>
                {/* Immagine */}
                <div className={styles.imgWrapper} style={{ "--aspect-ratio": "1/1" }}>
                    <img
                        className={styles.img}
                        src={
                            ndata?.image?.length > 0
                                ? ndata.image[0].url
                                : "https://placehold.co/880x880?text=Nessuna+foto"
                        }
                        alt={ndata?.name}
                    />
                </div>

                {/* Bottone - Invia partecipazione */}
                {canComment && (
                    <button
                        className={`mt-3 btn btn-primary w-100 d-none d-sm-flex d-md-none d-xl-flex`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#partecipazioniForm-${ndata.id}`}
                        aria-expanded="false"
                        aria-controls={`partecipazioniForm-${ndata.id}`}
                    >
                        Invia partecipazione
                    </button>
                )}

                {/* Bottone - Leggi le partecipazioni */}
                {ndata.obituary_comment.length > 0 && (
                    <button
                        className={`btn ${
                            canComment ? "btn-tertiary" : "btn-primary"
                        } w-100 d-none d-sm-flex d-md-none d-xl-flex ${styles.btnLeggiPartecipazione}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#partecipazioniElenco-${ndata.id}`}
                        aria-expanded="false"
                        aria-controls={`partecipazioniElenco-${ndata.id}`}
                    >
                        Leggi partecipazioni ({ndata.obituary_comment.length})
                    </button>
                )}
            </div>

            {/* Content */}
            <div>
                {/* Data e luogo */}
                <span className={`text-uppercase font-sans ${styles.dataLuogo}`}>
                    {DateUtils.convertDateFromString(ndata.item_date)} - {ndata.location}
                </span>

                {/* Nome */}
                <h2 className={`mt-2`}>{ndata.name}</h2>

                {/* Anni */}
                <span className={`font-sans fst-italic ${styles.anni}`}>di anni {ndata.age}</span>

                {/* Descrizione */}
                {ndata.content1 && (
                    <div
                        className={`my-3 ${styles.descrizione}`}
                        dangerouslySetInnerHTML={{
                            __html: ndata.content1,
                        }}
                    />
                )}
                {ndata.content2 && (
                    <div
                        className={`my-3 ${styles.descrizione}`}
                        dangerouslySetInnerHTML={{
                            __html: ndata.content2,
                        }}
                    />
                )}

                {/* Onoranza funebre */}
                <span className={`font-sans fst-italic ${styles.onoranzaFunebre}`}>
                    <strong>Onoranze funebri:</strong> {ndata.funeral_honour}
                </span>
            </div>

            <div className={`d-sm-none d-md-flex d-xl-none ${styles.btnWrapper}`}>
                {/* Bottone - Invia partecipazione */}
                {canComment && (
                    <button
                        className={`btn btn-primary w-100 ${styles.btnPartecipazione}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#partecipazioniForm-${ndata.id}`}
                        aria-expanded="false"
                        aria-controls={`partecipazioniForm-${ndata.id}`}
                    >
                        Invia partecipazione
                    </button>
                )}

                {/* Bottone - Leggi partecipazioni */}
                {ndata.obituary_comment.length > 0 && (
                    <button
                        className={`btn ${canComment ? "btn-tertiary" : "btn-primary"} w-100 ${
                            styles.btnLeggiPartecipazione
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#partecipazioniElenco-${ndata.id}`}
                        aria-expanded="false"
                        aria-controls={`partecipazioniElenco-${ndata.id}`}
                    >
                        Leggi partecipazioni ({ndata.obituary_comment.length})
                    </button>
                )}
            </div>

            {/* Form + Elenco partecipazioni */}
            <div id={`partecipazioniParent-${ndata.id}`} className={styles.partecipazioniParent}>
                {/* Form */}
                {canComment && (
                    <div
                        className="collapse multi-collapse"
                        id={`partecipazioniForm-${ndata.id}`}
                        data-bs-parent={`#partecipazioniParent-${ndata.id}`}
                    >
                        <h2 className="h5">Invia partecipazione</h2>
                        <div className="mt-3">
                            <Form
                                {...additionalProps}
                                apiUrl={"/api/obituary/comments/add"}
                                fieldsHTML={
                                    <>
                                        <input type="hidden" name="obituaryItemID" value={ndata.id} />
                                        <input type="hidden" name="locale" value={usePage().props.locale} />
                                        <input
                                            type="hidden"
                                            name="privateUserID"
                                            value={usePage().props.userLogged ? usePage().props.userLogged.id : ""}
                                        />

                                        <div className="row">
                                            <div className="col-lg-4 font-sans">
                                                <label
                                                    htmlFor={`formNecrologi-${ndata.id}-firma`}
                                                    className="form-label font-small text-uppercase"
                                                >
                                                    Nome e cognome<span className="required">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={`formNecrologi-${ndata.id}-firma`}
                                                    name="firma"
                                                    placeholder="Inserisci qui il tuo nome e cognome"
                                                    required
                                                    defaultValue={
                                                        usePage().props.userLogged
                                                            ? usePage().props.userLogged.name +
                                                              " " +
                                                              usePage().props.userLogged.surname
                                                            : ""
                                                    }
                                                    inputMode="latin-name"
                                                />
                                            </div>

                                            <div className="col-lg-4 font-sans">
                                                <label
                                                    htmlFor={`formNecrologi-${ndata.id}-mail`}
                                                    className="form-label font-small text-uppercase"
                                                >
                                                    Mail<span className="required">*</span>
                                                </label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id={`formNecrologi-${ndata.id}-mail`}
                                                    name="email"
                                                    placeholder="Inserisci qui la tua e-mail"
                                                    required
                                                    defaultValue={
                                                        usePage().props.userLogged
                                                            ? usePage().props.userLogged.email
                                                            : ""
                                                    }
                                                    inputMode="email"
                                                />
                                            </div>

                                            <div className="col-lg-4 font-sans">
                                                <label
                                                    htmlFor={`formNecrologi-${ndata.id}-tel`}
                                                    className="form-label font-small text-uppercase"
                                                >
                                                    Telefono<span className="required">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={`formNecrologi-${ndata.id}-tel`}
                                                    name="telephone"
                                                    placeholder="Inserisci qui il tuo numero di telefono"
                                                    required
                                                    defaultValue={
                                                        usePage().props.userLogged
                                                            ? usePage().props.userLogged.telephone ||
                                                              usePage().props.userLogged.mobile
                                                            : ""
                                                    }
                                                    inputMode="tel"
                                                />
                                            </div>

                                            <div className="col-lg-12 font-sans mt-2 mt-lg-3">
                                                <label
                                                    htmlFor={`formNecrologi-${ndata.id}-titolo`}
                                                    className="form-label font-small text-uppercase"
                                                >
                                                    Titolo<span className="required">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={`formNecrologi-${ndata.id}-titolo`}
                                                    name="title"
                                                    placeholder="Inserisci qui il titolo della tua partecipazione"
                                                    required
                                                />
                                            </div>

                                            <div className="col-12 font-sans mt-2 mt-lg-3">
                                                <label
                                                    htmlFor={`formNecrologi-${ndata.id}-content`}
                                                    className="form-label font-small text-uppercase"
                                                >
                                                    Testo partecipazione<span className="required">*</span>
                                                </label>
                                                <textarea
                                                    className="form-control"
                                                    id={`formNecrologi-${ndata.id}-content`}
                                                    rows={3}
                                                    name="content"
                                                    placeholder="Inserisci qui il testo della tua partecipazione"
                                                    required
                                                ></textarea>
                                            </div>

                                            <div className="col-12 mt-2 mt-lg-3">
                                                <input
                                                    className="btn-primary btn"
                                                    type="submit"
                                                    name="submit"
                                                    value="Invia"
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                            />
                        </div>
                    </div>
                )}

                {/* Elenco partecipazioni */}
                {ndata.obituary_comment.length > 0 && (
                    <div
                        className="collapse multi-collapse"
                        id={`partecipazioniElenco-${ndata.id}`}
                        data-bs-parent={`#partecipazioniParent-${ndata.id}`}
                    >
                        <h2 className="h5">Partecipazioni</h2>
                        {ndata.obituary_comment.map((partecipazione, index) => {
                            const partecipazioneFirma = partecipazione.firma;
                            const partecipazioneTitle = partecipazione.title;
                            const partecipazioneContent = partecipazione.content;
                            // console.log("partecipazione", partecipazione);
                            return (
                                <div className={index === 0 ? "mt-2" : ""}>
                                    <p>
                                        <strong>{partecipazioneTitle}</strong>: {partecipazioneContent} -{" "}
                                        <i>{partecipazioneFirma}</i>
                                    </p>
                                    {index < ndata.obituary_comment.length - 1 && <hr className="my-2" />}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </li>
    );
};

export default NecrologiCard;
