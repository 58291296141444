import React, { useState, useEffect } from "react";
//import styles from "./Weather.module.scss";
import Layout from "../../Layout/Main";
import HeadSEO from "../../Components/Head/Index";
import DailyWeather from "../../Components/Weather/DailyWeather/Index";
import { componentFactory } from "../../Utils/ComponentsFactory";
import ComponentsUtils from "../../Utils/ComponentsUtils";
import { useRefreshProps } from "../../Hooks";
import SEOUtils from "../../Utils/SEOUtils";

function Weather(props) {
	const { page, locale, components, coreBannersAllPage, weatherDays, userLogged, ziggy } = props;

	const componentMap = componentFactory();
	const zones = ["main", "asideTop", "asideMiddle", "asideBottom"];
	const FINAL_COMPONENTS = ComponentsUtils.getComponents(components, coreBannersAllPage, zones);

	{
		/* Aggiorna le props principali una volta ogni 5 minuti */
	}
	useEffect(() => {
		useRefreshProps(["components", "coreBannersAllPage"], 300000);
	}, []);

	const pageTitle = page.title[locale] ? page.title[locale] : page.name;
	const pageContent = page.content[locale];

	const main = (
		<>
			{/* Titolo */}
			<h1 className="mb-2 h2">{pageTitle}</h1>

			{/* Contenuto */}
			<div dangerouslySetInnerHTML={{ __html: pageContent }} />

			{/* Meteo */}
			<div className="row">
				<div className="col-12">
					<DailyWeather weather={weatherDays} />
				</div>
			</div>

			{/* Componenti main */}
			{FINAL_COMPONENTS.main?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"main",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}
		</>
	);

	const aside = (
		<>
			{/* Componenti asideTop */}
			{FINAL_COMPONENTS.asideTop?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"asideTop",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}

			{/* Componenti asideMiddle */}
			{FINAL_COMPONENTS.asideMiddle?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"asideMiddle",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}

			{/* Componenti asideBottom */}
			{FINAL_COMPONENTS.asideBottom?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"asideBottom",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}
		</>
	);

	const seoData = SEOUtils.generateSEOData({
		page: page,
		locale: locale,
		type: "default",
		ziggy: ziggy,
		otherProps: {},
	});

	return (
		<>
			<HeadSEO data={seoData} />
			<Layout {...props} main={main} aside={aside} />
			{/* Componenti popup */}
			{ComponentsUtils.renderPopupComponents(FINAL_COMPONENTS.popup, componentMap)}
		</>
	);
}

export default Weather;
