import React, { useState, useEffect } from "react";
import PartnerLink from "../PartnerLink/Index";
import styles from "./PartnersLinkList.module.scss";
import { usePage } from "@inertiajs/react";
import axios from "axios";
import CacheUtils from "../../../Utils/CacheUtils";
import GTrackUtils from "../../../Utils/GTrackUtils";

//const CACHE_DURATION = 60 * 60 * 1000 * 24 * 7; // Durata della cache in millisecondi (7 giorni)
const CACHE_DURATION = 60 * 1000 * 2; // 2 minuti di cache

function PartnersList({ ...props }) {
	const { locale } = usePage().props;
	const partnersListIDs = props.data?.components?.[0]?.banner || [];
	const [partners, setPartners] = useState([]);

	const { shouldIncludeDataGTrack, gTrackAction } = GTrackUtils.generateGTrackAction(
		props.data?.custom_fields?.gTrack_click,
		props.data?.custom_fields?.gTrack_view
	);

	useEffect(() => {
		const cancelTokenSource = axios.CancelToken.source();

		Promise.all(partnersListIDs.map((id) => CacheUtils.fetchBanner(id, CACHE_DURATION))).then((responses) => {
			const banners = responses.map((response) => response.data);
			setPartners(banners);
		});
		/*.catch(error => {
                if (axios.isCancel(error)) {
                    console.log('Richiesta annullata', error.message);
                } else {
                    console.error('Si è verificato un errore:', error);
                }
            });*/

		return () => {
			cancelTokenSource.cancel("Componente smontato");
		};
	}, [partnersListIDs]);

	const linkElements = partners.map((link, index) => {
		return (
			<li key={`PartnerLink-${index}`}>
				<PartnerLink data={link} />
			</li>
		);
	});

	return (
		<section
			className={`row`}
			{...(shouldIncludeDataGTrack && {
				"data-gtrack": true,
				"data-gtrack-id": props.data.id,
				"data-gtrack-type": "core_banner",
				"data-gtrack-action": gTrackAction,
			})}
		>
			<div className="col-12 text-center">
				<h6 className="text-uppercase font-sans h5 mb-4 pb-2">
					{props?.data?.title?.[locale] || props?.data?.name}
				</h6>
				<ul className={styles.partnersLinkListWrapper}>{linkElements}</ul>
			</div>
		</section>
	);
}

export default PartnersList;
