import React, { useEffect } from "react";
import styles from "./Layout.Main.module.scss";
import Header from "../Components/Header/Index";
import Footer from "../Components/Footer/Index";
import LoginModal from "../Components/PrivateArea/LoginModal/Index";
import RegisterModal from "../Components/PrivateArea/RegisterModal/Index";
import RecoverPasswordModal from "../Components/PrivateArea/RecoverPasswordModal/Index";
import GTrack from "../Components/GTrack/Index";

const Layout = (props) => {
    const userHasSubscription = Boolean(props?.userLogged?.private_package_active);
    useEffect(() => {
        if (userHasSubscription) {
            document.body.classList.add('userHasSubscription');
        } else {
            document.body.classList.remove('userHasSubscription');
        }
        // Rimuove la classe quando il componente viene smontato
        return () => {
            document.body.classList.remove('userHasSubscription');
        };
    }, [userHasSubscription]);
    return (
        <>
            <GTrack />
            <Header />
            <div className="container mt-3">
                <div className="row">
                    <div className="col-12">
                        <div className={`${styles.grid}`}>
                            <main className={`${styles.main} pt-md-3`}>
                                {props.main}
                            </main>
                            <hr className={`${styles.hr}`}></hr>
                            <aside className={`${styles.aside} py-3`}>
                                {props.aside}
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <LoginModal />
            <RegisterModal />
            <RecoverPasswordModal />
        </>
    );
};

export default Layout;