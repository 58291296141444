import React, { useState, useEffect } from "react";
import { Link, usePage } from "@inertiajs/react";
import styles from "./Topbar.module.scss";
import DateUtils from "../../Utils/DateUtils";
import SVG from "../../Utils/SVG";
import TopbarWeather from "../Weather/TopbarWeather/Index";

const Topbar = () => {
	const userLogged = usePage().props.userLogged;
	const userHasSubscription = Boolean(userLogged?.private_package_active);
	const privateAreaURL = usePage().props.modulesRoutes.private[usePage().props.locale];

	const [currentDate, setCurrentDate] = useState(DateUtils.getCurrentDate());

	useEffect(() => {
		const updateDate = () => {
			const now = new Date();
			const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
			const diffInMs = tomorrow - now;
			setCurrentDate(DateUtils.getCurrentDate());
			setTimeout(updateDate, diffInMs);
		};
		updateDate();
	}, []);

	return (
		<section className={`container ${styles.topbarWrapper}`}>
			<div className="row align-items-center">
				<div className={`col-6 col-md-4 col-lg-5 ${styles.dateWeatherWrapper}`}>
					<span className={`${styles.currentDate} fw-bold font-sans`}>{currentDate}</span>
					<TopbarWeather />
				</div>
				<div className={`col-6 col-md-8 col-lg-7 ${styles.topbarButtonsWrapper}`}>
					{/*<button type="" title="" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#loginModal">*/}
					{!userHasSubscription && (
						<Link className="btn btn-primary ms-0" href="/it/abbonati" style={{ minWidth: "74px" }}>
							Abbonati
						</Link>
					)}

					<a
						href="https://bab1f289.sibforms.com/serve/MUIFABgWpZuFnJLtQUa7ySu8HCS_sYUXCmHLvGqnzkmo40I6Xk5T7bYcIjdWiWDSM4pKeQcp2NcujrDOGotBRN5GkXmLEErbyivuVugVvBYyWiijMOG5bOs1gAUxs5-bHQ794MCbm8oWzYuSVQeCmc2dO0wABmYHYSzBPzWQHwOy4zqBYos1P-WRDqWqBcRf1JHq1aHw6eZu2Xmu"
						target="_blank"
						aria-label="Iscriviti alla newsletter"
						className="btn"
					>
						<div className="svgWrapper" dangerouslySetInnerHTML={{ __html: SVG.newsletter }} />
						<span className="d-none d-md-block">Iscriviti alla newsletter</span>
					</a>

					{userLogged ? (
						<>
							<Link title="" className="btn" href={privateAreaURL}>
								<div className="svgWrapper" dangerouslySetInnerHTML={{ __html: SVG.profileLogged }} />
								<span className="d-none d-md-block">Profilo</span>
							</Link>
						</>
					) : (
						<button
							className="btn"
							data-bs-toggle="modal"
							data-bs-target="#loginModal"
							aria-label="Accedi ora"
							title="Accedi ora"
						>
							<div className="svgWrapper" dangerouslySetInnerHTML={{ __html: SVG.profile }} />
							<span className="d-none d-md-block">Accedi</span>
						</button>
					)}
				</div>
			</div>
		</section>
	);
};

export default Topbar;
