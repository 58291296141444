import React, { useRef, useCallback, useState } from 'react';
import styles from "./Header.module.scss";
import Topbar from "../Topbar/Index";
import Middlebar from "../Middlebar/Index";
import Navbar from "../Navbar/Index";
import NewsBreaking from "../News/NewsBreaking/Index";
import FormUtils from '../../Utils/FormUtils';

const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    
    const searchBoxRef = useRef();

    const setInputRef = useCallback((node) => {
        searchBoxRef.current = node;
    }, []);

    const focusInputInSearchBox = useCallback(() => {
        setIsMobileMenuOpen(true);
        const inputInSearchBox = searchBoxRef.current;
        FormUtils.focusAndOpenKeyboard(inputInSearchBox);
    }, []);

    return (
        <header id="headerMain">

            <Topbar />

            <hr className={styles.separatore}></hr>

            <Middlebar
                setIsMobileMenuOpen={setIsMobileMenuOpen}
                onSearchButtonClick={focusInputInSearchBox}
            />

            <hr className={styles.separatore}></hr>

            <Navbar
                isMobileMenuOpen={isMobileMenuOpen}
                setIsMobileMenuOpen={setIsMobileMenuOpen}
                setInputRef={setInputRef}
            />

            <hr className={styles.separatore}></hr>

            <NewsBreaking />

        </header>
    );
};

export default Header;