import { useState, useEffect } from 'react';
import { usePage } from '@inertiajs/react';
import { useTrackClicks, useTrackVisibility } from "../../Hooks";

const GTrack = () => {

    const { config } = usePage().props;

    const [token, setToken] = useState('');
    const [isTrackingEnabled, setIsTrackingEnabled] = useState(false);

    const GTRACK_DOMAIN = config.gtrack.url;

    useEffect(() => {
        const tokenData = JSON.parse(localStorage.getItem('GTrackingTokenData'));
        if (tokenData && new Date() < new Date(tokenData.expiry)) {
            setToken(tokenData.token);
            setIsTrackingEnabled(true);
        } else {
            fetchToken();
        }
    }, []);

    const fetchToken = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const headers = {'Content-Type': 'application/json'};
                const data = JSON.stringify({"website_code": config.gtrack.website_code});
                const response = await fetch(`${GTRACK_DOMAIN}/api/auth/token/generate`, {
                    method: "POST",
                    headers: headers,
                    body: data,
                    keepalive: true
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log('ACCESS TOKEN RESPONSE DATA', data);
                    const newToken = data.accessToken;
                    const expiry = new Date(new Date().getTime() + 24 * 60 * 60 * 1000); // Imposta la scadenza per 24 ore dopo
                    localStorage.setItem('GTrackingTokenData', JSON.stringify({ token: newToken, expiry }));
                    setToken(newToken);
                    setIsTrackingEnabled(true);
                    resolve(newToken); // Risolve la Promise con il nuovo token
                } else {
                    console.error('%cGTRACK:', 'color: red;', `Impossibile ottenere un nuovo token.`);
                    setIsTrackingEnabled(false);
                    reject(new Error('Failed to fetch token'));
                }
            } catch (error) {
                console.error('%cGTRACK:', 'color: red;', `Errore nella generazione del token: ${error}.`);
                setIsTrackingEnabled(false);
                reject(error);
            }
        });
    };    

    const sendBeacon = async (id, type, action = "VIEW", url = "", userSessionData = "{}", requestData = "", retryCount = 0) => {

        if (!token) {
            console.log('%cGTRACK:', 'color: red;', 'Token non disponibile, tracciamento sospeso.');
            return; // Se non c'è un token, non eseguire il tracciamento
        }

        const data = {
            website_id: 1,
            website_code: "vallesabbia",
            resource_id: id,
            resource_what: type, // nome della tabella core_banner, core_page, magazine_news
            type_action: action, // CLICK, VIEW, CLICK_LINK
            url: url, // Su un click link allora è il link dove vado / magazine_news url della news visitata / view di un banner vuoto
            user_session_data: userSessionData, // tutti i dati nell'header relativi all'utente che sono in una tipica richiesta http -> in teoria non ho io questi dati
            request_data: requestData // json di tutti i dati precedenti che sto mandando
        };

        // Assegna alla proprietà "request_data" tutti gli altri valori del JSON data
        const _requestData = { ...data };
        delete _requestData.request_data;
        data.request_data = JSON.stringify(_requestData);

        const beaconData = JSON.stringify(data);

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };

        try {
            const response = await fetch(`${GTRACK_DOMAIN}/api/event-tracker/track`, {
                method: 'POST',
                headers: headers,
                body: beaconData,
                keepalive: true
            });

            if (!response.ok) {
                // Se il token è scaduto o non valido, ottieni un nuovo token e riprova una volta
                if (response.status === 401 && retryCount < 1) { // Fa solo un singolo nuovo tentativo dopo un errore, perché un rinnovo del token dovrebbe bastare
                    console.log('Token expired, fetching a new one...');
                    await fetchToken();
                    // Esegue la richiesta di nuovo con il nuovo token e incrementa il contatore di tentativi
                    await sendBeacon(id, type, action, url, userSessionData, requestData, retryCount + 1);
                } else {
                    console.error('Beacon send failed:', response.statusText);
                }
            }
        } catch (error) {
            console.error('Send beacon failed:', error);
            if (retryCount < 1) { // Fa solo un singolo nuovo tentativo dopo un errore, perché un rinnovo del token dovrebbe bastare
                await fetchToken();
                await sendBeacon(id, type, action, url, userSessionData, requestData, retryCount + 1);
            }
        }
    };

    // Utilizza gli hook di tracciamento e invio tramite sendBeacon() solo se il tracciamento è abilitato
    
    {/* Track banner impression */}
    useTrackVisibility((element) => {
        const id = element.getAttribute('data-gtrack-id');
        const type = element.getAttribute('data-gtrack-type');
        const action = 'VIEW';
        const url = element.getAttribute('data-gtrack-link') || '';
        //const userSessionData = "{}"; // NON HO QUESTI DATI!!!!!!!!!!!!!!!!!!!!!!
        //const requestData = ""; // Viene popolato dopo
        sendBeacon(id, type, action, url);
    }, isTrackingEnabled);

    {/* Track click on banner links */}
    useTrackClicks((id, type, action, url) => {
        sendBeacon(id, type, action, url);
    }, isTrackingEnabled);

    if (!isTrackingEnabled){
        console.log('%cGTRACK:', 'color: red;', 'Token non disponibile, tracciamento sospeso.');
    } else {
        console.log('%cGTRACK:', 'color: green;', 'Token disponibile, tracciamento ripreso.');
    }
  
    return null;
};

export default GTrack;