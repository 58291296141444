import React, { useEffect } from "react";
//import styles from "./EventCategory.module.scss";
import Layout from "../../Layout/Main";
import HeadSEO from "../../Components/Head/Index";
import EventsDailyList from "../../Components/Event/EventsDailyList/Index";
import { componentFactory } from "../../Utils/ComponentsFactory";
import ComponentsUtils from "../../Utils/ComponentsUtils";
import { useRefreshProps } from "../../Hooks";
import SEOUtils from "../../Utils/SEOUtils";

function EventCategory(props) {
	const { page, locale, components, coreBannersAllPage, eventCategory, eventItems, ziggy, userLogged } = props;

	const componentMap = componentFactory();
	const zones = ["main", "asideTop", "asideMiddle", "asideBottom"];
	const FINAL_COMPONENTS = ComponentsUtils.getComponents(components, coreBannersAllPage, zones);

	{
		/** Aggiorna le props principali una volta ogni 5 minuti */
	}
	useEffect(() => {
		useRefreshProps(["components", "coreBannersAllPage", "eventCategory", "eventItems"], 300000);
	}, []);

	const eventsDailyListTitle = eventCategory?.header_title?.[locale]
		? eventCategory?.header_title?.[locale].trim()
		: eventCategory?.title?.[locale]
		? eventCategory?.title?.[locale].trim()
		: eventCategory?.name.trim() || "Eventi";

	const main = (
		<>
			{/** Lista eventi categoria */}
			<EventsDailyList events={eventItems} large={true} title={eventsDailyListTitle} />

			{/* Componenti main */}
			{FINAL_COMPONENTS.main?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"main",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}
		</>
	);

	const aside = (
		<>
			{/* Componenti asideTop */}
			{FINAL_COMPONENTS.asideTop?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"asideTop",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}
			{/* Componenti asideMiddle */}
			{FINAL_COMPONENTS.asideMiddle?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"asideMiddle",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}
			{/* Componenti asideBottom */}
			{FINAL_COMPONENTS.asideBottom?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"asideBottom",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}
		</>
	);

	const seoData = SEOUtils.generateSEOData({
		page: page,
		locale: locale,
		type: "eventCategory",
		ziggy: ziggy,
		otherProps: {
			eventCategory: eventCategory,
			events: eventItems,
		},
	});

	return (
		<>
			<HeadSEO data={seoData} />
			<Layout {...props} main={main} aside={aside} />
			{/** Componenti popup */}
			{ComponentsUtils.renderPopupComponents(FINAL_COMPONENTS.popup, componentMap)}
		</>
	);
}

export default EventCategory;
