import React from "react";
import { usePage } from "@inertiajs/react";
import styles from "./BasicImage.module.scss";

const BasicImage = ({ ...props }) => {
	const { locale } = usePage().props;

	//const image = props.data.custom_fields.url_image;
	const image = props.data.media1?.[0]?.url;

	const alt = props.data.text_url?.[locale];
	const title = props.data.text_url?.[locale];

	return (
		<a
			className={styles.wrapper}
			href={props.data.url?.[locale]}
			title={title !== null ? title : undefined}
			target="_blank"
			rel="nofollow noopener"
		>
			<img
				src={image}
				loading="lazy"
				className="img-fluid"
				alt={alt !== null ? alt : undefined}
				title={title !== null ? title : undefined}
			/>
		</a>
	);
};

export default BasicImage;
