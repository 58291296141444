import React, { useEffect, useState } from "react";
import styles from "./Search.module.scss";
import Layout from "../../Layout/Generico";
import HeadSEO from "../../Components/Head/Index";
import axios from "axios";
import { useRefreshProps } from "../../Hooks";
import Form from "../../Components/Form/Index";
import NewsCard from "../../Components/News/NewsCard/Index";
import { usePage } from "@inertiajs/react";
import SEOUtils from "../../Utils/SEOUtils";
import Skeleton from "react-loading-skeleton";

function Search(props) {
    const { page, locale, components, menus, coreBannersAllPage, userLogged, ziggy } = props;
    const { url } = usePage();

    const [keywords, setKeywords] = useState("");
    const [searchedKeyword, setSearchedKeyword] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [shouldDoAutoSubmit, setShouldDoAutoSubmit] = useState(false);

    const urlObj = new URL(url, ziggy.url);
    const keywordParam = urlObj.searchParams.get("keyword") || null;
    const pageTitle = keywordParam
        ? `Ricerca per: ${keywordParam}`
        : page?.header_title?.[locale]?.trim() || page?.title?.[locale]?.trim() || page?.name?.trim() || "Cerca";

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const keyword = params.get("keyword");
        if (keyword) {
            setKeywords(keyword);
        }
    }, []);

    {
        /** Aggiorna le props principali una volta ogni 5 minuti */
    }
    useEffect(() => {
        useRefreshProps(["components", "coreBannersAllPage"], 300000);
    }, []);

    const [authors, setAuthors] = useState([]);
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        const source = axios.CancelToken.source();

        axios
            .get("/api/news/get/authors", { cancelToken: source.token })
            .then((response) => {
                setAuthors(response.data);
            })
            .catch((error) => {
                /*if (axios.isCancel(error)) {
					console.log("Request canceled", error.message);
				} else {
					console.error("There was an error!", error);
				}*/
            });

        return () => {
            source.cancel("API request canceled due to component unmounting.");
        };
    }, []);

    useEffect(() => {
        if (keywords && authors?.data?.length > 0 && !shouldDoAutoSubmit) {
            setShouldDoAutoSubmit(true);
        }
    }, [keywords, authors]);

    const fieldsHTML = (
        <>
            <input type="hidden" value={locale} name="locale" />

            <div className="row">
                <div className="form-group col-md-6 font-sans">
                    <label htmlFor="keywords" className="font-small text-uppercase mb-1">
                        Parole Chiave:
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="keywords"
                        name="keyword"
                        value={keywords}
                        onChange={(e) => setKeywords(e.target.value)}
                        placeholder="Inserisci qui le parole chiave"
                        inputMode="search"
                    />
                </div>
                <div className="form-group col-md-6 mt-2 mt-md-0 font-sans">
                    <label htmlFor="author" className="font-small text-uppercase mb-1">
                        Autore:
                    </label>
                    <select className="form-control form-select" id="author" name="magazine_author_id">
                        <option disabled selected value="">
                            Seleziona un autore...
                        </option>
                        {authors?.data?.map((author) => (
                            <option key={author.id} value={author.id}>
                                {author.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="row mt-2 mt-lg-3">
                <div className="form-group col-md-6 font-sans">
                    <label htmlFor="dateFrom" className="font-small text-uppercase mb-1">
                        Data Dal:
                    </label>
                    <input type="date" className="form-control" id="dateFrom" name="date_from" />
                </div>
                <div className="form-group col-md-6 font-sans">
                    <label htmlFor="dateTo" className="font-small text-uppercase mb-1">
                        Data Al:
                    </label>
                    <input type="date" className="form-control" id="dateTo" name="date_to" />
                </div>
            </div>

            <div className="row mt-2 mt-lg-3">
                <div className="form-group col-md-12">
                    <input name="submit" type="submit" value="cerca" className="btn btn-primary" />
                </div>
            </div>
        </>
    );

    const main = (
        <>
            <div className="row justify-content-center pt-4">
                <div className="col-xl-7">
                    <h1 className="h2 text-center">Cerca nell'archivio di ValleSabbiaNews</h1>
                    <p className="text-center mt-3 px-sm-5">
                        Le notizie di ieri e oggi sono a portata di click. Consulta l'archivio storico di
                        ValleSabbiaNews per rivivere la cronaca del passato e aggiornarti su quella presente.
                    </p>
                </div>
            </div>

            <div className="row justify-content-center mt-4">
                <div className="col-xl-6">
                    <Form
                        apiUrl={"/api/news/search"}
                        autoSubmit={shouldDoAutoSubmit}
                        method="GET"
                        fieldsHTML={fieldsHTML}
                        stateReset={true}
                        stateResetDelay={0}
                        refreshPageProps={false}
                        onSuccess={(response) => {
                            // console.log("response search", response.data);
                            setSearchResults(response.data);
                            setIsSubmitted(true);
                            setSearchedKeyword(keywords);
                        }}
                    />
                </div>
            </div>

            {/* Se ci sono risultati di ricerca */}
            {searchResults.length > 0 && (
                <div className="row justify-content-center mt-5">
                    <div className="col-xl-7 text-center">
                        <small className="text-uppercase font-sans">
                            {searchResults.length}{" "}
                            {searchResults.length === 1 ? "articolo trovato" : "articoli trovati"}
                        </small>
                        <h2 className="h3 mt-1">I risultati della tua ricerca:</h2>
                    </div>
                    <div className="col-xl-8">
                        <ul className="mt-4">
                            {searchResults.map((news, index) => (
                                <>
                                    <NewsCard
                                        key={`NewsCardSearchResult-${index}`}
                                        horizontal={true}
                                        large={true}
                                        imageAspectRatio={"3/2"}
                                        data={news}
                                    />
                                    {/* Separatore */}
                                    <hr className="separatore"></hr>
                                </>
                            ))}
                        </ul>
                    </div>
                </div>
            )}

            {/* Se dopo la ricerca non ci sono risultati */}
            {searchResults.length === 0 && isSubmitted && (
                <div className="row justify-content-center mt-5">
                    <div className="col-xl-7 text-center">
                        <h2 className="h3 mt-1">Nessun risultato trovato per "{searchedKeyword}"</h2>
                        <p className="mt-2">Prova con parole chiave o filtri diversi.</p>
                    </div>
                </div>
            )}

            {/* Durante la ricerca mostro gli skeleton */}
            {shouldDoAutoSubmit && !isSubmitted && (
                <div className="row justify-content-center mt-5">
                    <div className="col-xl-7 text-center">
                        <h2 className="h3 mt-1 mb-5">Ricerca in corso per "{keywords}"...</h2>
                        {[...Array(5)].map((_, index) => (
                            <React.Fragment key={index}>
                                <Skeleton containerClassName={`${styles.skeletonSearch}`} inline={true} />
                                {index !== 4 && <hr className="separatore" />}{" "}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            )}
        </>
    );

    const seoData = SEOUtils.generateSEOData({
        page: page,
        locale: locale,
        type: "search",
        ziggy: ziggy,
        otherProps: {
            title: pageTitle,
        },
    });

    return (
        <>
            <HeadSEO data={seoData} />
            <Layout {...props} main={main} />
        </>
    );
}

export default Search;
