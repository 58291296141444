import React, { useEffect } from "react";
import styles from "./Contacts.module.scss";
import Layout from "../../Layout/Main";
import FormContatti from "../../Components/Form/FormContatti/Index";
import HeadSEO from "../../Components/Head/Index";
import { componentFactory } from "../../Utils/ComponentsFactory";
import ComponentsUtils from "../../Utils/ComponentsUtils";
import { useRefreshProps } from "../../Hooks";
import SEOUtils from "../../Utils/SEOUtils";

function Contacts(props) {
	const { page, locale, components, coreBannersAllPage, userLogged, ziggy } = props;

	const componentMap = componentFactory();
	const zones = ["main", "asideTop", "asideMiddle", "asideBottom"];
	const FINAL_COMPONENTS = ComponentsUtils.getComponents(components, coreBannersAllPage, zones);

	{
		/** Aggiorna le props principali una volta ogni 5 minuti */
	}
	useEffect(() => {
		useRefreshProps(["components", "coreBannersAllPage"], 300000);
	}, []);

	const main = (
		<>
			<section className="mb-4">
				<h1 className="text-uppercase h2">Scrivici</h1>
				{page.summary[locale] && <p className="mt-2 font-serif-alt">{magazineGroup.summary[locale]}</p>}
				{page.content[locale] && (
					<div
						className={`mt-4 font-serif-alt ${styles.pageContent}`}
						dangerouslySetInnerHTML={{
							__html: page.content[locale],
						}}
					/>
				)}
			</section>

			<FormContatti />

			{/* Componenti main */}
			{FINAL_COMPONENTS.main?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"main",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}
		</>
	);

	const aside = (
		<>
			{/** Componenti asideTop */}
			{FINAL_COMPONENTS.asideTop?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"asideTop",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}

			{/** Componenti asideMiddle */}
			{FINAL_COMPONENTS.asideMiddle?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"asideMiddle",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}

			{/** Componenti asideBottom */}
			{FINAL_COMPONENTS.asideBottom?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"asideBottom",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}
		</>
	);

	const seoData = SEOUtils.generateSEOData({
		page: page,
		locale: locale,
		type: "contactPage",
		ziggy: ziggy,
		otherProps: {},
	});

	return (
		<>
			<HeadSEO data={seoData} />
			<Layout {...props} main={main} aside={aside} />
			{/** Componenti popup */}
			{ComponentsUtils.renderPopupComponents(FINAL_COMPONENTS.popup, componentMap)}
		</>
	);
}

export default Contacts;
