import React from "react";
import styles from "./SubscriptionThankYou.module.scss";
import { usePage, Link } from "@inertiajs/react";

import SVG from "../../../Utils/SVG";

const SubscriptionThankYou = ({ subscription, paymentMethod, paymentMethodDetails, email }) => {
    const { locale } = usePage().props;

    /* console.log("subscription", subscription);
	console.log("paymentMethod", paymentMethod);
	console.log("paymentMethodDetails", paymentMethodDetails); */

    let successMessage = paymentMethodDetails[paymentMethod].successMessage.replace(
        /\[email\]/g,
        `<strong>${email}</strong>`
    );

    return (
        <div className={`container`}>
            <div className={`row`}>
                <div className={`col-xl-6 offset-xl-3`}>
                    <div className={styles.confirmationIcon}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: SVG.checkCircle,
                            }}
                        />
                        <h1 className="h3">Abbonamento sottoscritto con successo</h1>
                    </div>
                    <p className="mt-3">
                        Grazie per esserti abbonato a ValleSabbiaNews.
                        <br />
                        Nella tua area personale puoi consultare i dettagli del tuo abbonamento.
                        {/* Info relative a PayPal */}
                        {paymentMethod?.toLowerCase().includes("paypal") && (
                            <>
                                <br />
                                <strong>Nota:</strong> L'attivazione dell'abbonamento tramite PayPal potrebbe richiedere
                                alcuni minuti.
                            </>
                        )}
                    </p>

                    <div className={`${styles.nextSteps} mt-3`}>
                        <h2 className="h5 mt-4">Cosa succede ora?</h2>
                        <p className="mt-2" dangerouslySetInnerHTML={{ __html: successMessage }} />
                        <Link href={`/${locale}`} className="mt-3 btn btn-primary">
                            Sfoglia il giornale online
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionThankYou;
