const SEOUtils = {
	generateSEOData: ({ page, locale, type, ziggy, ...props }) => {
		let seoData = {
			title: page?.header_title?.[locale]
				? page?.header_title?.[locale].trim()
				: page?.title?.[locale]
				? page?.title?.[locale].trim()
				: page?.name.trim() || "ValleSabbiaNews",
			description: page?.header_description?.[locale]
				? page?.header_description?.[locale].trim()
				: "ValleSabbiaNews",
			keywords: page?.header_keyword?.[locale] ? page?.header_keyword?.[locale].trim() : "valle, sabbia, news",
			url: page?.url?.[locale]?.url ? ziggy?.url + page?.url?.[locale]?.url : ziggy?.location,
			image: page?.cover_image?.[0]?.url
				? page?.cover_image?.[0]?.url
				: ziggy?.url + "/favicon/android-chrome-512x512.png",
			structuredData: {},
		};

		switch (type) {
			case "home":
			case "default":
				// Logica per generare metadati per una pagina standard e la home
				seoData.structuredData = SEOUtils.generateStructuredData("NewsMediaOrganization", locale);
				break;
			case "search":
				// Logica per generare metadati per eventi
				seoData.title = props?.otherProps?.title;
				seoData.structuredData = SEOUtils.generateStructuredData("SearchResultsPage", locale, props, seoData);
				break;
			case "galleryCategory":
				// Logica per generare metadati per eventi
				const categoryData = props?.otherProps?.galleryCategory || props?.otherProps?.galleryCategories;
				seoData = {
					title: props?.otherProps?.title,
					description: categoryData?.header_description?.[locale]
						? categoryData?.header_description?.[locale].trim()
						: "ValleSabbiaNews",
					keywords: categoryData?.header_keyword?.[locale]
						? categoryData?.header_keyword?.[locale].trim()
						: "valle, sabbia, news",
					url: categoryData?.url?.[locale]?.url
						? ziggy?.url + categoryData?.url?.[locale]?.url
						: ziggy?.location,
					image: categoryData?.cover_image?.[0]?.url
						? categoryData?.cover_image?.[0]?.url
						: ziggy?.url + "/favicon/android-chrome-512x512.png",
				};
				// In base al tipo di pagina chiama generateStructuredData di conseguenza
				if (props?.otherProps?.galleryCategory) {
					// Pagina di categoria
					seoData.structuredData = SEOUtils.generateStructuredData(
						"GalleryCategory",
						locale,
						props,
						seoData,
						ziggy
					);
				} else if (props?.otherProps?.galleryCategories) {
					// Pagina principale della galleria
					seoData.structuredData = SEOUtils.generateStructuredData(
						"GalleryCollectionPage",
						locale,
						props,
						seoData,
						ziggy
					);
				}
				break;
			case "events":
				// Logica per generare metadati per eventi
				seoData.title = props?.otherProps?.title;
				seoData.structuredData = SEOUtils.generateStructuredData(
					"EventsCollectionPage",
					locale,
					props,
					seoData,
					ziggy
				);
				break;
			case "eventItem":
				// Logica per generare metadati per un singolo evento
				seoData = {
					title: props?.otherProps?.eventItem?.header_title?.[locale]
						? props?.otherProps?.eventItem?.header_title?.[locale].trim()
						: props?.otherProps?.eventItem?.title?.[locale]
						? props?.otherProps?.eventItem?.title?.[locale].trim()
						: props?.otherProps?.eventItem?.name.trim() || "ValleSabbiaNews",
					description: props?.otherProps?.eventItem?.header_description?.[locale]
						? props?.otherProps?.eventItem?.header_description?.[locale].trim()
						: props?.otherProps?.eventItem?.summary?.[locale]
						? props?.otherProps?.eventItem?.summary?.[locale].trim()
						: props?.otherProps?.eventItem?.content?.[locale]
						? props?.otherProps?.eventItem?.content?.[locale].trim()
						: "ValleSabbiaNews",
					keywords: props?.otherProps?.eventItem?.header_keyword?.[locale]
						? props?.otherProps?.eventItem?.header_keyword?.[locale].trim()
						: "valle, sabbia, news",
					url: props?.otherProps?.eventItem?.url?.[locale]?.url
						? ziggy?.url + props?.otherProps?.eventItem?.url?.[locale]?.url
						: ziggy?.location,
					image: props?.otherProps?.eventItem?.cover_image?.[0]?.url
						? props?.otherProps?.eventItem?.cover_image?.[0]?.url
						: ziggy?.url + "/favicon/android-chrome-512x512.png",
				};
				seoData.structuredData = SEOUtils.generateStructuredData("Event", locale, props, seoData, ziggy);

				break;
			case "eventCategory":
				// Logica per generare metadati per la singola categoria evento
				seoData = {
					title: props?.otherProps?.eventCategory?.header_title?.[locale]
						? props?.otherProps?.eventCategory?.header_title?.[locale].trim()
						: props?.otherProps?.eventCategory?.title?.[locale]
						? props?.otherProps?.eventCategory?.title?.[locale].trim()
						: props?.otherProps?.eventCategory?.name.trim() || "ValleSabbiaNews",
					description: props?.otherProps?.eventCategory?.header_description?.[locale]
						? props?.otherProps?.eventCategory?.header_description?.[locale].trim()
						: props?.otherProps?.eventCategory?.summary?.[locale]
						? props?.otherProps?.eventCategory?.summary?.[locale].trim()
						: props?.otherProps?.eventCategory?.content?.[locale]
						? props?.otherProps?.eventCategory?.content?.[locale].trim()
						: "ValleSabbiaNews",
					keywords: props?.otherProps?.eventCategory?.header_keyword?.[locale]
						? props?.otherProps?.eventCategory?.header_keyword?.[locale].trim()
						: "valle, sabbia, news",
					url: props?.otherProps?.eventCategory?.url?.[locale]?.url
						? ziggy?.url + props?.otherProps?.eventCategory?.url?.[locale]?.url
						: ziggy?.location,
					image: props?.otherProps?.eventCategory?.cover_image?.[0]?.url
						? props?.otherProps?.eventCategory?.cover_image?.[0]?.url
						: ziggy?.url + "/favicon/android-chrome-512x512.png",
				};
				seoData.structuredData = SEOUtils.generateStructuredData(
					"EventsCollectionPage",
					locale,
					props,
					seoData,
					ziggy
				);

				break;
			case "newsArticle":
				// Logica per generare metadati per un articolo di notizie
				seoData = {
					title: props?.otherProps?.newsArticle?.header_title?.[locale]
						? props?.otherProps?.newsArticle?.header_title?.[locale].trim()
						: props?.otherProps?.newsArticle?.title?.[locale]
						? props?.otherProps?.newsArticle?.title?.[locale].trim()
						: props?.otherProps?.newsArticle?.name.trim() || "ValleSabbiaNews",
					description: props?.otherProps?.newsArticle?.header_description?.[locale]
						? props?.otherProps?.newsArticle?.header_description?.[locale].trim()
						: props?.otherProps?.newsArticle?.summary?.[locale]
						? props?.otherProps?.newsArticle?.summary?.[locale].trim()
						: "ValleSabbiaNews",
					keywords: props?.otherProps?.newsArticle?.header_keyword?.[locale]
						? props?.otherProps?.newsArticle?.header_keyword?.[locale].trim()
						: "valle, sabbia, news",
					url: props?.otherProps?.newsArticle?.url?.[locale]?.url
						? ziggy?.url + props?.otherProps?.newsArticle?.url?.[locale]?.url
						: ziggy?.location,
					image: props?.otherProps?.newsArticle?.cover_image?.[0]?.url
						? props?.otherProps?.newsArticle?.cover_image?.[0]?.url
						: ziggy?.url + "/favicon/android-chrome-512x512.png",
				};

				if (seoData.description) seoData.description = SEOUtils.removeHTMLTags(seoData.description);

				seoData.structuredData = SEOUtils.generateStructuredData("NewsArticle", locale, props, seoData, ziggy);

				break;
			case "newsCategory":
				// Logica per generare metadati per una categoria di notizie
				seoData = {
					title: props?.otherProps?.newsCategory?.header_title?.[locale]
						? props?.otherProps?.newsCategory?.header_title?.[locale].trim()
						: props?.otherProps?.newsCategory?.title?.[locale]
						? props?.otherProps?.newsCategory?.title?.[locale].trim()
						: props?.otherProps?.newsCategory?.name.trim() || "ValleSabbiaNews",
					description: props?.otherProps?.newsCategory?.header_description?.[locale]
						? props?.otherProps?.newsCategory?.header_description?.[locale].trim()
						: props?.otherProps?.newsCategory?.summary?.[locale]
						? props?.otherProps?.newsCategory?.summary?.[locale].trim()
						: "ValleSabbiaNews",
					keywords: props?.otherProps?.newsCategory?.header_keyword?.[locale]
						? props?.otherProps?.newsCategory?.header_keyword?.[locale].trim()
						: "valle, sabbia, news",
					url: props?.otherProps?.newsCategory?.url?.[locale]?.url
						? ziggy?.url + props?.otherProps?.newsCategory?.url?.[locale]?.url
						: ziggy?.location,
					image: props?.otherProps?.newsCategory?.cover_image?.[0]?.url
						? props?.otherProps?.newsCategory?.cover_image?.[0]?.url
						: ziggy?.url + "/favicon/android-chrome-512x512.png",
				};

				seoData.structuredData = SEOUtils.generateStructuredData(
					"MagazineCollectionPage",
					locale,
					props,
					seoData,
					ziggy
				);

				break;
			case "necrologi":
				// Logica per generare metadati per la pagina necrologi
				seoData.structuredData = SEOUtils.generateStructuredData("NecrologiItemList", locale, props);
				break;
			case "books":
				// Logica per generare metadati per la pagina libri
				seoData.structuredData = SEOUtils.generateStructuredData("BooksItemList", locale, props);
				break;
			case "contactPage":
				// Logica per generare metadati per la pagina libri
				seoData.structuredData = SEOUtils.generateStructuredData("ContactPage", locale, props, seoData);
				break;
			// Altri tipi di pagina
		}

		// Generazione dei dati strutturati (JSON-LD, ecc.) in base al tipo di pagina
		// ...
		return seoData;
	},
	generateStructuredData: (type, locale, props = null, seoData = null, ziggy = null) => {
		let structuredData = {};
		switch (type) {
			//
			case "ContactPage":
				structuredData = {
					"@context": "http://schema.org",
					"@type": "ContactPage",
					name: seoData.title,
					description: seoData.description,
					url: seoData.url,
					contactPoint: {
						"@type": "ContactPoint",
						email: "redazione@vallesabbianews.it",
						contactType: "Newsroom Contact",
						areaServed: "IT",
						availableLanguage: ["Italian"],
						url: seoData.url,
					},
				};
				break;

			// Dato strutturato "NewsMediaOrganization"
			case "NewsMediaOrganization":
				structuredData = {
					"@context": "https://schema.org",
					"@type": "NewsMediaOrganization",
					name: "Edizioni Valle Sabbia Srl",
					url: "https://www.vallesabbianews.it",
					logo: {
						"@type": "ImageObject",
						url: "https://www.vallesabbianews.it/images/logo.svg",
						width: 783.1,
						height: 140,
					},
					contactPoint: {
						"@type": "ContactPoint",
						email: "redazione@vallesabbianews.it",
						contactType: "Newsroom Contact",
						areaServed: "IT",
						availableLanguage: ["Italian"],
						url: "https://www.vallesabbianews.it/it/scrivici",
					},
					sameAs: [
						"https://www.facebook.com/vallesabbianews",
						"https://www.youtube.com/user/vallesabbianews",
					],
				};
				break;

			// Dato strutturato ItemList dedicato ai libri
			case "BooksItemList":
				const booksList = props?.otherProps?.booksList;
				const itemListElement = booksList.map((book, index) => {
					const isbn = book?.custom_fields?.isbn || null;
					const title = book?.title?.[locale] || null;
					const author = book?.summary?.[locale] || null;
					const datePublished = book?.custom_fields?.anno_pubblicazione || null;
					const image = (book?.media1 && book?.media1[0]?.url) || null;
					const description = book?.content?.[locale] || null;
					const price = book?.custom_fields?.price ? parseFloat(book.custom_fields.price) : null;
					let item = {
						"@type": "Book",
						publisher: {
							"@type": "NewsMediaOrganization",
							name: "Edizioni Valle Sabbia Srl",
						},
					};
					if (isbn) item.isbn = isbn;
					if (title) item.name = title;
					if (author) item.author = SEOUtils.parseAuthors(author);
					if (datePublished) item.datePublished = datePublished;
					if (image) item.image = image;
					if (description) item.description = SEOUtils.removeHTMLTags(description);
					if (price)
						item.offers = {
							"@type": "Offer",
							price: price,
							priceCurrency: "EUR",
							availability: "http://schema.org/InStock",
						};
					return {
						"@type": "ListItem",
						position: index + 1,
						item: item,
					};
				});
				structuredData = {
					"@context": "https://schema.org",
					"@type": "ItemList",
				};
				structuredData.itemListElement = itemListElement;
				break;

			// Dato strutturato ItemList dedicato ai necrologi
			case "NecrologiItemList":
				const necrologiList = props?.otherProps?.obituaries;
				const necrologiItemListElement = necrologiList.map((necrologio, index) => {
					const name = necrologio?.name || null;
					const deathDate = necrologio?.item_date || null;
					const description = necrologio?.content1?.[locale] || null;
					const image = (necrologio?.image && necrologio?.image[0]?.url) || null;
					const age = necrologio?.age || null;
					const organization = necrologio?.funeral_honour || null;
					const location = necrologio?.location || null;
					let item = {
						"@type": "Person",
					};
					if (name) item.name = name;
					if (deathDate) item.deathDate = deathDate;
					if (description) item.description = SEOUtils.removeHTMLTags(description);
					if (image) item.image = image;
					if (age) {
						item.additionalProperty = {
							"@type": "PropertyValue",
							name: "Age",
							value: age,
						};
					}
					if (organization) {
						item.memberOf = {
							"@type": "Organization",
							name: organization,
						};
					}
					if (location) {
						item.location = {
							"@type": "Place",
							name: location,
						};
					}

					return {
						"@type": "ListItem",
						position: index + 1,
						item: item,
					};
				});
				structuredData = {
					"@context": "https://schema.org",
					"@type": "ItemList",
				};
				structuredData.itemListElement = necrologiItemListElement;
				break;

			// Dato strutturato per pagina di risultati di ricerca
			case "SearchResultsPage":
				structuredData = {
					"@context": "http://schema.org",
					"@type": "SearchResultsPage",
					name: seoData.title,
					description: seoData.description,
					url: seoData.url,
				};
				break;

			// Dato strutturato per pagina di categoria
			case "MagazineCollectionPage":
				const newsCategory = props?.otherProps?.newsCategory;
				const newsList = newsCategory?.news?.magazineNews;
				const newsItemListElement = newsList.map((news, index) => {
					const headline = news?.header_title?.[locale]
						? news?.header_title?.[locale].trim()
						: news?.title?.[locale]
						? news?.title?.[locale].trim()
						: null;
					const url = ziggy.url + news?.url?.[locale]?.url || null;
					const image = news?.cover_image?.[0]?.url
						? news?.cover_image?.[0]?.url
						: ziggy?.url + "/favicon/android-chrome-512x512.png";
					const publish_datetime = news?.publish_datetime || null;
					const updated_at = news?.updated_at || null;
					const author = news?.author_nickname || null;
					const summary = news?.summary?.[locale] || null;

					let item = {
						"@type": "NewsArticle",
						mainEntityOfPage: {
							"@type": "WebPage",
							"@id": url,
						},
						headline: headline,
						image: {
							"@type": "ImageObject",
							url: image,
						},
						publisher: {
							"@type": "NewsMediaOrganization",
							name: "Edizioni Valle Sabbia Srl",
							logo: {
								"@type": "ImageObject",
								url: "https://www.vallesabbianews.it/images/logo.svg",
								width: 783.1,
								height: 140,
							},
						},
					};
					if (publish_datetime) item.datePublished = publish_datetime;
					if (updated_at) item.dateModified = updated_at;
					if (summary) item.description = SEOUtils.removeHTMLTags(summary);
					if (author) {
						item.author = {
							"@type": "Person",
							name: author,
						};
					}

					return {
						"@type": "ListItem",
						position: index + 1,
						item: item,
					};
				});

				structuredData = {
					"@context": "http://schema.org",
					"@type": "CollectionPage",
					name: seoData.title,
					description: seoData.description,
					url: seoData.url,
					breadcrumb: {
						"@type": "BreadcrumbList",
						itemListElement: [
							{
								"@type": "ListItem",
								position: 1,
								item: {
									"@id": ziggy?.url + "/" + locale,
									name: "Home",
								},
							},
							{
								"@type": "ListItem",
								position: 2,
								item: {
									"@id": seoData.url,
									name: seoData.title,
								},
							},
						],
					},
					mainEntity: {
						"@type": "ItemList",
						numberOfItems: newsList?.length || 0,
						itemListElement: newsItemListElement,
					},
				};
				break;

			// Dato strutturato per pagina di categoria
			case "NewsArticle":
				const news = props?.otherProps?.newsArticle;
				const headline = news?.header_title?.[locale]
					? news?.header_title?.[locale].trim()
					: news?.title?.[locale]
					? news?.title?.[locale].trim()
					: null;
				const url = ziggy.url + news?.url?.[locale]?.url || null;
				let images = [];
				if (news && news.cover_image && news.cover_image.length > 0) {
					images = news.cover_image.map((image) => image.url);
				} else {
					images = [ziggy.url + "/favicon/android-chrome-512x512.png"];
				}
				const publish_datetime = news?.publish_datetime || null;
				const updated_at = news?.updated_at || null;
				const author = news?.author_nickname || null;
				const summary = news?.summary?.[locale] || null;
				const content = news?.content?.[locale] || null;
				const isAccessibleForFree = !Object.entries(news?.pa_package_enabled).some(
					([key, value]) => key !== "1" && value === "1"
				);

				structuredData = {
					"@context": "http://schema.org",
					"@type": "NewsArticle",
					mainEntityOfPage: {
						"@type": "WebPage",
						"@id": url,
					},
					headline: headline,
					image: images,
					publisher: {
						"@type": "NewsMediaOrganization",
						name: "Edizioni Valle Sabbia Srl",
						logo: {
							"@type": "ImageObject",
							url: "https://www.vallesabbianews.it/images/logo.svg",
							width: 783.1,
							height: 140,
						},
					},
					isAccessibleForFree: isAccessibleForFree,
					hasPart: {
						"@type": "WebPageElement",
						isAccessibleForFree: isAccessibleForFree,
					},
				};

				if (publish_datetime) structuredData.datePublished = publish_datetime;
				if (updated_at) structuredData.dateModified = updated_at;
				if (summary) structuredData.description = SEOUtils.removeHTMLTags(summary);
				if (content) structuredData.articleBody = SEOUtils.removeHTMLTags(content);
				if (author) {
					structuredData.author = {
						"@type": "Person",
						name: author,
					};
				}
				break;

			// Dati strutturati per pagina di categoria
			case "GalleryCollectionPage":
				const galleryCategories = props?.otherProps?.galleryCategories;
				const imageGalleryList = galleryCategories.map((gallery, index) => {
					const title = gallery?.header_title?.[locale]
						? gallery?.header_title?.[locale].trim()
						: gallery?.title?.[locale]
						? gallery?.title?.[locale].trim()
						: null;
					const description = gallery?.header_description?.[locale]
						? gallery?.header_description?.[locale].trim()
						: gallery?.summary?.[locale]
						? gallery?.summary?.[locale].trim()
						: gallery?.content?.[locale]
						? gallery?.content?.[locale].trim()
						: null;
					const url = ziggy.url + gallery?.url?.[locale]?.url || null;
					/*let images = [];
                    if (gallery && gallery.cover_image && gallery.cover_image.length > 0) {
                        images = gallery.cover_image.map(image => image.url);
                    } else {
                        images = [ziggy.url + '/favicon/android-chrome-512x512.png'];
                    }*/
					const created_at = gallery?.created_at || null;
					const updated_at = gallery?.updated_at || null;
					let item = {
						"@type": "ImageGallery",
						name: title,
						description: description,
						url: url,
						/*"numberOfItems": images.length,
                        "image": images*/
					};
					if (created_at) item.dateCreated = created_at;
					if (updated_at) item.dateModified = updated_at;

					return item;
				});
				structuredData = {
					"@context": "http://schema.org",
					"@type": "CollectionPage",
					name: seoData.title,
					description: seoData.description,
					url: seoData.url,
					breadcrumb: {
						"@type": "BreadcrumbList",
						itemListElement: [
							{
								"@type": "ListItem",
								position: 1,
								item: {
									"@id": "https://www.vallesabbianews.it",
									name: "Home",
								},
							},
							{
								"@type": "ListItem",
								position: 2,
								item: {
									"@id": seoData.url,
									name: seoData.title,
								},
							},
						],
					},
					hasPart: imageGalleryList,
				};
				break;

			// Dati strutturati per pagina di categoria
			case "GalleryCategory":
				const galleryCategory = props?.otherProps?.galleryCategory;
				const subcategoriesList = galleryCategory?.children?.map((gallery, index) => {
					const title = gallery?.header_title?.[locale]
						? gallery?.header_title?.[locale].trim()
						: gallery?.title?.[locale]
						? gallery?.title?.[locale].trim()
						: null;
					const description = gallery?.header_description?.[locale]
						? gallery?.header_description?.[locale].trim()
						: gallery?.summary?.[locale]
						? gallery?.summary?.[locale].trim()
						: gallery?.content?.[locale]
						? gallery?.content?.[locale].trim()
						: null;
					const url = ziggy.url + gallery?.url?.[locale]?.url || null;
					let images = [];
					if (gallery && gallery.cover_image && gallery.cover_image.length > 0) {
						images = gallery.cover_image.map((image) => image.url);
					} else {
						images = [ziggy.url + "/favicon/android-chrome-512x512.png"];
					}
					const created_at = gallery?.created_at || null;
					const updated_at = gallery?.updated_at || null;
					let item = {
						"@type": "ImageGallery",
						name: title,
						description: description,
						url: url,
						numberOfItems: images.length,
						image: images,
					};
					if (created_at) item.dateCreated = created_at;
					if (updated_at) item.dateModified = updated_at;

					return item;
				});
				const imagesList = galleryCategory?.gallery_item?.map((_image, index) => {
					const image = _image?.cover_image?.[0];
					const title = image?.data?.title?.[0] || null;
					const alt = image?.data?.alt?.[0] || null;
					const url = image?.url || null;
					let item = {
						"@type": "ImageObject",
						url: url,
					};
					if (title) item.name = title;
					if (alt) item.description = alt;
					return item;
				});
				let parts = [...(subcategoriesList ?? []), ...(imagesList ?? [])];
				structuredData = {
					"@context": "http://schema.org",
					"@type": "CollectionPage",
					name: seoData.title,
					description: seoData.description,
					url: seoData.url,
					hasPart: parts,
				};
				break;

			// Dati strutturati per pagina di categoria evento o lista eventi
			case "EventsCollectionPage":
				const events = props?.otherProps?.events;
				const eventsList = events.map((event) => SEOUtils.processEvent(event, locale, ziggy));

				structuredData = {
					"@context": "http://schema.org",
					"@type": "CollectionPage",
					name: seoData.title,
					description: seoData.description,
					url: seoData.url,
					breadcrumb: {
						"@type": "BreadcrumbList",
						itemListElement: [
							{
								"@type": "ListItem",
								position: 1,
								item: {
									"@id": "https://www.vallesabbianews.it",
									name: "Home",
								},
							},
							{
								"@type": "ListItem",
								position: 2,
								item: {
									"@id": seoData.url,
									name: seoData.title,
								},
							},
						],
					},
					mainEntity: {
						"@type": "ItemList",
						itemListElement: eventsList,
					},
				};
				break;

			// Dati strutturati per pagina di dettaglio evento
			case "Event":
				const event = props?.otherProps?.eventItem;
				structuredData = SEOUtils.processEvent(event, locale, ziggy);
				break;
		}
		return structuredData;
	},
	parseAuthors: (authorsString) => {
		// Sostituisce la congiunzione " e " con una virgola, assicurandoti che non sia parte di un nome proprio
		let normalizedString = authorsString.replace(/\s+e\s+/g, ", ");

		// Gestisce i casi in cui ci sono virgole senza spazio dopo di esse
		normalizedString = normalizedString.replace(/,\s*/g, ", ");

		// Divide la stringa in un array usando la virgola come separatore
		let authorsArray = normalizedString.split(", ");

		// Rimuove spazi vuoti extra e filtra eventuali stringhe vuote
		authorsArray = authorsArray
			.map((name) => name.trim())
			.filter((name) => name)
			.map((name) => ({
				"@type": "Person",
				name: name,
			}));

		return authorsArray;
	},
	removeHTMLTags: (str) => {
		return str.replace(/<\/?[^>]+(>|$)/g, "");
	},
	processEvent: (event, locale, ziggy) => {
		const name = event?.header_title?.[locale] || event?.title?.[locale] || event?.name;
		const description = event?.header_description?.[locale] || event?.summary?.[locale] || event?.content?.[locale];
		const url = ziggy.url + event?.url?.[locale]?.url || null;
		const startDate = event?.date_from
			? `${event.date_from}${event?.time_from ? `T${event.time_from}` : ""}`
			: null;
		const endDate = event?.date_to ? `${event.date_to}${event?.time_to ? `T${event.time_to}` : ""}` : null;

		let locationName =
			event?.location ||
			/*event.event_category?.[0]?.header_title?.[locale] ||*/ event.event_category?.[0]?.title?.[locale] ||
			event.event_category?.[0]?.name;

		let images =
			event?.cover_image && event.cover_image.length > 0
				? event.cover_image.map((image) => image.url)
				: [ziggy.url + "/favicon/android-chrome-512x512.png"];

		let item = {
			"@context": "http://schema.org",
			"@type": "Event",
			name: name?.trim() || null,
			image: images,
			url: url,
		};

		if (description) {
			item.description = description.trim();
		}

		if (startDate) {
			item.startDate = startDate;
		}

		if (endDate) {
			item.endDate = endDate;
		}

		if (locationName) {
			item.location = {
				"@type": "Place",
				name: locationName,
			};
		}

		return item;
	},
};

export default SEOUtils;
