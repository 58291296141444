import React from "react";
//import styles from './UserNotLogged.module.scss';

export default function UserNotLogged() {
	return (
		<>
			<h1>Area riservata</h1>
			<p className="mt-2 mb-4">Per poter visualizzare questa pagina devi effettuare il log-in.</p>
			<button
				className="btn btn-primary"
				data-bs-toggle="modal"
				data-bs-target="#loginModal"
				style={{ width: "288px" }}
			>
				Effettua il log-in
			</button>
		</>
	);
}
