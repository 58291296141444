import React from 'react';
import { Link } from '@inertiajs/react'

function DossierLink({ href, title, countArticles }) {
    return (
        <Link href={href} title={title} className='dossierLink'>
            <h4 className='text-uppercase h6'>{title}</h4>
            <span className='d-block mt-1 text-gray'>
                {countArticles} articoli
            </span>
        </Link>
    );
}

export default DossierLink;