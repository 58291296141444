import React, { useEffect, useState } from "react";
import styles from "./Popup.module.scss";
import { usePage } from "@inertiajs/react";
import GTrackUtils from "../../Utils/GTrackUtils";

const Popup = ({ ...props }) => {
	const [Bootstrap, setBootstrap] = useState(null);
	const { data } = props;
	const { locale } = usePage().props;

	const { shouldIncludeDataGTrack, gTrackAction } = GTrackUtils.generateGTrackAction(
		data?.custom_fields?.gTrack_click,
		data?.custom_fields?.gTrack_view
	);

	const id = data.id;
	const title = data.title?.[locale] || "";
	const summary = data.summary?.[locale] || "";
	const content = data.content?.[locale] || "";
	const url = data.url?.[locale] || "";
	const textUrl = data.text_url?.[locale] || "";
	const image = data?.media1?.[0] || "";

	useEffect(() => {
		// Importa bootstrap asincronamente
		import("bootstrap")
			.then((bootstrap) => {
				setBootstrap(bootstrap);
			})
			.catch((error) => {
				console.error("Errore durante l'importazione di bootstrap", error);
			});
	}, []);

	useEffect(() => {
		if (Bootstrap && !sessionStorage.getItem(`popupShown-${id}`)) {
			const modal = new Bootstrap.Modal(document.getElementById(`popupModal-${id}`));
			modal.show();
			sessionStorage.setItem(`popupShown-${id}`, "true");
		}
	}, [Bootstrap, id]);

	return (
		<div
			id={`popupModal-${id}`}
			className={`modal ${styles.modalPopup}`}
			tabIndex="-1"
			{...(shouldIncludeDataGTrack && {
				"data-gtrack": true,
				"data-gtrack-id": data.id,
				"data-gtrack-type": "core_banner",
				"data-gtrack-action": gTrackAction,
				"data-gtrack-link": url,
			})}
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
						<button
							type="button"
							className={`btn-close ${styles.popupmodalbtn}`}
							data-bs-dismiss="modal"
							aria-label="Chiudi"
						></button>

					{title && (
						<div className="modal-header">
							<h5 className="modal-title">{title}</h5>
						</div>
					)}

					{(summary || content) && (
						<div className={`modal-body ${styles.content}`}>
							{summary && <p className={styles.summary}>{summary}</p>}
							{content && (
								<div className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
							)}
						</div>
					)}

					{image && (
						<img
							src={image?.url}
							alt="Banner"
							className={styles.advertisementBannerImage}
						/>
					)}

					{(url || textUrl) && (
						<div className="modal-footer">
							<a href={url} title={textUrl} className="btn btn-primary">
								{textUrl}
							</a>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Popup;
