import React from "react";
import { usePage } from "@inertiajs/react";
import NewsCard from "../NewsCard/Index";
import styles from "./NewsCategory.module.scss";

function NewsCategory({ wrapperCSSClasses, ...props }) {
	const { locale } = usePage().props;

	let data = props.data;
	let newsCategoryTitle = props?.data?.title || "";

	if (props.data.keyType == "newsGroups") {
		data = data.data[0];
		newsCategoryTitle = data.group.title[locale] || data.group.name;
	} else {
		if (data.tag) {
			newsCategoryTitle = props.data.tag.title[locale] || props.data.tag.name;
		}
	}

	const magazineNews = data?.news;

	const renderNewsCards = (news, index) => {
		let output = [
			<li className={`${styles.categoryCardWrapper}`} key={`card-${index}`}>
				<NewsCard horizontal={false} large={false} imageAspectRatio="3/2" data={news} showDate={true} />
			</li>,
		];
		if (index % 4 === 0) {
			output.push(<li className={`${styles.categoryCardColSeparator}`} key={`col-sep-${index}`}></li>);
		}
		if (index % 4 === 1) {
			output.push(
				<li className={`${styles.categoryCardColSeparator} ${styles.big}`} key={`big-col-sep-${index}`}></li>
			);
			output.push(
				<li
					className={`${styles.categoryCardRowSeparator} ${styles.small}`}
					key={`small-row-sep-${index}`}
				></li>
			);
		}
		if (index % 4 === 2) {
			output.push(
				<li
					className={`${styles.categoryCardColSeparator} ${styles.small}`}
					key={`small-col-sep-${index}`}
				></li>
			);
			output.push(
				<li className={`${styles.categoryCardRowSeparator} ${styles.big}`} key={`big-row-sep-${index}`}></li>
			);
		}
		if (index % 4 === 3) {
			output.push(<li className={`${styles.categoryCardColSeparator}`} key={`col-sep-${index}`}></li>);
			output.push(
				<li
					className={`${styles.categoryCardRowSeparator} ${styles.small}`}
					key={`small-row-sep-${index}`}
				></li>
			);
		}
		return output;
	};

	return (
		magazineNews.length > 0 && (
			<section className={`${styles.categoryWrapper} ${wrapperCSSClasses && wrapperCSSClasses}`}>
				{/* Titolo */}
				<h3 className="text-uppercase fw-bold mb-3 h5 font-sans">{newsCategoryTitle}</h3>

				{/* Elenco news */}
				<ul className={`${styles.categoryGrid}`}>{magazineNews.map(renderNewsCards)}</ul>
			</section>
		)
	);
}

export default NewsCategory;
