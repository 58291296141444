const DateUtils = {
    // Lunedì, 20 novembre 2023
    getCurrentDate: () => {
        const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        };
        const currentDate = new Date();
        const formatter = new Intl.DateTimeFormat("it-IT", options);
        const parts = formatter.formatToParts(currentDate);

        let formattedDate = "";
        for (let i = 0; i < parts.length; i++) {
            if (parts[i].type === "weekday") {
                formattedDate += parts[i].value + ", ";
            } else {
                formattedDate += parts[i].value;
            }
        }
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    },
    // [giovedì, ] 12 ottobre 2023
    convertDateFromString: (data, includeWeekday = true) => {
        const opzioniFormatto = {
            weekday: "long",
            day: "numeric",
            month: "long",
            year: "numeric",
        };
        const dataObj = new Date(data);
        const formatter = new Intl.DateTimeFormat("it-IT", opzioniFormatto);
        const parts = formatter.formatToParts(dataObj);
        let formattedDate = "";
        for (let i = 0; i < parts.length; i++) {
            if (parts[i].type === "weekday") {
                if (includeWeekday) formattedDate += parts[i].value + ", ";
            } else {
                formattedDate += parts[i].value;
            }
        }
        return formattedDate;
    },

    convertDateRangeFromString: (startDate, endDate) => {
        if (startDate === null || startDate === undefined || endDate === null || endDate === undefined) {
            if (startDate === null && endDate === null) {
                return null; // Se entrambi sono null, restituisce null
            }
            if (startDate === null || startDate === undefined) {
                startDate = endDate; // Se solo startDate è null, lo imposto uguale a endDate
            }
            if (endDate === null || endDate === undefined) {
                endDate = startDate; // Se solo endDate è null, lo imposto uguale a startDate
            }
        }
        const options = { day: "2-digit", month: "2-digit", year: "numeric" };
        const startDateObj = new Date(startDate.split("T")[0]);
        const endDateObj = new Date(endDate.split("T")[0]);

        // Controllo se le date hanno lo stesso giorno, mese e anno
        if (startDateObj.toDateString() === endDateObj.toDateString()) {
            const formatter = new Intl.DateTimeFormat("it-IT", options);
            return formatter.format(startDateObj);
        }

        // Se le date sono diverse, restituisco la stringa nel formato richiesto
        const startDateFormatted = startDateObj.toLocaleDateString("it-IT", options);
        const endDateFormatted = endDateObj.toLocaleDateString("it-IT", options);
        return `dal ${startDateFormatted} al ${endDateFormatted}`;
    },
    // giovedì 12 ottobre
    formatDataDayOfTheWeek: (data) => {
        const optionsFormat = {
            weekday: "long",
            day: "2-digit",
            month: "long",
        };
        const dataObj = new Date(data);
        const formatter = new Intl.DateTimeFormat("it-IT", optionsFormat);
        const parts = formatter.formatToParts(dataObj);
        let formattedDate = "";
        for (let i = 0; i < parts.length; i++) {
            if (parts[i].type === "weekday") {
                formattedDate += parts[i].value + " ";
            } else if (parts[i].type === "day") {
                formattedDate += parts[i].value.padStart(2, "0") + " ";
            } else if (parts[i].type === "month") {
                formattedDate += parts[i].value;
            }
        }
        return formattedDate;
    },
    //11.11.2023
    formatDateToDDMMYYYY: (inputDate) => {
        const date = new Date(inputDate);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    },
    // giovedì 12 ottobre alle ore 14:01
    formatCommentDate: (dateString) => {
        {
            const date = new Date(dateString);
            const now = new Date();
            const diffMinutes = (now - date) / (1000 * 60);

            if (diffMinutes < 10) {
                return "Adesso";
            }

            if (diffMinutes < 24 * 60 && now.getDate() === date.getDate()) {
                return `Oggi alle ${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")}`;
            }

            if (diffMinutes < 48 * 60 && now.getDate() - date.getDate() === 1) {
                return `Ieri alle ${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")}`;
            }

            const options = {
                weekday: "long",
                day: "numeric",
                month: "long",
                hour: "numeric",
                minute: "numeric",
            };

            return date.toLocaleDateString("it-IT", options);
        }
    },
    formatTimeToHHMM: (inputDateTime, includeSeconds = false) => {
        const date = new Date(inputDateTime);
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        let formattedTime = `${hours}:${minutes}`;

        if (includeSeconds) {
            const seconds = String(date.getSeconds()).padStart(2, "0");
            formattedTime += `:${seconds}`;
        }

        return formattedTime;
    },
};

export default DateUtils;
