import React from "react";
import SVG from "../../../Utils/SVG";
import styles from "./AttachmentLinkList.module.scss";
import FileUtils from "../../../Utils/FileUtils";
import { usePage } from "@inertiajs/react";

const AttachmentLinkList = ({ attachments, wrapperCSSClasses }) => {
    const { locale } = usePage().props;

    return (
        <>
            {attachments.some((attachment) => attachment.filename) && (
                <div className={wrapperCSSClasses ? wrapperCSSClasses : ""}>
                    <h3 className={`h6 font-sans ${styles.title}`}>
                        <div
                            className={`me-2`}
                            dangerouslySetInnerHTML={{
                                __html: SVG.attachment,
                            }}
                        />
                        Elenco allegati:
                    </h3>

                    <ul className={`mt-2 font-sans ${styles.listWrapper}`}>
                        {attachments.map((attachment, index) => {
                            // console.log('Allegato', attachment)
                            let attachmentTitle = attachment.name;
                            if (attachment.title[locale]) {
                                attachmentTitle = attachment.title[locale];
                            }
                            return (
                                <li key={index}>
                                    <a
                                        href={`/storage/${attachment.filename}`}
                                        download={`/storage/${attachment.filename}`}
                                    >
                                        {FileUtils.isPDF(attachment.filename) && (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: SVG.attachmentPDF,
                                                }}
                                            />
                                        )}
                                        {FileUtils.isAudio(attachment.filename) && (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: SVG.attachmentAudio,
                                                }}
                                            />
                                        )}
                                        {FileUtils.isVideo(attachment.filename) && (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: SVG.attachmentVideo,
                                                }}
                                            />
                                        )}
                                        {attachmentTitle}
                                        <div
                                            className={`${styles.downloadIcon}`}
                                            dangerouslySetInnerHTML={{
                                                __html: SVG.download,
                                            }}
                                        />
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </>
    );
};

export default AttachmentLinkList;
