import React, { useEffect } from "react";
import styles from "./EventDetail.module.scss";
import { Link } from "@inertiajs/react";
import { usePage } from "@inertiajs/react";
import SmartImage from "../../Images/SmartImage/Index";
import DateUtils from "../../../Utils/DateUtils";
import ShareUtils from "../../../Utils/ShareUtils";
import ShareList from "../../Share/ShareList/Index";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipeDynamicCaption from "photoswipe-dynamic-caption-plugin";
import "photoswipe/dist/photoswipe.css";
import "photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css";
import ImageUtils from "../../../Utils/ImageUtils";
import AttachmentLinkList from "../../Attachments/AttachmentLinkList/Index";

function EventDetail({ ...props }) {
	const { locale } = usePage().props;

	const eventItem = props.eventItem;

	const eventPretitle = DateUtils.convertDateRangeFromString(eventItem.date_from, eventItem.date_to);
	const eventTitle = eventItem.title[locale] || eventItem.name;
	const eventPlace =
		eventItem && Array.isArray(eventItem.event_category) && eventItem.event_category.length > 0
			? eventItem?.event_category[0]?.title[locale] || eventItem?.event_category[0]?.name
			: null;
	const eventImages = eventItem?.cover_image;
	const eventSummary = eventItem.summary[locale];
	const eventContent = eventItem.content[locale];

	const eventMedias = eventItem.event_medias;
	const hasEventMedias = eventMedias.length > 0;
	const hasDownloadableEventMedias = eventMedias.some((attachment) => attachment.filename);

	useEffect(() => {
		async function prepareLightbox() {
			let imagesList = Array.from(document.querySelectorAll("ul.imagesGrid a"));
			await ImageUtils.prepareImagesForPhotoswipe(imagesList);
		}
		prepareLightbox();
		let lightbox = new PhotoSwipeLightbox({
			gallery: "ul.imagesGrid",
			children: "a",
			pswpModule: () => import("photoswipe"),
		});
		const captionPlugin = new PhotoSwipeDynamicCaption(lightbox, { type: "below" });
		lightbox.init();
	}, []);

	return (
		<>
			<section className={`${styles.wrapper}`}>
				{/** Share buttons */}
				<ShareList links={ShareUtils.shareLinks} />

				{/** Event body */}
				<article className={styles.article}>
					<header>
						{/* Pre-title */}
						<span className={`d-block text-uppercase font-sans ${styles.pretitle}`}>
							{eventPretitle} {eventPlace && ` - ${eventPlace}`}
						</span>

						{/* Titolo */}
						<h1 className={styles.title}>{eventTitle}</h1>

						{/* Sommario */}
						{eventSummary && <p className={`h5 fst-italic mb-3 ${styles.summary}`}>{eventSummary}</p>}

						{/* Cover Image */}
						{eventImages?.length > 0 && (
							<ul className={`${styles.imagesGrid} imagesGrid`}>
								{eventImages.map((eventImage, index) => {
									const imageTitle = eventImage.data?.title?.[0] ?? "";
									const imageAlt = eventImage.data?.alt?.[0] ?? "";
									const imageCopyright = eventImage.data?.copyright?.[0] ?? "";

									return (
										<li key={`eventiImage-${index}`}>
											<Link
												style={{ "--aspect-ratio": "1/1.4142" }}
												data-pswp-width={eventImage.width}
												data-pswp-height={eventImage.height}
												data-pswp-src={eventImage.url}
												className="d-block"
												data-cropped="true"
												target="_blank"
												rel="noreferrer"
											>
												<SmartImage
													url={eventImage.url}
													conversions={eventImage.conversions}
													data={eventImage.data}
												/>
												<span className="pswp-caption-content">
													{imageTitle.trim() !== "" && <strong>{imageTitle}</strong>}
													{imageCopyright.trim() !== "" && (
														<small className="d-block mt-1">{imageCopyright}</small>
													)}
												</span>
											</Link>
											<small className="d-block mt-1 fst-italic">{imageCopyright}</small>
										</li>
									);
								})}
							</ul>
						)}
					</header>

					{eventContent && (
						<div className={`mt-3 ${styles.articleContents}`}>
							<div dangerouslySetInnerHTML={{ __html: eventContent }} />
						</div>
					)}

					{/* Elenco allegati vari */}
					{hasEventMedias && hasDownloadableEventMedias && (
						<AttachmentLinkList attachments={eventMedias} wrapperCSSClasses={"mt-3"} />
					)}
				</article>
			</section>
			<hr className="separatore"></hr>
		</>
	);
}

export default EventDetail;
