import React, { useEffect, useState, useRef } from "react";
import styles from "./PayPalButton.module.scss";
import PrivateAreaUtils from "../../../Utils/PrivateAreaUtils";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { usePage } from "@inertiajs/react";

const PayPalButton = ({
    subscriptionData,
    onPayPalSubscriptionSuccess,
    onPayPalSubscriptionError,
    selectedAddressId,
    isInvoiceNeeded,
}) => {
    const { config, payments } = usePage().props;
    const isProduction = config.env !== "development";

    const buttonRef = useRef(null); // Ref per il bottone di PayPal
    const [showButton, setShowButton] = useState(false); // Se mostrare o meno il bottone

    const [codTrans, setCodTrans] = useState(null);

    const CLIENT_ID = payments.paypal.client_id;

    let subscriptionExtraData = {
        plan_id: subscriptionData.id,
        private_address_id: selectedAddressId,
        is_invoice_required: isInvoiceNeeded,
    };
    const subscriptionExtraDataRef = useRef(subscriptionExtraData);

    // Carica lo script dei PayPal Buttons e lo rimuove quando il componente viene smontato
    useEffect(() => {
        const script = document.createElement("script");
        script.src = `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&vault=true&disable-funding=card&intent=subscription`;
        script.async = true;
        script.onload = () => configurePayPal();
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        subscriptionExtraDataRef.current = {
            ...subscriptionExtraData,
            is_invoice_required: isInvoiceNeeded,
        };
    }, [isInvoiceNeeded, selectedAddressId, subscriptionData.id]);

    useEffect(() => {
        // console.log("Aggiorno il codTrans", codTrans);
        subscriptionExtraDataRef.current = {
            ...subscriptionExtraDataRef.current,
            codTrans: codTrans,
        };
    }, [codTrans]);

    const configurePayPal = async () => {
        // Configuro il bottone di PayPal
        paypal
            .Buttons({
                style: {
                    disableMaxWidth: true,
                    shape: "sharp",
                    label: "pay",
                    height: 45,
                },
                createSubscription: function (data, actions) {
                    return actions.subscription.create({
                        plan_id: subscriptionData.external_code, //"P-42748150H7948853SMYP2Q2Q", //"P-1XW24961MT281304AMYPE4ZA"
                    });
                },
                onClick: async () => {
                    // console.log("Click sul bottone di PayPal");
                    // Notificare il BE
                    const response = await PrivateAreaUtils.subscription.log(
                        subscriptionExtraDataRef.current,
                        "paypal_subscription"
                    );
                    // console.log("subscription.log response", response);
                    if (response.result && response.data) {
                        setCodTrans(response.data);
                    } else {
                        onPayPalSubscriptionError(
                            "Si è verificato un errore imprevisto. Per favore, riprova più tardi."
                        );
                    }
                },
                onApprove: async (data, actions) => {
                    /*console.log(
                        "L'abbonamento è stato approvato da PayPal, provo ad ottenere i dettagli della subscription da PayPal e chiamo la payPalApprove/create per notificare il BE"
                    );*/
                    actions.subscription.get().then(function (details) {
                        // console.log("Subscription details: ", details);
                        subscriptionExtraDataRef.current = {
                            ...subscriptionExtraDataRef.current,
                            payPalData: data,
                            payPalSubscriptionDetails: details,
                        };
                        // console.log("Dati che passo alla paypalApprove:", subscriptionExtraDataRef.current);
                        // Ritorniamo la Promise esplicitamente
                        return new Promise(async (resolve, reject) => {
                            try {
                                const response = await PrivateAreaUtils.subscription.payPalApprove(
                                    subscriptionExtraDataRef.current
                                );
                                // console.log("PrivateAreaUtils.subscription.payPalApprove response", response);
                                onPayPalSubscriptionSuccess();
                                resolve();
                            } catch (error) {
                                console.error("Error in payPalApprove:", error);
                                reject(error);
                                onPayPalSubscriptionError(
                                    "Si è verificato un errore imprevisto. Per favore, riprova più tardi."
                                );
                            }
                        });
                    });
                },

                onError: (err) => {
                    // console.log("onError", err);
                    onPayPalSubscriptionError("Si è verificato un errore imprevisto. Per favore, riprova più tardi.");
                },
            })
            .render("#paypal-button-container");

        // Attivo il bottone "Paga ora"
        setShowButton(true);
    };

    return (
        <div className="mt-4 d-flex">
            {!showButton && <Skeleton count={1} containerClassName={`${styles.skeletonBtn}`} inline={true} />}
            <div className="w-100" id="paypal-button-container"></div>
        </div>
    );
};

export default PayPalButton;
