import React from "react";

const ComponentsUtils = {
    getComponents: (components, coreBannersAllPage, zones) => {
        let overwrittenComponents = {};
        let nonOverwrittenComponents = {};
        let finalComponents = {};
        const possibleKeys = [
            "banners",
            "news",
            "newsTags",
            "newsGroups",
            "eventsCalendar",
            "eventsItems",
            "galleryItems", // Aggiungere qui altre chiavi di proprietà di eventuali altri dati/componenti
        ];

        // Valorizzo due array di supporto: componenti non sovrascritti e componenti che invece vanno a sovrascrivere
        for (let zone in components) {
            if (components.hasOwnProperty(zone)) {
                for (let component of components[zone]) {
                    if (component?.component?.overwrite_banner_type) {
                        if (!overwrittenComponents[zone]) {
                            overwrittenComponents[zone] = [];
                        }
                        overwrittenComponents[zone].push(component);
                    } else {
                        if (!nonOverwrittenComponents[zone]) {
                            nonOverwrittenComponents[zone] = [];
                        }
                        nonOverwrittenComponents[zone].push(component);
                    }
                }
            }
        }
        //console.log('overwrittenComponents', overwrittenComponents)
        //console.log('nonOverwrittenComponents', nonOverwrittenComponents)

        for (let zone of zones) {
            finalComponents[zone] = [];

            if (overwrittenComponents.hasOwnProperty(zone)) {
                for (let component of overwrittenComponents[zone]) {
                    for (let key of possibleKeys) {
                        if (
                            component.hasOwnProperty(key) &&
                            (Array.isArray(component[key]) || typeof component[key] === "object")
                        ) {
                            finalComponents[zone].push({
                                data: component[key],
                                keyType: key,
                                component: component.component,
                            });
                            break;
                        }
                    }
                }
            }

            if (nonOverwrittenComponents.hasOwnProperty(zone)) {
                for (let component of nonOverwrittenComponents[zone]) {
                    for (let key of possibleKeys) {
                        if (
                            component.hasOwnProperty(key) &&
                            (Array.isArray(component[key]) || typeof component[key] === "object")
                        ) {
                            finalComponents[zone].push({
                                data: component[key],
                                keyType: key,
                                component: component.component,
                            });
                            break;
                        }
                    }
                }
            }

            if (
                overwrittenComponents &&
                coreBannersAllPage &&
                !overwrittenComponents.hasOwnProperty(zone) &&
                coreBannersAllPage.hasOwnProperty(zone) &&
                Array.isArray(coreBannersAllPage[zone])
            ) {
                finalComponents[zone].push({
                    data: coreBannersAllPage[zone].filter((banner) => banner.components.length === 0),
                    keyType: "banners",
                });
                /*
                    Questo aggiungerebbe tutti i banner AllPage senza controllare eventuali sovrascritture
                    alla fine dell'array dei banner che verrebbero mostrati in pagina
                    coreBannersAllPage[zone].forEach(banner => {
                        if (banner.components.length === 0) {
                            finalComponents[zone].push({ data: banner, keyType: "banners" });
                        }
                    });
                */
            }
        }

        return finalComponents;
    },
    renderComponent: (
        componentObj,
        componentMap,
        wrapperCSSClasses,
        keyPrefix,
        componentsToPrepend = null,
        componentsToAppend = null,
        userLogged = null
    ) => {
        const userHasSubscription = Boolean(userLogged?.private_package_active);
        if (!componentObj.data || componentObj.data.length === 0) {
            if (componentObj.keyType === "banners") {
                let ComponentName = componentObj?.component?.template;
                if (ComponentName === "ValleSabbiaNewsLavoro" || ComponentName === "ValleSabbiaNewsCasa") {
                    const Component = componentMap[ComponentName];
                    if (!Component) {
                        console.warn(
                            `RENDERCOMPONENT: Si è verificato un problema. Riprova più tardi. ERRORCODE: NO_COMPONENT_FOUND. ComponentName: ${ComponentName}`
                        );
                        return;
                    }
                    return (
                        <React.Fragment key={`${keyPrefix}`}>
                            {componentsToPrepend}
                            <Component data={componentObj} wrapperCSSClasses={wrapperCSSClasses} />
                            {componentsToAppend}
                        </React.Fragment>
                    );
                }
            }
            console.warn(
                `RENDERCOMPONENT: Si è verificato un problema. Riprova più tardi. ERRORCODE: NO_DATA_SET. Component details: ${JSON.stringify(
                    componentObj,
                    null,
                    2
                )}`
            );
            return;
        }

        if (
            componentObj.keyType === "newsGroups" ||
            componentObj.keyType === "news" ||
            componentObj.keyType === "newsTags" ||
            componentObj.keyType === "galleryItems"
        ) {
            let ComponentName = componentObj?.component?.template;
            const Component = componentMap[ComponentName];
            if (!Component) {
                console.warn(
                    `RENDERCOMPONENT: Si è verificato un problema. Riprova più tardi. ERRORCODE: NO_COMPONENT_FOUND. ComponentName: ${ComponentName}. ComponentKeyType: ${componentObj.keyType}`
                );
                return;
            }
            return (
                <React.Fragment key={`${keyPrefix}`}>
                    {componentsToPrepend}
                    <Component data={componentObj} wrapperCSSClasses={wrapperCSSClasses} />
                    {componentsToAppend}
                </React.Fragment>
            );
        }

        if (componentObj.keyType == "eventsCalendar" || componentObj.keyType == "eventsItems") {
            let ComponentName = componentObj?.component?.template;
            const Component = componentMap[ComponentName];
            if (!Component) {
                console.warn(
                    `RENDERCOMPONENT: Si è verificato un problema. Riprova più tardi. ERRORCODE: NO_COMPONENT_FOUND. ComponentName: ${ComponentName}`
                );
                return;
            }
            return (
                <React.Fragment key={`${keyPrefix}`}>
                    {componentsToPrepend}
                    <Component data={componentObj} wrapperCSSClasses={wrapperCSSClasses} />
                    {componentsToAppend}
                </React.Fragment>
            );
        }

        if (componentObj.keyType === "banners") {
            let isCarousel = componentObj.component?.is_carousel;
            let visibility = componentObj.component?.visibility;
            let isVisibleOnMobile = false;
            let isVisibleOnDesktop = false;
            if (visibility === "desk") {
                isVisibleOnDesktop = true;
                isVisibleOnMobile = false;
            } else if (visibility === "mobi") {
                isVisibleOnDesktop = false;
                isVisibleOnMobile = true;
            }

            if (isCarousel) {
                return (
                    <div
                        className={`${isVisibleOnMobile ? "d-lg-none" : ""} ${
                            isVisibleOnDesktop ? "d-mobile-none" : ""
                        }`}
                    >
                        {componentsToPrepend}
                        <div className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-pause="false">
                            <div className="carousel-inner">
                                {componentObj.data.map((component, index) => {
                                    const ComponentName = component.core_banner_type.template;

                                    // Non renderizza il banner AdSense se l'utente ha un abbonamento
                                    if (ComponentName === "AdSense" && userHasSubscription) return;

                                    // Se l'utente non ha un abbonamento, proseguo
                                    const Component = componentMap[ComponentName];
                                    if (!Component) {
                                        console.warn(
                                            `RENDERCOMPONENT: Si è verificato un problema. Riprova più tardi. ERRORCODE: NO_COMPONENT_FOUND. ComponentName: ${ComponentName}`
                                        );
                                        return;
                                    }
                                    let carouselClass = "carousel-item";
                                    if (index === 0) carouselClass += " active"; // Il primo elemento deve essere attivo
                                    return (
                                        <div className={carouselClass} key={`${keyPrefix}-${index}`}>
                                            <Component data={component} wrapperCSSClasses={wrapperCSSClasses} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {componentsToAppend}
                    </div>
                );
            }

            return componentObj.data.map((component, index) => {
                const ComponentName = component.core_banner_type.template;

                // Non renderizza il banner AdSense se l'utente ha un abbonamento
                if (ComponentName === "AdSense" && userHasSubscription) return;

                // Se l'utente non ha un abbonamento, proseguo
                const Component = componentMap[ComponentName];

                if (!Component) {
                    console.warn(
                        `RENDERCOMPONENT: Si è verificato un problema. Riprova più tardi. ERRORCODE: NO_COMPONENT_FOUND. ComponentName: ${ComponentName}`
                    );
                    return;
                }

                return (
                    <React.Fragment key={`${keyPrefix}-${index}`}>
                        {componentsToPrepend}
                        <Component
                            data={component}
                            wrapperCSSClasses={`${wrapperCSSClasses} ${isVisibleOnMobile ? "d-lg-none" : ""} ${
                                isVisibleOnDesktop ? "d-mobile-none" : ""
                            }`}
                        />
                        {componentsToAppend}
                    </React.Fragment>
                );
            });
        }
    },
    renderPopupComponents: (components, componentMap) => {
        return components?.map((componentObj, index) =>
            ComponentsUtils.renderComponent(componentObj, componentMap, "", `popupComponent-${index}`)
        );
    },
};

export default ComponentsUtils;
