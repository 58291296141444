import React, { useEffect } from "react";
import HeadSEO from "../../Components/Head/Index";
//import styles from "./Gallery.module.scss";
import Layout from "../../Layout/Main";
import SmartImage from "../../Components/Images/SmartImage/Index";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipeDynamicCaption from "photoswipe-dynamic-caption-plugin";
import "photoswipe/dist/photoswipe.css";
import "photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css";
import { Link } from "@inertiajs/react";
import { componentFactory } from "../../Utils/ComponentsFactory";
import SVG from "../../Utils/SVG";
import ComponentsUtils from "../../Utils/ComponentsUtils";
import ImageUtils from "../../Utils/ImageUtils";
import { useRefreshProps } from "../../Hooks";
import SEOUtils from "../../Utils/SEOUtils";

function GalleryCategory(props) {
	const {
		page,
		locale,
		components,
		coreBannersAllPage,
		galleryCategory,
		galleryCategories,
		userLogged,
		ziggy,
	} = props;

	const componentMap = componentFactory();
	const zones = ["main", "asideTop", "asideMiddle", "asideBottom"];
	const FINAL_COMPONENTS = ComponentsUtils.getComponents(components, coreBannersAllPage, zones);

	{
		/** Aggiorna le props principali una volta ogni 5 minuti */
	}
	useEffect(() => {
		useRefreshProps(["components", "coreBannersAllPage", "galleryCategory", "galleryCategories"], 300000);
	}, []);

	let dailyPicture = false;
	dailyPicture = FINAL_COMPONENTS?.main?.find(
		(item) => item.keyType === "galleryItems" && item.component.item_order === "ordPhotoOfDay"
	);

	{
		/** Titolo di pagina */
	}
	const pageTitle =
		!galleryCategory && galleryCategories
			? page.title[locale]
				? page.title[locale]
				: page.name
			: galleryCategory.title[locale]
			? galleryCategory.title[locale]
			: galleryCategory.name;

	{
		/** Lista sottocategorie e items del dettaglio categoria */
	}
	function generateCategoryItems(category, index) {
		const categoryTitle = category.title[locale] ? category.title[locale] : category.name;
		const categoryImage = category.cover_image && category.cover_image[0];
		const categoryUrl = category.url?.[locale].url;

		return (
			<li className="galleryCard" key={`galleryCard-${index}`}>
				<Link href={categoryUrl} title={categoryTitle} style={{ "--aspect-ratio": "3/2" }}>
					<SmartImage
						url={categoryImage ? categoryImage.url : "https://placehold.co/600x400?text=Nessuna+foto"}
						conversions={categoryImage ? categoryImage.conversions : null}
						data={categoryImage ? categoryImage.data : { alt: [categoryTitle], title: [categoryTitle] }}
					/>
				</Link>
				<h2 className="h6 mt-2">{categoryTitle}</h2>
			</li>
		);
	}
	function generateGalleryItems(item, index) {
		const itemTitle = item.title[locale] || item.name;
		const itemSummary = item.summary[locale];
		const itemContent = item.content[locale];
		const itemImage = item.cover_image && item.cover_image[0];

		return (
			<li className="galleryCard galleryItemCard" key={`itemCard-${index}`}>
				<a
					style={{ "--aspect-ratio": "3/2" }}
					className={`d-block`}
					itemProp="contentUrl"
					href={itemImage.url}
					data-pswp-width={itemImage.width}
					data-pswp-height={itemImage.height}
					data-cropped="true"
					target="_blank"
					rel="noreferrer"
					title={itemTitle}
				>
					<SmartImage
						url={itemImage ? itemImage.url : "https://placehold.co/600x400?text=Nessuna+foto"}
						conversions={itemImage ? itemImage.conversions : null}
						data={itemImage ? itemImage.data : { alt: [itemTitle], title: [itemTitle] }}
					/>
					<span className="pswp-caption-content">
						{itemTitle && <strong>{itemTitle}</strong>}
						{itemSummary && <span className="d-block mt-1">{itemSummary}</span>}
						{itemContent && (
							<span className="d-block mt-2" dangerouslySetInnerHTML={{ __html: itemContent }}></span>
						)}
					</span>
				</a>
				<h2 className="h6 mt-2">{itemTitle}</h2>
				{itemSummary && <small className="mt-1 font-sans">{itemSummary}</small>}
			</li>
		);
	}
	let categoryList = null,
		itemsList = null;
	if (galleryCategory) {
		categoryList = galleryCategory.children.map(generateCategoryItems);
		itemsList = galleryCategory.gallery_item.map(generateGalleryItems);
	}

	{
		/**
		 * Bottone indietro
		 * O torna al padre o alla pagina principale della gallery
		 */
	}
	const BackButton = () => {
		const backButtonText = galleryCategory.parent_id ? "Torna indietro" : "Torna alla galleria";
		const backButtonLink = galleryCategory.parent_id
			? galleryCategory.parentUrl?.[locale].url
			: page.url?.[locale].url;

		return (
			<Link
				href={backButtonLink}
				title={backButtonText}
				className="galleryBackButton text-uppercase font-sans mb-2"
			>
				<div dangerouslySetInnerHTML={{ __html: SVG.chevronLeft }} />
				{backButtonText}
			</Link>
		);
	};

	{
		/** Photoswipe */
	}
	useEffect(() => {
		async function prepareLightbox() {
			let imagesList = Array.from(document.querySelectorAll(".dailyPictureWrapper a"));
			if (galleryCategory) {
				const galleryImagesList = Array.from(document.querySelectorAll(".galleryItemCard a"));
				imagesList = [...imagesList, ...galleryImagesList];
			}
			await ImageUtils.prepareImagesForPhotoswipe(imagesList);
		}
		prepareLightbox();
		const lightbox = new PhotoSwipeLightbox({
			gallery: galleryCategory ? ".galleryGrid" : ".dailyPictureWrapper",
			children: galleryCategory ? ".galleryItemCard a" : "a",
			pswpModule: () => import("photoswipe"),
		});
		const captionPlugin = new PhotoSwipeDynamicCaption(lightbox, {
			type: "below",
		});
		lightbox.init();
	}, []);

	const main = (
		<>
			{/** Bottone indietro */}
			{galleryCategory && <BackButton />}

			<h1 className="h2">{pageTitle}</h1>

			{/**
			 * Foto del giorno - Ultima foto caricata
			 * La foto del giorno viene mostrata solo nella pagina di elenco di tutte le macrocategorie,
			 * per questo motivo viene effettuato il controllo se è valorizzata la prop "galleryCategories"
			 * e non è valorizzata la galleryCategory
			 */}
			{!galleryCategory &&
				galleryCategories &&
				dailyPicture &&
				dailyPicture.data[0].cover_image.map((image, index) => {
					let imageData = dailyPicture.data[0];
					let imageTitle = imageData.title && imageData.title[locale] ? imageData.title[locale] : "";
					let imageSummary = imageData.summary && imageData.summary[locale] ? imageData.summary[locale] : "";
					let imageContent = imageData.content && imageData.content[locale] ? imageData.content[locale] : "";
					return (
						<div
							className="dailyPictureWrapper mt-4"
							key={`dailyPicture-${index}`}
							itemProp="associatedMedia"
							itemScope
							itemType="http://schema.org/ImageObject"
						>
							<a
								style={{ "--aspect-ratio": "3/2" }}
								className={`d-block`}
								itemProp="contentUrl"
								href={image.url}
								data-pswp-width={image.width}
								data-pswp-height={image.height}
								data-cropped="true"
								target="_blank"
								rel="noreferrer"
							>
								<SmartImage url={image.url} conversions={image.conversions} data={image.data} />
								<span className="pswp-caption-content">
									{imageTitle && <strong>{imageTitle}</strong>}
									{imageSummary && <span className="d-block mt-1">{imageSummary}</span>}
									{imageContent && (
										<span
											className="d-block mt-2"
											dangerouslySetInnerHTML={{ __html: imageContent }}
										/>
									)}
								</span>
							</a>
							<span className="d-block w-100 text-center mt-2 galleryCaption">
								{imageTitle && <strong className="text-primary">{imageTitle}</strong>}
								{imageSummary && <span className="d-block mt-1 font-sans">{imageSummary}</span>}
								{imageContent && (
									<span className="d-block mt-2" dangerouslySetInnerHTML={{ __html: imageContent }} />
								)}
							</span>
						</div>
					);
				})}

			{/** Elenco macrocategorie Gallery */}
			{!galleryCategory && galleryCategories && (
				<ul className="galleryGrid mt-4">
					{galleryCategories.map((category, index) => {
						let categoryTitle = category.title[locale] || category.name;
						let categoryImage = category.cover_image && category.cover_image[0];
						let categoryUrl = category.url?.[locale].url;
						return (
							<li className="galleryCard" key={`GalleryCategoryCard-${index}`}>
								<Link href={categoryUrl} title={categoryTitle} style={{ "--aspect-ratio": "3/2" }}>
									<SmartImage
										url={
											categoryImage
												? categoryImage.url
												: "https://placehold.co/600x400?text=Nessuna+foto"
										}
										conversions={categoryImage ? categoryImage.conversions : null}
										data={
											categoryImage
												? categoryImage.data
												: { alt: [categoryTitle], title: [categoryTitle] }
										}
									/>
								</Link>
								<h2 className="h6 mt-2">{categoryTitle}</h2>
							</li>
						);
					})}
				</ul>
			)}

			{/** Elenco sottocategorie e dettagli */}
			{galleryCategory &&
				(() => {
					const categorySummary = galleryCategory.summary[locale];
					const categoryContent = galleryCategory.content[locale];
					return (
						<>
							{categorySummary && <p className="h5 fst-italic mt-1 fw-normal">{categorySummary}</p>}
							{categoryContent && (
								<div
									className="mt-2"
									dangerouslySetInnerHTML={{
										__html: categoryContent,
									}}
								/>
							)}
							<ul className="galleryGrid mt-4">
								{categoryList}
								{itemsList}
							</ul>
						</>
					);
				})()}

			{/** Bottone indietro */}
			<div className="mt-3">{galleryCategory && <BackButton />}</div>
		</>
	);

	const aside = (
		<>
			{FINAL_COMPONENTS.asideTop?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"asideTop",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}
			{FINAL_COMPONENTS.asideMiddle?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"asideMiddle",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}
			{FINAL_COMPONENTS.asideBottom?.map((componentObj, index) => {
				return ComponentsUtils.renderComponent(
					componentObj,
					componentMap,
					"",
					"asideBottom",
					undefined,
					<hr className="separatore"></hr>,
					userLogged
				);
			})}
		</>
	);

	const seoData = SEOUtils.generateSEOData({
		page: page,
		locale: locale,
		type: "galleryCategory",
		ziggy: ziggy,
		otherProps: {
			title: pageTitle,
			...(galleryCategory ? { galleryCategory: galleryCategory } : {}),
			...(galleryCategories ? { galleryCategories: galleryCategories } : {}),
		},
	});

	return (
		<>
			<HeadSEO data={seoData} />
			<Layout {...props} main={main} aside={aside} />
			{/** Componenti popup */}
			{ComponentsUtils.renderPopupComponents(FINAL_COMPONENTS.popup, componentMap)}
		</>
	);
}

export default GalleryCategory;
