import React from "react";
import styles from "./UserMenu.module.scss";

import SVG from "../../../Utils/SVG";
import PrivateAreaUtils from "../../../Utils/PrivateAreaUtils";

export default function UserMenu({ user }) {
    // Pusho il parametro activeTab nell'URL di pagina, così se uno refresha si ritrova nel tab in cui era
    const handleTabClick = (tabId) => {
        window.history.replaceState({}, "", `?activeTab=${tabId}`);
    };

    return (
        <ul id="menuWrapper" className={`mt-4 font-sans ${styles.menu}`} role="tablist" aria-orientation="vertical">
            <li className={`nav-item`} role="presentation">
                <button
                    id="personalDataTab-pill"
                    className="active"
                    data-bs-toggle="pill"
                    data-bs-target="#personalDataTab"
                    type="button"
                    role="tab"
                    aria-controls="personalDataTab"
                    aria-selected="true"
                    onClick={() => handleTabClick("personalDataTab")}
                >
                    <div dangerouslySetInnerHTML={{ __html: SVG.paUser }} />
                    Dati personali e account
                </button>
            </li>
            <li className="nav-item" role="presentation">
                <button
                    id="subscriptionTab-pill"
                    data-bs-toggle="pill"
                    data-bs-target="#subscriptionTab"
                    type="button"
                    role="tab"
                    aria-controls="subscriptionTab"
                    aria-selected="false"
                    onClick={() => handleTabClick("subscriptionTab")}
                >
                    <div dangerouslySetInnerHTML={{ __html: SVG.paSubscription }} />
                    Il mio abbonamento
                </button>
            </li>
            <li className="nav-item" role="presentation">
                <button
                    id="devicesTab-pill"
                    data-bs-toggle="pill"
                    data-bs-target="#devicesTab"
                    type="button"
                    role="tab"
                    aria-controls="devicesTab"
                    aria-selected="false"
                    onClick={() => handleTabClick("devicesTab")}
                >
                    <div dangerouslySetInnerHTML={{ __html: SVG.paDevices }} />I miei dispositivi
                </button>
            </li>
            <li className="nav-item" role="presentation">
                <button
                    id="paymentsTab-pill"
                    data-bs-toggle="pill"
                    data-bs-target="#paymentsTab"
                    type="button"
                    role="tab"
                    aria-controls="paymentsTab"
                    aria-selected="false"
                    onClick={() => handleTabClick("paymentsTab")}
                >
                    <div dangerouslySetInnerHTML={{ __html: SVG.paPayments }} />
                    Pagamenti
                </button>
            </li>
            <li className="nav-item" role="presentation">
                <button
                    id="userLogout"
                    type="button"
                    aria-selected="false"
                    onClick={() => PrivateAreaUtils.user.logout()}
                >
                    <div dangerouslySetInnerHTML={{ __html: SVG.paLogout }} />
                    Effettua il logout
                </button>
            </li>
        </ul>
    );
}
