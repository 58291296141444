import React from "react";
import styles from "./Footer.module.scss";
import { usePage } from "@inertiajs/react";
import { componentFactory } from "../../Utils/ComponentsFactory";
import ComponentsUtils from "../../Utils/ComponentsUtils";
import GTrackUtils from "../../Utils/GTrackUtils";

const Footer = ({ ...props }) => {
	const { locale, coreBannersAllPage, components, userLogged } = usePage().props;

	const { shouldIncludeDataGTrack, gTrackAction } = GTrackUtils.generateGTrackAction(
		props.data?.custom_fields?.gTrack_click,
		props.data?.custom_fields?.gTrack_view
	);

	const componentMap = componentFactory();
	const zones = ["prefooter", "footer"];
	const FINAL_COMPONENTS = ComponentsUtils.getComponents(components, coreBannersAllPage, zones);

	return (
		<footer
			className={`container pb-3 ${styles.footer}`}
			{...(shouldIncludeDataGTrack && {
				"data-gtrack": true,
				"data-gtrack-id": props.data.id,
				"data-gtrack-type": "core_banner",
				"data-gtrack-action": gTrackAction,
			})}
		>
			{/* Componenti prefooter */}
			{FINAL_COMPONENTS.prefooter?.map((componentObj, indexMain) => {
				{
					/* Banners */
				}
				if (componentObj.keyType === "banners") {
					return ComponentsUtils.renderComponent(
						componentObj,
						componentMap,
						"",
						"prefooter-banners",
						undefined,
						undefined,
						userLogged
					);
				}

				{
					/* News Groups */
				}
				if (componentObj.keyType === "newsGroups") {
					return ComponentsUtils.renderComponent(
						componentObj,
						componentMap,
						"",
						"prefooter-newsGroups",
						undefined,
						<hr className="separatore"></hr>,
						userLogged
					);
				}

				return null;
			})}

			{/* Footer sempre visibile */}
			<hr className={styles.separatore}></hr>
			{FINAL_COMPONENTS.footer?.map((componentObj, index) =>
				componentObj.data.map((banner, innerIndex) => (
					<section className="row" key={`Footer-Banners-${index}-${innerIndex}`}>
						<div className="col-12">
							{/* Logo */}
							<div
								className={styles.logoWrapper}
								dangerouslySetInnerHTML={{
									__html: `<img src='${banner?.media1?.[0] &&
										banner?.media1?.[0]?.url}' alt="ValleSabbiaNews logo" />`,
								}}
							/>
							{/* Content */}
							<div
								className={`mt-3`}
								dangerouslySetInnerHTML={{
									__html: banner?.content?.[locale],
								}}
							/>
						</div>
					</section>
				))
			)}
		</footer>
	);
};

export default Footer;
