const FileUtils = {
    isPDF: filename => {
        return filename.toLowerCase().endsWith('.pdf');
    },
    isAudio: filename => {
        const audioExtensions = ['.mp3', '.wav', '.ogg', '.aac', '.flac'];
        const extension = filename.toLowerCase().substring(filename.lastIndexOf('.'));
        return audioExtensions.includes(extension);
    },
    getAudioType: filename => {
        const extension = filename.toLowerCase().substring(filename.lastIndexOf('.'));
        switch (extension) {
            case '.mp3':
                return 'audio/mpeg';
            case '.wav':
                return 'audio/wav';
            case '.ogg':
                return 'audio/ogg';
            case '.aac':
                return 'audio/aac';
            case '.flac':
                return 'audio/flac';
            default:
                return '';
        }
    },
    isVideo: filename => {
        const videoExtensions = ['.mp4', '.webm', '.ogg'];
        const extension = filename.toLowerCase().substring(filename.lastIndexOf('.'));
        return videoExtensions.includes(extension);
    },

    getVideoType: filename => {
        const extension = filename.toLowerCase().substring(filename.lastIndexOf('.'));
        switch (extension) {
            case '.mp4':
                return 'video/mp4';
            case '.webm':
                return 'video/webm';
            case '.ogg':
                return 'video/ogg';
            default:
                return '';
        }
    }
}

export default FileUtils;