import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Private";
import HeadSEO from "../../Components/Head/Index";
import ComponentsUtils from "../../Utils/ComponentsUtils";
import { componentFactory } from "../../Utils/ComponentsFactory";
import { useRefreshProps } from "../../Hooks";
import SEOUtils from "../../Utils/SEOUtils";

import UserProfile from "../../Components/PrivateArea/UserProfile/Index";
import UserMenu from "../../Components/PrivateArea/UserMenu/Index";
import UserTabContent from "../../Components/PrivateArea/UserTabContent/Index";
import UserNotLogged from "../../Components/PrivateArea/UserNotLogged/Index";
import SubscriptionCheckout from "../../Components/Subscription/SubscriptionCheckout/Index";

function PrivateArea(props) {
	const { page, locale, components, coreBannersAllPage, userLogged, ziggy } = props;

	const componentMap = componentFactory();
	const zones = ["popup"];
	const FINAL_COMPONENTS = ComponentsUtils.getComponents(components, coreBannersAllPage, zones);

	const isUserLogged = Boolean(userLogged);
	const userHasSubscription = Boolean(userLogged?.private_package_active);

	const [Bootstrap, setBootstrap] = useState(null);

	const [subscriptionId, setSubscriptionId] = useState(null);

	useEffect(() => {
		useRefreshProps(["components", "coreBannersAllPage"], 300000);
	}, []);

	useEffect(() => {
		// Importa bootstrap asincronamente
		import("bootstrap")
			.then((bootstrap) => {
				setBootstrap(bootstrap);
			})
			.catch((error) => {
				console.error("Errore durante l'importazione di bootstrap", error);
			});
	}, []);

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const shouldOpenCheckoutModal = queryParams.get("openCheckoutModal") === "true";
		const subscriptionId = queryParams.get("subscriptionId");
		setSubscriptionId(subscriptionId);
		const activeTab = queryParams.get("activeTab");

		// Attivo il tab reso attivo dal parametro GET "activeTab"
		if (Bootstrap && activeTab) {
			activateTab(`#${activeTab}-pill`);
		}

		// Apro il modale di checkout
		if (Bootstrap && shouldOpenCheckoutModal && subscriptionId && !userHasSubscription) {
			openCheckoutModal();
		}
	}, [Bootstrap]);

	const activateTab = (tabId) => {
		const tab = document.querySelector(tabId);
		if (tab) {
			new Bootstrap.Tab(tab).show();
		}
	};

	const openCheckoutModal = () => {
		const modal = document.querySelector("#SubscriptionCheckoutModal");
		if (modal) {
			new Bootstrap.Modal(modal).show();
		}
	};

	const menu = isUserLogged ? (
		<>
			<UserProfile user={userLogged} />
			<UserMenu user={userLogged} />
		</>
	) : null;

	const main = userLogged ? (
		<>
			<UserTabContent user={userLogged} />
			<SubscriptionCheckout subscriptionId={subscriptionId} />
		</>
	) : (
		<UserNotLogged />
	);

	const seoData = SEOUtils.generateSEOData({
		page: page,
		locale: locale,
		type: "default",
		ziggy: ziggy,
		otherProps: {
			// Altre props
		},
	});

	return (
		<>
			<HeadSEO data={seoData} />

			<Layout {...props} isUserLogged={Boolean(userLogged)} menu={menu} main={main} />

			{ComponentsUtils.renderPopupComponents(FINAL_COMPONENTS.popup, componentMap)}
		</>
	);
}

export default PrivateArea;
