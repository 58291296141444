const CacheUtils = {
    fetchBanner: async (id, cacheDuration = 3600000) => {
        const cacheKey = `banner-${id}`;
        const cachedData = localStorage.getItem(cacheKey);
        const now = new Date().getTime();
    
        if (cachedData) {
            const { lastFetch, data } = JSON.parse(cachedData);
            if (now - lastFetch < cacheDuration && data) {
                return data; // Usa i dati della cache se sono ancora validi
            }
        }
    
        try {
            const response = await axios.get(`${window.location.origin}/api/banners/${id}`);
            const data = response.data;
    
            // Salva i dati nel localStorage con timestamp
            localStorage.setItem(cacheKey, JSON.stringify({ data, lastFetch: now }));
            return data;
        } catch (error) {
            console.error("Errore nella richiesta del banner:", error);
            throw error; // Rilancia l'errore per la gestione nel componente
        }
    }
}
export default CacheUtils;