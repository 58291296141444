const EventsUtils = {
	transformEvents: (events, locale) => {
		const parsedEvents = [];
		if (events) {
			for (const date in events) {
				events[date].forEach((event) => {
					parsedEvents.push({
						title: event.title?.[locale] || event?.name,
						start: event.date_from + "T" + event.time_from,
						end: event.date_to + "T" + event.time_to,
						allDay: false,
					});
				});
			}
		}
		return parsedEvents;
	},
	getEventDates: (events) => {
		const datesArray = [];
		const datesMap = {}; // Utilizzato per tracciare le date già inserite

		for (const event of events) {
			const start = event.start.split("T")[0]; // Estre la data senza considerare l'orario
			const end = event.end.split("T")[0];

			let startDate;
			if (start) {
				startDate = new Date(start);
			} else {
				continue; // Se la data di inizio non è valida, salta l'evento
			}

			let endDate;
			if (end && end !== "null") {
				endDate = new Date(end);
			} else {
				endDate = new Date(startDate); // Se la data di fine non è valida, usa la data di inizio come data di fine
			}

			for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
				const dateString = d.toISOString().split("T")[0];

				if (!datesMap[dateString]) {
					datesMap[dateString] = true;
					datesArray.push({ start: dateString, end: dateString });
				}
			}
		}

		return datesArray;
	},
};

export default EventsUtils;
