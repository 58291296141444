import React from "react";
import HeadSEO from "../../Components/Head/Index";
//import styles from "./Legal.module.scss";
import Layout from "../../Layout/Generico";
import SEOUtils from "../../Utils/SEOUtils";

function Legal(props) {
	const { page, locale, ziggy } = props;

	const main = (
		<>
			<h1>{page.title?.[locale]}</h1>
			<div className="mt-3" dangerouslySetInnerHTML={{ __html: page.content?.[locale] }} />
		</>
	);

	const seoData = SEOUtils.generateSEOData({
		page: page,
		locale: locale,
		type: "legal",
		ziggy: ziggy,
		otherProps: {},
	});

	return (
		<>
			<HeadSEO data={seoData} />
			<Layout {...props} main={main} />
		</>
	);
}

export default Legal;
