import React from "react";

const SmartImage = ({ url, conversions, data }) => {

    let alt = null,
        title = null;

    if (typeof data === "object" && data !== null && !Array.isArray(data)) {
        if (data.alt[0] !== null) alt = data.alt[0];
        if (data.title[0] !== null) title = data.title[0];
    }

    const getConversionUrl = (conversionType) => {
        const extension = url.substr(url.lastIndexOf(".") + 1);
        const conversionUrl = conversions[`${conversionType}`];
        if (conversionUrl) {
            return `${url.substr(
                0,
                url.lastIndexOf("/") + 1
            )}conversions/${conversionUrl}`;
        }
        return url;
    };

    const hasWebPConversion = (conversions) => {
        if (typeof conversions !== 'object' || conversions === null) {
            return false;
        }
    
        const webpConversion = Object.entries(conversions).find(([key]) =>
            key.endsWith(".webp")
        );
    
        return webpConversion !== undefined;
    };
    

    const conversionTypes = conversions ? Object.keys(conversions) : null;

    const sourceElements = conversionTypes?.map((conversionType) => {
        const extension = conversionType.substr(
            conversionType.lastIndexOf(".") + 1
        );
        const type = `image/${
            extension === "webp" ? "webp" : extension === "png" ? "png" : "jpeg"
        }`;
        const conversionUrl = getConversionUrl(conversionType);

        // Ignora l'immagine WebP per ora
        if (extension === "webp") {
            return null;
        }

        return (
            <source key={conversionType} srcSet={conversionUrl} type={type} />
        );
    });

    // Aggiungi l'elemento <source> per l'immagine WebP come prima opzione
    if (hasWebPConversion()) {
        const webpConversion = Object.entries(conversions).find(([key]) =>
            key.endsWith(".webp")
        );
        const webpConversionType = webpConversion[0];
        const webpUrl = getConversionUrl(webpConversionType);
        sourceElements.unshift(
            <source
                key={webpConversionType}
                srcSet={webpUrl}
                type="image/webp"
            />
        );
    }

    const fallbackUrl = url;

    return (
        <picture>
            {sourceElements}
            <img
                src={fallbackUrl}
                loading="lazy"
                alt={alt !== null ? alt : undefined}
                title={title !== null ? title : undefined}
            />
        </picture>
    );
};

export default SmartImage;