import React from "react";
import styles from "./Middlebar.module.scss";
import SVG from "../../Utils/SVG";
import { Link, usePage } from "@inertiajs/react";
import { componentFactory } from "../../Utils/ComponentsFactory";
import ComponentsUtils from "../../Utils/ComponentsUtils";

const Middlebar = ({ setIsMobileMenuOpen, onSearchButtonClick }) => {
	const { locale, components, coreBannersAllPage, userLogged } = usePage().props;

	const componentMap = componentFactory();
	const zones = ["navbarRight", "navbarLeft", "navbarLogo"];
	const FINAL_COMPONENTS = ComponentsUtils.getComponents(components, coreBannersAllPage, zones);

	return (
		<section className={`container py-2`}>
			<div className="row">
				<div className={`col-12 ${styles.middlebar}`}>
					{/** Banner a sinistra del logo */}
					{FINAL_COMPONENTS.navbarLeft?.map((componentObj, index) => {
						return ComponentsUtils.renderComponent(
							componentObj,
							componentMap,
							`${styles.order1}`,
							"middlebar-navbarLeft",
							undefined,
							undefined,
							userLogged
						);
					})}

					{/** Bottone menu mobile */}
					<button
						className="navbar-toggler d-lg-none order-4"
						type="button"
						aria-label="Apri menu"
						onClick={() => setIsMobileMenuOpen(true)}
					>
						<span className="navbar-toggler-icon"></span>
					</button>

					<hr className="d-lg-none order-3" />

					{/** Logo */}
					{FINAL_COMPONENTS.navbarLogo?.map((componentObj, index) =>
						componentObj.data.map((banner, innerIndex) => (
							<Link
								key={`middlebar-logo-${index}-${innerIndex}`}
								className={`${styles.logoWrapper} order-5 order-lg-2`}
								dangerouslySetInnerHTML={{
									__html: `<img src='${banner?.media1 &&
										banner?.media1[0].url}' alt="ValleSabbiaNews logo" />`,
								}}
								href={banner.url?.[locale]}
							/>
						))
					)}

					{/** Banner a destra del logo */}
					{FINAL_COMPONENTS.navbarRight?.map((componentObj, index) => {
						return ComponentsUtils.renderComponent(
							componentObj,
							componentMap,
							`order-2 order-lg-3 ${styles.order2}`,
							"middlebar-navbarRight",
							undefined,
							undefined,
							userLogged
						);
					})}

					{/** Componente ricerca */}
					<button
						className={`${styles.search} order-6 d-lg-none`}
						aria-label="Cerca"
						dangerouslySetInnerHTML={{
							__html: SVG.search,
						}}
						onClick={onSearchButtonClick}
					/>
				</div>
			</div>
		</section>
	);
};

export default Middlebar;
