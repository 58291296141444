const GTrackUtils = {
    generateGTrackAction: (clickValue, viewValue) => {
        const gTrackClick = clickValue == 1;
        const gTrackView = viewValue == 1;

        const actions = [];

        if (gTrackClick) {
            actions.push('CLICK_LINK');
        }
        
        if (gTrackView) {
            actions.push('VIEW');
        }

        const gTrackAction = actions.length > 0 ? `${actions.join(',')}` : null;

        return {
            shouldIncludeDataGTrack: actions.length > 0,
            gTrackAction,
        };
    }
};

export default GTrackUtils;  