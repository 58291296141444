import React, { useEffect } from "react";
import { usePage } from "@inertiajs/react";

const AdSense = ({ data }) => {
	const { locale, userLogged } = usePage().props;
	const userHasSubscription = Boolean(userLogged?.private_package_active);
	const content = data?.content?.[locale];

	useEffect(() => {
		//console.log('%cAdSense: Component Mounted/Updated', 'color: #FF00FF');

		if (!userHasSubscription && content) {
			//console.log('%cAdSense: User does not have subscription, processing content', 'color: #FF00FF');

			const wrapper = document.createElement("div");
			wrapper.innerHTML = content;
			const adSlotDiv = wrapper.querySelector('div[id^="rtbuzz_"]');

			if (adSlotDiv) {
				//console.log('%cAdSense: Ad slot div found', 'color: #FF00FF');

				const checkAndInitializeGMP = () => {
					if (window._gmp && window._gmp.slotMap) {
						//console.log('%cAdSense: window._gmp is available', 'color: #FF00FF');
						const forcedAdSlot = adSlotDiv.id;
						const adSlotSelector = `#${forcedAdSlot}`; // Il selettore dello slot di annunci
						let slotKey = null;
						// Cerca la chiave corrispondente al selettore nello slotMap
						for (const key in window._gmp.slotMap) {
							if (
								window._gmp.slotMap[key].insert &&
								window._gmp.slotMap[key].insert.selector === adSlotSelector
							) {
								slotKey = key;
								break;
							}
						}
						if (slotKey) {
							//console.log('%cAdSense: Found slot key for the selector', 'color: #FF00FF', slotKey);
							window._gmp.forceAdSlot(slotKey);
							var forcedSlotDiv = document.getElementById(slotKey);
							if (forcedSlotDiv) {
								window._gmp.initCandidate(forcedSlotDiv);
							}
							clearInterval(checkInterval); // Stop the periodic check
						} else {
							//console.log('%cAdSense: No slot key found for the selector', 'color: #FF00FF');
						}
					} else {
						//console.log('%cAdSense: waiting for window._gmp', 'color: #FF00FF');
					}
				};

				let checkInterval = setInterval(checkAndInitializeGMP, 1000); // Check every 1000 ms

				return () => {
					clearInterval(checkInterval); // Clean up
					//console.log('%cAdSense: Component Unmounted, clearing interval', 'color: #FF00FF');
				};
			} else {
				//console.log('%cAdSense: No ad slot div found', 'color: #FF00FF');
			}
		} else {
			//console.log('%cAdSense: No content to process or user has subscription', 'color: #FF00FF');
		}
	}, [content, userHasSubscription]);

	return !userHasSubscription ? (
		<div className="AdSenseWrapper" dangerouslySetInnerHTML={{ __html: content }} />
	) : null;
};

export default AdSense;
