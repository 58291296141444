import React from "react";
import styles from "./Navbar.module.scss";
import { Link, usePage } from "@inertiajs/react";
import SVG from "../../Utils/SVG";
import SearchBox from "../Search/SearchBox/Index";
import ComponentsUtils from "../../Utils/ComponentsUtils";
import DateUtils from "../../Utils/DateUtils";
import TopbarWeather from "../Weather/TopbarWeather/Index";

const Navbar = ({ isMobileMenuOpen, setIsMobileMenuOpen, setInputRef }) => {
	const { locale, components, coreBannersAllPage, menu } = usePage().props;

	const zones = ["navbarLogo"];
	const FINAL_COMPONENTS = ComponentsUtils.getComponents(components, coreBannersAllPage, zones);

	const Dropdown = ({ menuItem, depth }) => {
		const showButton = menuItem.type.toLowerCase() === "text" ? true : false;

		const Component = showButton ? "button" : Link;
		const commonProps = {
			title: menuItem.name,
			className: showButton ? "dropdown-toggle" : "nav-link",
		};
		const specificProps = showButton
			? {
					type: "button",
					"data-bs-toggle": "dropdown",
					"aria-expanded": "false",
			  }
			: {
					target: menuItem.target,
			  };

		return (
			<li className={`nav-item ${styles.navItem}`}>
				<div className={`dropdown ${styles.dropdown} ${menuItem.data?.custom_style}`}>
					<Component {...commonProps} {...specificProps}>
						{menuItem.name}
					</Component>
					{menuItem.children && menuItem.children.length > 0 && (
						<NestedMenu items={menuItem.children} depth={depth + 1} />
					)}
				</div>
			</li>
		);
	};

	const buildTree = (items) => {
		const roots = Object.values(items).filter((item) => item.parent_id == null);
		// Ordina l'array roots in base alla proprietà "ord"
		roots.sort((a, b) => a.ord - b.ord);
		const children = Object.values(items).filter((item) => item.parent_id != null);

		roots.forEach((root) => {
			root.children = children.filter((child) => child.parent_id === root.id);
		});

		return roots;
	};

	const NavItem = ({ menuItem, menuItemName, depth }) => {
		if (menuItem.data?.show_group_of_type && menuItem.data?.custom_style === "paesi") {
			const tree = buildTree(menuItem.lists);
			return (
				<li className={styles.treePaesiWrapper}>
					<ul>
						{tree.map((item, index) => (
							<li key={`PaesiExternalTree-${index}`}>
								{/* Area */}
								<Link
									href={item.url && item.url?.[locale].url}
									title={item.title[locale] ? item.title[locale] : item.name}
									className={`nav-link ${styles.paesiArea}`}
									target={item.target}
								>
									<span>{item.title[locale] ? item.title[locale] : item.name}</span>
								</Link>

								{/* Elenco paesi */}
								{item.children && item.children.length > 0 && (
									<ul className={styles.treePaesi}>
										{item.children.map((child, childIndex) => (
											<li key={`PaesiTree-${childIndex}`}>
												<Link
													href={child.url && child.url?.[locale].url}
													title={child.title[locale] ? child.title[locale] : child.name}
													className="nav-link"
													target={child.target}
												>
													{child.title[locale] ? child.title[locale] : child.name}
												</Link>
											</li>
										))}
									</ul>
								)}
							</li>
						))}
					</ul>
				</li>
			);
		}

		return menuItem.data?.show_group_of_type ? (
			Object.values(menuItem.lists).map((item) =>
				item.custom_fields && item.custom_fields.hide_from_menu === "1" ? null : (
					<NavItem
						menuItem={item}
						menuItemName={item.title[locale] ? item.title[locale] : item.name}
						key={`menuItemList-${menuItem.id}-${item.id}`}
						depth={depth + 1}
					/>
				)
			)
		) : (
			<li className={`nav-item ${styles.navItem}`}>
				<Link
					href={menuItem?.url?.[locale]?.url}
					title={menuItemName}
					className="nav-link"
					target={menuItem.target}
				>
					{menuItemName}
				</Link>
				{menuItem.children && menuItem.children.length > 0 && (
					<NestedMenu items={menuItem.children} depth={depth + 1} />
				)}
			</li>
		);
	};

	const NestedMenu = ({ items = [], className, depth }) => {
		const list = (
			<ul className={className}>
				{items.length > 0 &&
					items.map((item, index) =>
						item.children.length > 0 ? (
							<Dropdown menuItem={item} key={`NestedMenu-${index}-${item.id}`} depth={depth} />
						) : (
							<NavItem
								menuItem={item}
								menuItemName={item.name}
								key={`NestedMenu-${index}-${item.id}`}
								depth={depth}
							/>
						)
					)}
			</ul>
		);

		return depth > 0 ? <div className={`dropdown-menu ${styles.dropdownMenu}`}>{list}</div> : list;
	};

	const SocialLink = ({ href, svg, title }) => (
		<li className={styles.navbarSociaLinkWrapper}>
			<a href={href} title={title} className={styles.navbarSociaLink} target="_blank" rel="nofollow noopener">
				<div dangerouslySetInnerHTML={{ __html: svg }} />
			</a>
		</li>
	);

	const socials = [
		{ href: "https://www.facebook.com/vallesabbianews", svg: SVG.facebookRoundIcon, title: "Facebook" },
		{ href: "https://www.youtube.com/user/vallesabbianews", svg: SVG.youtubeRoundIcon, title: "Instagram" },
		{
			href: "https://www.vallesabbianews.it/it/notizie//2018/11/03/con-telegram-e-meglio",
			svg: SVG.telegramRoundIcon,
			title: "Telegram",
		},
	];

	return (
		<>
			<div
				className={`backdrop d-lg-none ${isMobileMenuOpen ? "open" : ""}`}
				onClick={() => setIsMobileMenuOpen(false)}
			></div>
			<nav className={`navbar navbar-expand-lg navbar-light py-lg-0 ${isMobileMenuOpen ? "open" : ""}`}>
				<div className="container d-lg-none mobileMenuHeader">
					<div className="row">
						<div className="col-12">
							{/** Logo */}
							{FINAL_COMPONENTS.navbarLogo?.map((componentObj, indexMain) => {
								if (componentObj.keyType === "banners") {
									return componentObj?.data?.map((banner, index) => (
										<Link
											dangerouslySetInnerHTML={{
												__html: `<img alt="Logo" src='${banner?.media1 &&
													banner?.media1[0].url}' alt="ValleSabbiaNews logo" />`,
											}}
											href={banner.url?.[locale]}
											key={`NavbarLogo-${index}`}
										/>
									));
								}
							})}

							{/* Bottone chiusura menu */}
							<button
								type="button"
								className="btn-close"
								aria-label="Chiudi menu"
								onClick={() => setIsMobileMenuOpen(false)}
							></button>
						</div>
						{/* Meteo e data */}
						<div className={`col-12 font-sans mt-3 ${styles.mobileWeatherWrapper}`}>
							<span>{DateUtils.formatDataDayOfTheWeek(new Date())}</span>
							<span className="mx-2">&middot;</span>
							<TopbarWeather />
						</div>
					</div>
				</div>
				<div className="container mobileMenuContainer">
					<div
						className={`collapse navbar-collapse ${styles.navbarWrapper} ${isMobileMenuOpen ? "show" : ""}`}
						id="navbarNav"
					>
						{/* Menu principale */}
						<NestedMenu
							items={menu?.header?.root_menu_items.filter((menuItem) => menuItem.locale === locale)}
							className={`navbar-nav ${styles.navbar}`}
							depth={0}
						/>

						{/* Ricerca */}
						<ul className={styles.searchWrapper}>
							<SearchBox setInputRef={setInputRef} />
						</ul>

						{/* Social */}
						<ul className={styles.navbarSocial}>
							{socials.map((social) => (
								<SocialLink
									href={social.href}
									svg={social.svg}
									title={social.title}
									key={social.href}
								/>
							))}
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
};

export default Navbar;
