import React, { useEffect } from "react";
import styles from "./Subscription.module.scss";
import Layout from "../../Layout/Generico";
import HeadSEO from "../../Components/Head/Index";
import { Link } from "@inertiajs/react";
import SVG from "../../Utils/SVG";
import { useRefreshProps } from "../../Hooks";
import SubscriptionList from "../../Components/Subscription/SubscriptionList/Index";
import SEOUtils from "../../Utils/SEOUtils";

function Subscription(props) {
	const { page, locale, ziggy } = props;

	{
		/** Aggiorna le props principali una volta ogni 5 minuti */
	}
	useEffect(() => {
		useRefreshProps(["components", "coreBannersAllPage"], 300000);
	}, []);

	const main = (
		<>
			<div className="row justify-content-center pt-4">
				<div className="col-xl-7">
					<h1 className="h2 text-center">{page.title[locale] ? page.title[locale] : page.name}</h1>
					<p className="text-center mt-3 px-sm-5">{page.summary[locale]}</p>
				</div>

				<div className="col-xl-7">
					<SubscriptionList />
				</div>

				<div className="col-12">
					<hr className="separatore my-5"></hr>
				</div>

				<div className="col-xl-10">
					<h2 className="h4 text-center">Vantaggi inclusi solo nell'abbonamento premium</h2>
					<ul className={`mt-4 mt-sm-5 ${styles.gridAbbonamentoPremium}`}>
						<li>
							<div dangerouslySetInnerHTML={{ __html: SVG.banner }}></div>
							<div>
								<h3 className="h5 mb-2">Inserisci annunci</h3>
								<p>Pubblica i tuoi annunci</p>
							</div>
						</li>
						<li>
							<div dangerouslySetInnerHTML={{ __html: SVG.celebration }}></div>
							<div>
								<h3 className="h5 mb-2">Inserisci compleanni e lauree</h3>
								<p>Pubblica i tuoi annunci</p>
							</div>
						</li>
						<li>
							<div dangerouslySetInnerHTML={{ __html: SVG.comment }}></div>
							<div>
								<h3 className="h5 mb-2">Commenta gli articoli</h3>
								<p>Pubblica i tuoi annunci</p>
							</div>
						</li>
						<li>
							<div dangerouslySetInnerHTML={{ __html: SVG.necrologie }}></div>
							<div>
								<h3 className="h5 mb-2">Invia partecipazioni nelle necrologie</h3>
								<p>Pubblica i tuoi annunci</p>
							</div>
						</li>
					</ul>
				</div>

				<div className="col-12">
					<hr className="separatore my-5"></hr>
				</div>

				<div className="col-xl-7 mb-4">
					<div className="row">
						{/** Domande */}
						<div className="col-lg-6">
							<h4>Domande?</h4>
							<p className="mt-2 mt-sm-3">
								Per ulteriori dettagli sui servizi offerti da ValleSabbiaNews, ti invitiamo a consultare
								la sezione delle domande frequenti.
							</p>
							<Link
								className="mt-3 mt-sm-4 btn btn-primary btn-200"
								href="https://www.vallesabbianews.it/it/notizie/valsabbia/2020/11/05/come-fare-se"
							>
								Vai alle FAQ
							</Link>
						</div>

						{/** Contatti */}
						<div className="col-lg-6 mt-4 pt-2 pt-lg-0 mt-lg-0">
							<h4>Hai bisogno di assistenza?</h4>
							<p className="mt-2 mt-sm-3">
								Se necessiti di dettagli sul nostro servizio, su come effettuare il pagamento, o se
								incontri problemi durante l'acquisto o l'utilizzo del prodotto.
							</p>
							<Link className="mt-3 mt-sm-4 btn btn-primary btn-200" href="/it/scrivici">
								Contattaci
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);

	const seoData = SEOUtils.generateSEOData({
		page: page,
		locale: locale,
		type: "default",
		ziggy: ziggy,
		otherProps: {},
	});

	return (
		<>
			<HeadSEO data={seoData} />
			<Layout {...props} main={main} />
		</>
	);
}

export default Subscription;
