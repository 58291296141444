import React, { useEffect } from "react";
import styles from "./Books.module.scss";
import Layout from "../../Layout/Generico";
import HeadSEO from "../../Components/Head/Index";
import { componentFactory } from "../../Utils/ComponentsFactory";
import ComponentsUtils from "../../Utils/ComponentsUtils";
import { useRefreshProps } from "../../Hooks";
import SEOUtils from "../../Utils/SEOUtils";

function Books(props) {
	const { page, locale, components, coreBannersAllPage, userLogged, ziggy } = props;

	const componentMap = componentFactory();
	const zones = ["main"];
	const FINAL_COMPONENTS = ComponentsUtils.getComponents(components, coreBannersAllPage, zones);

	const booksList = Object.keys(FINAL_COMPONENTS).flatMap((key) =>
		FINAL_COMPONENTS[key].flatMap((item) =>
			item.data.filter((dataItem) => dataItem.core_banner_type && dataItem.core_banner_type.name === "Libro")
		)
	);

	{
		/* Aggiorna le props principali una volta ogni 5 minuti */
	}
	useEffect(() => {
		useRefreshProps(["components", "coreBannersAllPage"], 300000);
	}, []);

	const main = (
		<>
			{/* Introduzione */}
			<section className="text-center pt-2 pt-sm-3">
				{/** Titolo */}
				<h1 className="h2">{page.title?.[locale] ? page.title?.[locale] : page.name}</h1>

				{/** Sommario */}
				{page.summary?.[locale] && <p className="mt-2 font-serif-alt">{page.summary?.[locale]}</p>}

				{/** Contenuto */}
				{page.content?.[locale] && (
					<div
						className={`mt-4 font-serif-alt ${styles.pageContent}`}
						dangerouslySetInnerHTML={{
							__html: page.content?.[locale],
						}}
					/>
				)}
			</section>

			{/* Componenti main */}
			<section className="row">
				<div className="col-xl-10 offset-xl-1">
					<ul className="mt-4 mt-md-5">
						{FINAL_COMPONENTS.main?.map((componentObj, index) => {
							return ComponentsUtils.renderComponent(
								componentObj,
								componentMap,
								"",
								"main",
								undefined,
								<hr className="separatore"></hr>,
								userLogged
							);
						})}
					</ul>
				</div>
			</section>
		</>
	);

	const seoData = SEOUtils.generateSEOData({
		page: page,
		locale: locale,
		type: "books",
		ziggy: ziggy,
		otherProps: {
			booksList: booksList,
		},
	});

	return (
		<>
			<HeadSEO data={seoData} />
			<Layout {...props} main={main} />
			{/** Componenti popup */}
			{ComponentsUtils.renderPopupComponents(FINAL_COMPONENTS.popup, componentMap)}
		</>
	);
}

export default Books;
