import React from "react";
import { Link, usePage } from "@inertiajs/react";
import styles from "./NewsCard.module.scss";
import SVG from "../../../Utils/SVG";
import SmartImage from "../../Images/SmartImage/Index";
import DateUtils from "../../../Utils/DateUtils";

const NewsCard = ({ ...props }) => {
	const { locale } = usePage().props;
	const cardClass = `${styles.card} ${props.horizontal ? styles.cardHorizontal : styles.cardVertical} ${
		props.large ? styles.cardLarge : styles.cardSmall
	}`;

	const articleURL = props.data?.url?.[locale]?.url;
	const articlePretitle = props.data?.pre_title[locale];
	const articleTitle = props.data?.title[locale] || props.data?.name;
	const articleSummary = props.data?.summary[locale];
	const articleAuthor = props.data?.author_nickname;
	const articleImage = props.data?.cover_image ? props.data.cover_image[0] : null;
	const articleCategoryPiped = props.data?.magazine_group?.map((group) => group.title[locale]).join(" | ");
	const articleIsCommentable = props.data?.is_commentable > 0;
	const articleComments = props.data?.countMagazineComment;
	const articleDate = DateUtils.formatDateToDDMMYYYY(props.data?.publish_datetime);
	const articleTime = DateUtils.formatTimeToHHMM(props.data?.publish_datetime);
	const articleTimeWithSeconds = DateUtils.formatTimeToHHMM(props.data?.publish_datetime, true);
	const articleCategory = props.data?.magazine_group?.map((group) => group.title[locale]);
	const conjunction = articleCategory && /^[aeiouAEIOU]/.test(articleCategory.slice(-1)[0]) ? "ed" : "e";
	const formattedCategory = articleCategory
		? articleCategory
				.filter((item) => typeof item === "string" && item) // Filtra solo stringhe non vuote
				.map((item) => item.trim()) // Rimuove gli spazi bianchi
				.join(", ")
				.replace(/, ([^,]*)$/, ` ${conjunction} $1`) // Aggiunge la congiunzione prima dell'ultimo elemento
		: "";

	const isVideoNews = props.data.custom_fields?.video_news == "1";
	const isAudioNews = props.data.custom_fields?.audio_news == "1";

	const publicationDate = DateUtils.convertDateFromString(props.data?.publish_datetime, false);
	const publicationTime = DateUtils.formatTimeToHHMM(props.data?.publish_datetime);

	let displayDate = publicationDate;
	let displayTime = `Aggiornato alle ${publicationTime}`;

	// Controlla se 'updated_at' è più recente della data di pubblicazione
	if (props.data?.updated_at) {
		const updatedDate = DateUtils.convertDateFromString(props.data?.updated_at, false);
		const updatedTime = DateUtils.formatTimeToHHMM(props.data?.updated_at);
		const publicationDateTime = new Date(publicationDate + "T" + publicationTime);
		const updatedDateTime = new Date(updatedDate + "T" + updatedTime);

		// Verifica che la data/ora di aggiornamento sia più recente della data/ora di pubblicazione
		if (updatedDateTime > publicationDateTime) {
			if (updatedDate !== publicationDate) {
				displayTime = `Aggiornato il ${updatedDate} alle ${updatedTime}`;
			} else if (updatedTime !== publicationTime) {
				displayTime = `Aggiornato alle ${updatedTime}`;
			}
		}
	}

	{
		/* Card Timeline */
	}
	if (props.timeline) {
		return (
			<Link className={`${styles.card} ${styles.cardTimeline}`} href={articleURL} title={articleTitle}>
				<span className="text-uppercase font-sans font-small">
					{articleTimeWithSeconds} {formattedCategory ? `- ${formattedCategory}` : ""}
				</span>
				<div className={`mt-1 ${styles.contents}`}>
					{articleImage && (
						<div className={styles.image}>
							<SmartImage
								url={articleImage.url}
								conversions={articleImage.conversions}
								data={articleImage.data}
								className={props.horizontal ? styles.cardHorizontalImage : styles.cardVerticalImage}
							/>
						</div>
					)}
					<div>
						<h6>{articleTitle}</h6>
					</div>
				</div>
			</Link>
		);
	}

	{
		/* Card Top 10 */
	}
	if (props.top10) {
		return (
			<Link className={`${styles.card} ${styles.cardTop10}`} href={articleURL} title={articleTitle}>
				<span className="text-uppercase font-sans font-small">
					{articleDate} {formattedCategory ? `- ${formattedCategory}` : ""}
				</span>
				<h6 className="mt-1">{articleTitle}</h6>
			</Link>
		);
	}

	{
		/* Card generiche */
	}
	return (
		<div className={`${cardClass} ${!articleImage ? styles.noImage : ""}`}>
			{/* Immagine */}
			{articleImage && (
				<Link
					href={articleURL}
					aria-label="Leggi l'articolo"
					className={props.horizontal ? styles.cardHorizontalImageLink : styles.cardVerticalImageLink}
				>
					{/*{isVideoNews && (
                        <div
                            className={styles.cardVideoPlayWrapper}
                            dangerouslySetInnerHTML={{ __html: SVG.videoPlay }}
                        />
                    )}*/}
					{articleImage && (
						<div style={{ "--aspect-ratio": props.imageAspectRatio }}>
							<SmartImage
								url={articleImage.url}
								conversions={articleImage.conversions}
								data={articleImage.data}
								className={props.horizontal ? styles.cardHorizontalImage : styles.cardVerticalImage}
							/>
						</div>
					)}
				</Link>
			)}

			{/* Corpo */}
			<div className={`${props.horizontal ? styles.cardHorizontalBody : styles.cardVerticalBody}`}>
				{/* Categoria */}
				{/*{articleCategory && (
                    <div className={`${styles.cardCategory} newsCategory`}>
                        {articleCategory}
                    </div>
                )}*/}

				{/* Pre-title */}
				{articlePretitle && (
					<div className={`${styles.cardCategory} newsCategory`}>
						{articlePretitle}{" "}
						{props?.showDate && (
							<span className={styles.pretitleDateUpdated}>
								- {displayDate}
								{props.large && <>&nbsp;{displayTime}</>}
							</span>
						)}
					</div>
				)}

				{/* Titolo */}
				<Link href={articleURL} className={`mt-2 d-block ${styles.cardTitleLink}`}>
					{!props.horizontal && props.large && (
						<h1 className={`${styles.cardTitle} h2`}>
							{isVideoNews && (
								<div
									className={`${styles.cardTitleVideo} ${styles.cardTitleAudioVideoH1}`}
									dangerouslySetInnerHTML={{ __html: SVG.videoPlay }}
								/>
							)}
							{isAudioNews && (
								<div
									className={`${styles.cardTitleVideo} ${styles.cardTitleAudioVideoH1}`}
									dangerouslySetInnerHTML={{ __html: SVG.micWhite }}
								/>
							)}
							{articleTitle}
						</h1>
					)}
					{props.horizontal && props.large && (
						<h2 className={`${styles.cardTitle} h3`}>
							{isVideoNews && (
								<div
									className={`${styles.cardTitleVideo} ${styles.cardTitleAudioVideoH2}`}
									dangerouslySetInnerHTML={{ __html: SVG.videoPlay }}
								/>
							)}
							{isAudioNews && (
								<div
									className={`${styles.cardTitleVideo} ${styles.cardTitleAudioVideoH2}`}
									dangerouslySetInnerHTML={{ __html: SVG.micWhite }}
								/>
							)}
							{articleTitle}
						</h2>
					)}
					{!props.horizontal && !props.large && (
						<h5 className={styles.cardTitle}>
							{isVideoNews && (
								<div
									className={styles.cardTitleVideo}
									dangerouslySetInnerHTML={{ __html: SVG.videoPlay }}
								/>
							)}
							{isAudioNews && (
								<div
									className={styles.cardTitleVideo}
									dangerouslySetInnerHTML={{ __html: SVG.micWhite }}
								/>
							)}
							{articleTitle}
						</h5>
					)}
				</Link>

				{/* Sommario */}
				{articleSummary && (
					<Link href={articleURL} className={`${styles.cardSummaryLink} mt-2 d-block`}>
						<div className={styles.cardSummary} dangerouslySetInnerHTML={{ __html: articleSummary }} />
					</Link>
				)}

				{/* Autore */}
				{articleAuthor && (
					<div
						className={`${styles.cardAuthor} ${
							!props.horizontal && !props.large ? "mt-2" : "mt-2 mt-xl-3"
						}`}
					>
						di {articleAuthor}
					</div>
				)}

				{/* Commenti */}
				{articleIsCommentable && (
					<Link
						href={articleURL}
						className={`${styles.cardCommentsLink} ${
							!props.horizontal && !props.large ? "mt-2" : "mt-2 mt-xl-3"
						}`}
					>
						<div className={styles.cardComments}>
							<div
								dangerouslySetInnerHTML={{
									__html: SVG.comments,
								}}
							/>
							<span className="font-sans">
								{articleComments ? (
									`(${articleComments})`
								) : (
									<span className={`${styles.noComments}`}>Commenta ora</span>
								)}
							</span>
						</div>
					</Link>
				)}
			</div>
		</div>
	);
};

export default NewsCard;
