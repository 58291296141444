import React, { useEffect, useState } from "react";
//import styles from "./Necrologi.module.scss";
import Layout from "../../Layout/Necrologi";
import NecrologiForm from "../../Components/Necrologi/NecrologiForm/Index";
import NecrologiList from "../../Components/Necrologi/NecrologiList/Index";
import HeadSEO from "../../Components/Head/Index";
import { componentFactory } from "../../Utils/ComponentsFactory";
import ComponentsUtils from "../../Utils/ComponentsUtils";
import { useRefreshProps } from "../../Hooks";
import SEOUtils from "../../Utils/SEOUtils";

function Necrologi(props) {
    const { page, locale, components, coreBannersAllPage, obituaries, userLogged, ziggy } = props;

    const componentMap = componentFactory();
    const zones = ["asideTop", "asideMiddle", "asideBottom"];
    const FINAL_COMPONENTS = ComponentsUtils.getComponents(components, coreBannersAllPage, zones);

    {
        /** Aggiorna le props principali una volta ogni 5 minuti */
    }
    useEffect(() => {
        useRefreshProps(["components", "coreBannersAllPage", "obituaries"], 300000);
    }, []);

    {
        /* Stati per gestione ricerca necrologi */
    }
    const [filteredObituaries, setFilteredObituaries] = useState(obituaries);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    const handleFormSubmit = (filterData) => {
        // Controlla se entrambi i campi sono vuoti o non esistono
        if (
            (!filterData.nome || filterData.nome.trim() === "") &&
            (!filterData.luogo || filterData.luogo.trim() === "")
        ) {
            // Imposta obituaries come filteredObituaries ed esce resettando gli  obituari iniziali
            setFilteredObituaries(obituaries);
            setIsFormSubmitting(false);
            return;
        }
        setIsFormSubmitting(true);
        const source = axios.CancelToken.source();
        axios
            .post(
                `/api/obituary/search`,
                {
                    name: filterData.nome,
                    location: filterData.luogo,
                },
                {
                    cancelToken: source.token,
                }
            )
            .then((response) => {
                const data = response.data;
                if (data.result && data?.data?.length > 0) {
                    setFilteredObituaries(data.data);
                } else {
                    console.error("Impossibile trovare risultati per la ricerca corrente");
                    setFilteredObituaries([]);
                }
                setIsFormSubmitting(false);
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log("Richiesta cancellata");
                } else {
                    console.error("Errore nella richiesta:", error);
                }
                setFilteredObituaries([]);
                setIsFormSubmitting(false);
            });
    };

    const main = (
        <>
            {/* Form ricerca */}
            <NecrologiForm onFormSubmit={handleFormSubmit} />

            {/* Elenco card */}
            <NecrologiList filteredObituaries={filteredObituaries} isFormSubmitting={isFormSubmitting} />
        </>
    );

    const aside = (
        <>
            {FINAL_COMPONENTS.asideTop?.map((componentObj, index) => {
                return ComponentsUtils.renderComponent(
                    componentObj,
                    componentMap,
                    "",
                    "asideTop",
                    undefined,
                    <hr className="separatore"></hr>,
                    userLogged
                );
            })}

            {FINAL_COMPONENTS.asideMiddle?.map((componentObj, index) => {
                return ComponentsUtils.renderComponent(
                    componentObj,
                    componentMap,
                    "",
                    "asideMiddle",
                    undefined,
                    <hr className="separatore"></hr>,
                    userLogged
                );
            })}

            {FINAL_COMPONENTS.asideBottom?.map((componentObj, index) => {
                return ComponentsUtils.renderComponent(
                    componentObj,
                    componentMap,
                    "",
                    "asideBottom",
                    undefined,
                    <hr className="separatore"></hr>,
                    userLogged
                );
            })}
        </>
    );

    const seoData = SEOUtils.generateSEOData({
        page: page,
        locale: locale,
        type: "necrologi",
        ziggy: ziggy,
        otherProps: {
            obituaries: obituaries,
        },
    });

    return (
        <>
            <HeadSEO data={seoData} />
            <Layout {...props} main={main} aside={aside} />
            {/** Componenti popup */}
            {ComponentsUtils.renderPopupComponents(FINAL_COMPONENTS.popup, componentMap)}
        </>
    );
}

export default Necrologi;
