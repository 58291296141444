import React from "react";
import styles from "./RedazioneCard.module.scss";
import { usePage } from "@inertiajs/react";
import SmartImage from "../../Images/SmartImage/Index";
import GTrackUtils from "../../../Utils/GTrackUtils";

const RedazioneCard = ({ ...props }) => {
	const { locale } = usePage().props;
	const { data } = props;

	const { shouldIncludeDataGTrack, gTrackAction } = GTrackUtils.generateGTrackAction(
		props.data?.custom_fields?.gTrack_click,
		props.data?.custom_fields?.gTrack_view
	);

	const image = data.media1?.[0];
	const name = data.title?.[locale];
	const role = data.summary?.[locale];
	const content = data.content?.[locale];

	const nameRoleClasses = `${image ? "pt-3 ms-3 ms-lg-4" : ""}`;

	return (
		<div
			className={`${styles.card}`}
			{...(shouldIncludeDataGTrack && {
				"data-gtrack": true,
				"data-gtrack-id": data.id,
				"data-gtrack-type": "core_banner",
				"data-gtrack-action": gTrackAction,
			})}
		>
			{/* Info */}
			<div className={`${styles.info}`}>
				{/* Immagine */}
				{image && (
					<div style={{ "--aspect-ratio": "1/1" }} className={`${styles.imgWrapper}`}>
						<SmartImage url={image.url} conversions={image.conversions} data={image.data} />
					</div>
				)}

				{/* Nome e ruolo */}
				{(name || role) && (
					<div className={nameRoleClasses}>
						{name && <h3 className="text-primary">{name}</h3>}
						{role && <span className="fst-italic mt-1 mt-lg-2 d-block">{role}</span>}
					</div>
				)}
			</div>

			{/* Descrizione */}
			{content && <p className="mt-2 mt-lg-3 font-serif-alt" dangerouslySetInnerHTML={{ __html: content }} />}
		</div>
	);
};

export default RedazioneCard;
