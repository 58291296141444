import React from "react";
import { Link } from "@inertiajs/react";

function BlogLink({ url, title, author }) {
    return (
        <Link href={url} title={title} className="blogLink">
            <h4 className="text-uppercase h6">{title}</h4>
            <span className="d-block mt-1 text-primary">{author}</span>
        </Link>
    );
}

export default BlogLink;
