import React from "react";
import styles from "./CommentItem.module.scss";
import CommentForm from "../CommentForm/Index";
import DateUtils from "../../../Utils/DateUtils";
import SVG from "../../../Utils/SVG";

function generateColor(name) {
	let hash = 0;
	if(!name) name = "";
	for (let i = 0; i < name.length; i++) {
		hash = name.charCodeAt(i) + ((hash << 5) - hash);
	}
	let color = ((hash & 0x00ffffff) | 0x1000000).toString(16).substring(1);
	return color;
}

function CommentItem({ comment, user, level, onReplyClick, replyingTo, canComment }) {
	const handleReply = () => {
		onReplyClick(comment.id);
	};

	const handleCancelReply = () => {
		onReplyClick(null);
	};

	const commentAuthor = comment.firma;
	const commentFirma = comment.firma;
	const commentTitle = comment.title;
	const commentContent = comment.content;
	const commentDate = comment.created_at;

	return (
		<div
			className={`${styles.comment} ${comment.replies && comment.replies.length > 0 ? styles.hasReplies : ""}`}
			style={{ marginLeft: `${level * 16}px` }}
		>
			{/* Avatar */}
			<img
				src={`https://ui-avatars.com/api/?name=${commentAuthor}&background=${generateColor(commentAuthor)}`}
				alt={`Avatar di ${commentAuthor}`}
				className={styles.avatar}
			/>

			{/* Commento */}
			<div className={styles.commentContent}>
				<div className={`font-sans ${styles.nameDateWrapper}`}>
					<p className="">{commentFirma}</p>
					<small>{DateUtils.formatCommentDate(commentDate)}</small>
				</div>
				<strong>{commentTitle}</strong>
				<p>{commentContent}</p>

				{/* Azioni */}
				{canComment && (
					<div className={styles.actionsWrapper}>
						<button className={`font-sans ${styles.replyButton}`} onClick={handleReply}>
							<div dangerouslySetInnerHTML={{ __html: SVG.replyPrimaryLight }} />
							Rispondi
						</button>
						{replyingTo === comment.id && (
							<>
								<span>&middot;</span>
								<button
									className={`font-sans text-danger ${styles.cancelButton}`}
									onClick={handleCancelReply}
								>
									<div dangerouslySetInnerHTML={{ __html: SVG.xDanger }} />
									Annulla
								</button>
							</>
						)}
					</div>
				)}

				{/* Se è utente corrente, può modifiare o cancellare */}
				{/*user && (
                        <>
                            <button>Edit</button>
                            <button>Delete</button>
                        </>
                )*/}
			</div>

			{/* Form replica */}
			{replyingTo === comment.id && canComment && (
				<div className={`commentsFormWrapper ${styles.formWrapper}`}>
					<CommentForm
						user={user}
						replyTo={comment}
						parentID={comment.id}
						formID={`replyForm-${comment.id}`}
						autoFocus
					/>
				</div>
			)}

			{/* Risposte al commento */}
			{comment.replies && (
				<div className={styles.repliesWrapper}>
					{comment.replies.map((reply) => (
						<CommentItem
							key={`CommentItem-${reply.id}`}
							comment={reply}
							user={user}
							level={level + 1}
							onReplyClick={onReplyClick}
							replyingTo={replyingTo}
						/>
					))}
				</div>
			)}
		</div>
	);
}

export default CommentItem;
