import React, { useState, useEffect } from "react";
//import styles from './FormContatti.module.scss';
import { usePage } from "@inertiajs/react";
import Form from "../../Form/Index";
import axios from "axios";

function FormContatti() {
    const { locale } = usePage().props;
    const [formInfo, setFormInfo] = useState({ name: "Scrivici" });

    const isFieldRequired = (fieldName) => {
        const field = formInfo.inputs?.find((input) => input.name === fieldName);
        return field?.is_required === 1;
    };

    useEffect(() => {
        const source = axios.CancelToken.source();
        axios
            .get(`/api/forms/${formInfo.name}`, { cancelToken: source.token })
            .then((response) => {
                const data = response.data;
                if (data.result) {
                    const formData = data.data;
                    setFormInfo((prevState) => ({
                        ...prevState,
                        messageReply: formData.message_reply[locale],
                        id: formData.id,
                        inputs: formData.form_field,
                    }));
                } else {
                    console.error("Impossibile trovare la form richiesta");
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    console.log("Richiesta cancellata");
                } else {
                    console.error("Errore nella richiesta:", error);
                }
            });

        return () => {
            source.cancel("Componente smontato");
        };
    }, []);

    const fieldsHTML = (
        <>
            <input type="hidden" name="id_form" value={formInfo.id} />
            <input type="hidden" name="name_form" value={formInfo.name} />
            <input type="hidden" name="locale" value={locale} />

            <div className="row g-4 mb-3 mb-lg-4">
                <div className="col-12">
                    <div className="form-check form-check-inline mb-0">
                        <input
                            className="form-check-input"
                            type="radio"
                            value="Privato"
                            id="formContattiPrivato"
                            name="privatoAzienda"
                            required={isFieldRequired("privatoAzienda")}
                            defaultChecked
                            data-cons-exclude
                        />
                        <label className="form-check-label" htmlFor="formContattiPrivato">
                            Privato
                        </label>
                    </div>
                    <div className="form-check form-check-inline mb-0">
                        <input
                            className="form-check-input"
                            type="radio"
                            value="Azienda"
                            id="formContattiAzienda"
                            name="privatoAzienda"
                            required={isFieldRequired("privatoAzienda")}
                            data-cons-exclude
                        />
                        <label className="form-check-label" htmlFor="formContattiAzienda">
                            Azienda
                        </label>
                    </div>
                </div>
            </div>

            {/* Nome e Cognome */}
            <div className="row g-4 mb-3 mb-lg-4">
                <div className="col-lg-6">
                    <div className="form-floating">
                        <input
                            type="text"
                            className="form-control"
                            id="formContattiName"
                            placeholder={`Nome${isFieldRequired("name") ? "*" : ""}`}
                            name="name"
                            required={isFieldRequired("name")}
                            data-cons-subject="first_name"
                        />
                        <label htmlFor="formContattiName">Nome{isFieldRequired("name") ? "*" : ""}</label>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-floating">
                        <input
                            type="text"
                            className="form-control"
                            id="formContattiSurname"
                            placeholder={`Cognome${isFieldRequired("surname") ? "*" : ""}`}
                            name="surname"
                            required={isFieldRequired("surname")}
                            data-cons-subject="last_name"
                        />
                        <label htmlFor="formContattiSurname">Cognome{isFieldRequired("surname") ? "*" : ""}</label>
                    </div>
                </div>
            </div>

            {/* Indirizzo e CAP */}
            <div className="row g-4 mb-3 mb-lg-4">
                <div className="col-lg-6">
                    <div className="form-floating">
                        <input
                            type="text"
                            className="form-control"
                            id="formContattiAddress"
                            placeholder={`Indirizzo${isFieldRequired("address") ? "*" : ""}`}
                            name="address"
                            required={isFieldRequired("address")}
                            data-cons-exclude
                        />
                        <label htmlFor="formContattiAddress">Indirizzo{isFieldRequired("address") ? "*" : ""}</label>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-floating">
                        <input
                            type="text"
                            className="form-control"
                            id="formContattiCAP"
                            placeholder={`CAP${isFieldRequired("cap") ? "*" : ""}`}
                            name="cap"
                            required={isFieldRequired("cap")}
                            inputMode="numeric"
                            data-cons-exclude
                        />
                        <label htmlFor="formContattiCAP">CAP{isFieldRequired("cap") ? "*" : ""}</label>
                    </div>
                </div>
            </div>

            {/* Città e Provincia */}
            <div className="row g-4 mb-3 mb-lg-4">
                <div className="col-lg-6">
                    <div className="form-floating">
                        <input
                            type="text"
                            className="form-control"
                            id="formContattiCity"
                            placeholder={`Città${isFieldRequired("city") ? "*" : ""}`}
                            name="city"
                            required={isFieldRequired("city")}
                            data-cons-exclude
                        />
                        <label htmlFor="formContattiCity">Città{isFieldRequired("city") ? "*" : ""}</label>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-floating">
                        <input
                            type="text"
                            className="form-control"
                            id="formContattiProvince"
                            placeholder={`Provincia${isFieldRequired("province") ? "*" : ""}`}
                            name="province"
                            required={isFieldRequired("province")}
                            data-cons-exclude
                        />
                        <label htmlFor="formContattiProvince">Provincia{isFieldRequired("province") ? "*" : ""}</label>
                    </div>
                </div>
            </div>

            {/* Telefono e Mail */}
            <div className="row g-4 mb-3 mb-lg-4">
                <div className="col-lg-6">
                    <div className="form-floating">
                        <input
                            type="text"
                            className="form-control"
                            id="formContattiTel"
                            placeholder={`Telefono${isFieldRequired("tel") ? "*" : ""}`}
                            name="tel"
                            required={isFieldRequired("tel")}
                            inputMode="tel"
                            data-cons-exclude
                        />
                        <label htmlFor="formContattiTel">Telefono{isFieldRequired("tel") ? "*" : ""}</label>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-floating">
                        <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="formContattiEmail"
                            placeholder={`Mail${isFieldRequired("email") ? "*" : ""}`}
                            required={isFieldRequired("email")}
                            inputMode="email"
                            data-cons-subject="email"
                        />
                        <label htmlFor="formContattiEmail">Mail{isFieldRequired("email") ? "*" : ""}</label>
                    </div>
                </div>
            </div>

            {/* File */}
            <div className="row mb-3 mb-lg-4">
                <div className="col-12">
                    <label htmlFor="formContattiFileMultiple" className="form-label">
                        Allega uno o più file (formato PDF, jpg, png){isFieldRequired("file") ? "*" : ""}
                    </label>
                    <input
                        className="form-control"
                        type="file"
                        name="file[]"
                        id="formContattiFileMultiple"
                        multiple
                        required={isFieldRequired("file")}
                        data-cons-exclude
                    />
                </div>
            </div>

            {/* Messaggio */}
            <div className="row mb-3 mb-lg-4">
                <div className="col-12">
                    <div className="form-floating">
                        <textarea
                            className="form-control"
                            placeholder="Scrivi qui il tuo messaggio*"
                            id="formContattiMessage"
                            style={{ height: "140px" }}
                            name="message"
                            required={isFieldRequired("message")}
                            data-cons-exclude
                        ></textarea>
                        <label htmlFor="formContattiMessage">
                            Scrivi qui il tuo messaggio{isFieldRequired("message") ? "*" : ""}
                        </label>
                    </div>
                </div>
            </div>

            {/* Termini e condizioni */}
            <div className="row mb-3 mb-lg-4">
                <div className="col-12">
                    <div className="textareaWrapper">
                        <h6 className="mb-2">{isFieldRequired("terminiCondizioni") ? "*" : ""}Termini e condizioni</h6>
                        <p>
                            L'invio dei contributi (es. lettere, articoli, commenti, immagini, fotografie, video ecc.,
                            di seguito "Contributi") costituirà ammissione di titolarità da parte dell'utente. L'utente
                            assume la piena responsabilità, con esclusione di qualsiasi responsabilità di Edizioni Valle
                            Sabbia S.r.l., circa i Contributi da pubblicare sul Sito ed assicura che gli stessi saranno
                            nella sua piena e legittima titolarità e disponibilità, non saranno contrari a norme
                            imperative di legge o regolamentari e non violeranno alcun diritto d'autore, segno
                            distintivo, marchio, brevetto e/o altro diritto di terzi, derivante da legge, accordi di
                            riservatezza, contratti, usi e consuetudini.
                            <br />
                            L'utente si obbliga a tenere espressamente indenne Edizioni Valle Sabbia S.r.l. da tutte le
                            eventuali pretese giudiziali e/o stragiudiziali da parte di terzi, per violazione del
                            diritto d'autore o di altro diritto di proprietà intellettuale e/o industriale, o comunque
                            dipendenti dal contenuto dei Contributi pubblicati, ovvero in qualsiasi modo collegate alla
                            fruizione da parte di altri utenti dei Contributi pubblicati.
                            <br />
                        </p>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <div className="form-check form-check-inline mb-0">
                        <input
                            className="form-check-input"
                            type="radio"
                            value="1"
                            id="formContattiTerminiCondizioniOk"
                            name="terminiCondizioni"
                            required={isFieldRequired("terminiCondizioni")}
                            data-cons-exclude
                        />
                        <label className="form-check-label" htmlFor="formContattiTerminiCondizioniOk">
                            Accetto
                        </label>
                    </div>
                    <div className="form-check form-check-inline mb-0">
                        <input
                            className="form-check-input"
                            type="radio"
                            value="0"
                            id="formContattiTerminiCondizioniKo"
                            name="terminiCondizioni"
                            data-cons-exclude
                        />
                        <label className="form-check-label" htmlFor="formContattiTerminiCondizioniKo">
                            Non accetto
                        </label>
                    </div>
                </div>
            </div>

            {/* Privacy Policy */}
            <div className="row mb-3 mb-lg-4">
                <div className="col-12">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="formContattiPrivacy"
                            value="ok"
                            required={isFieldRequired("privacy")}
                            name="privacy"
                            data-cons-preference="general"
                        />
                        <label className="form-check-label" htmlFor="formContattiPrivacy">
                            {isFieldRequired("privacy") ? "*" : ""}Dichiaro di aver preso visione della privacy policy
                            di Edizioni Valle Sabbia S.r.l. ed autorizzo Edizioni Valle Sabbia S.r.l. al trattamento dei
                            miei dati personali per la pubblicazione sul sito www.vallesabbianews.it di miei articoli,
                            commenti, segnalazioni, interventi e/o contributi di qualsiasi genere e natura e per
                            compiere tutte le attività ad essa correlate.
                        </label>
                    </div>
                </div>
            </div>

            {/* Newsletter */}
            {/*<div className="row mb-3 mb-lg-4">
				<div className="col-12">
					<div className="form-check">
						<input
							className="form-check-input"
							type="checkbox"
							id="formContattiNewsletter"
							value="ok"
							name="newsletter"
							required={isFieldRequired("newsletter")}
							data-cons-preference="newsletter"
						/>
						<label className="form-check-label" htmlFor="formContattiNewsletter">
							{isFieldRequired("newsletter") ? "*" : ""}Autorizzo altresì Edizioni Valle Sabbia S.r.l. al
							trattamento dei miei dati personali per l’invio di materiale informativo, commerciale,
							promozionale e/o pubblicitario a mezzo e-mail, sms/mms e/o via telefono.
						</label>
					</div>
				</div>
			</div>*/}

            <div className="row">
                <div className="col-12">
                    <input type="submit" name="submit" className="btn btn-primary" value="Invia" data-cons-exclude />
                </div>
            </div>
        </>
    );

    return (
        <Form
            apiUrl={"/api/forms/submit"}
            extraProps={{ className: `font-sans`, id: "formContatti" }}
            fieldsHTML={fieldsHTML}
            stateReset={false}
            refreshPageProps={false}
            submissionSuccessMessage={formInfo.messageReply}
            onSuccess={() => {
                if (typeof window !== "undefined" && window._iub) {
                    const subjectId = document.querySelector('#formContatti [data-cons-subject="email"]').value;
                    _iub.cons_instructions.push([
                        "submit",
                        {
                            writeOnLocalStorage: false,
                            form: {
                                selector: document.getElementById("formContatti"),
                            },
                            consent: {
                                subject: { id: subjectId },
                                legal_notices: [{ identifier: "privacy_policy" }, { identifier: "cookie_policy" }],
                            },
                        },
                        {
                            success: function (response) {
                                // console.log(response);
                            },
                            error: function (response) {
                                // console.log(response);
                            },
                        },
                    ]);
                }
            }}
        />
    );
}

export default FormContatti;
