import React, { useEffect, useState } from "react";
import styles from "./EventsDailyList.module.scss";
import EventCard from "../EventCard/Index";
import { usePage } from "@inertiajs/react";
import DateUtils from "../../../Utils/DateUtils";
import PrivateAreaUtils from "../../../Utils/PrivateAreaUtils";

const EventsDailyList = ({ ...props }) => {
	const { locale } = usePage().props;
	const events = props.events ? props.events : props.data.data;
	const large = props.large ? props.large : false;
	const title = props.title ? props.title : props.data.component[`title_${locale}`];

	const [places, setPlaces] = useState({});

	useEffect(() => {
		const fetchCityNames = async () => {
			const updatedPlaces = {};

			for (let event of events) {
				if (!event?.event_category[0]?.title[locale] && !event?.event_category[0]?.name) {
					const cityName = await PrivateAreaUtils.address.getCityNameById(event.core_city_id);
					updatedPlaces[event.core_city_id] = cityName;
				}
			}

			setPlaces(updatedPlaces);
		};

		fetchCityNames();
	}, [events, locale]);

	const eventsList =
		events.length > 0 ? (
			events.map((event, index) => [
				<li key={index} className={styles.eventCardWrapper}>
					<EventCard
						url={event.url?.[locale].url}
						pretitle={DateUtils.convertDateRangeFromString(event.date_from, event.date_to)}
						title={event.title[locale] ? event.title[locale] : event.name}
						place={
							event?.event_category[0]?.title[locale] ||
							event?.event_category[0]?.name ||
							places[event.core_city_id] ||
							null
						}
						image={event?.cover_image}
						large={large}
					/>
				</li>,
				index !== events.length - 1 && (
					<li className={styles.hr} key={`hr-${index}`}>
						<hr />
					</li>
				),
			])
		) : (
			<li>
				<p className={`my-3`}>Non ci sono eventi in programma al momento.</p>
			</li>
		);

	return (
		<section
			className={`${styles.dailyListWrapper} ${large ? styles.dailyListWrapperLarge : ""} ${
				props.wrapperCSSClasses
			}`}
		>
			{large ? (
				<h1 className={`font-sans text-uppercase h2 ${styles.dailyListTitle}`}>{title}</h1>
			) : (
				<h5 className={`font-sans text-uppercase ${styles.dailyListTitle}`}>{title}</h5>
			)}
			<ul>{eventsList}</ul>
		</section>
	);
};

export default EventsDailyList;
