import { useEffect } from "react";

export const useTrackClicks = (onClick, isTrackingEnabled) => {
	useEffect(() => {
		const clickHandler = (event) => {
			// Trova il contenitore più vicino con gli attributi data-gtrack-*
			const trackingContainer = event.target.closest("[data-gtrack]");
			const clickedElement = event.target.closest("a");

			if (trackingContainer && clickedElement && clickedElement.getAttribute("href")) {
				const href = clickedElement.getAttribute("href").trim();
				const trackId = trackingContainer.getAttribute("data-gtrack-id");
				const trackType = trackingContainer.getAttribute("data-gtrack-type");
				const uniqueKey = `${trackType}-${trackId}`;
				if (href) {
					const actions = trackingContainer.getAttribute("data-gtrack-action");
					const shouldTrackClick =
						actions &&
						actions
							.split(",")
							.map((action) => action.trim())
							.includes("CLICK_LINK");
					const url = clickedElement.getAttribute("href");

					if (shouldTrackClick) {
						console.log(
							"%cGTRACK:",
							"color: lightgreen;",
							`Link click registrato su GTRACK-ID ${uniqueKey} con URL ${url}.`
						);
						onClick(trackId, trackType, "CLICK_LINK", url); // Passa i dati rilevanti a onClick
						console.log(
							"%cGTRACK:",
							"color: dodgerblue;",
							`Il link click su GTRACK-ID ${uniqueKey} con URL ${url} è stato tracciato.`
						);
					}
				} else {
					console.log(
						"%cGTRACK:",
						"color: orange;",
						`Il link click su GTRACK-ID ${uniqueKey} non può avvenire perché l'attributo href è vuoto.`
					);
				}
				console.log(
					"%cGTRACK:",
					"color: orange;",
					`Il link click su GTRACK-ID ${uniqueKey} non può essere tracciato.`
				);
			}
		};

		// Aggiungo il listener di eventi al documento
		if (isTrackingEnabled) document.addEventListener("click", clickHandler);

		// Rimuovo il listener quando il componente viene smontato
		return () => document.removeEventListener("click", clickHandler);
	}, [onClick]);
};
