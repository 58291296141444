import { useEffect, useRef } from 'react';

export const useTrackVisibility = (onVisible, isTrackingEnabled) => {
    const seenElements = useRef(new Set());
    const observers = useRef([]);

    useEffect(() => {
        if (isTrackingEnabled) {
            document.querySelectorAll('[data-gtrack]').forEach(element => {
                const id = element.getAttribute('data-gtrack-id');
                const type = element.getAttribute('data-gtrack-type');
                const actions = element.getAttribute('data-gtrack-action');
                const threshold = element.getAttribute('data-gtrack-threshold') 
                                    ? parseFloat(element.getAttribute('data-gtrack-threshold')) 
                                    : 0.5;

                if (actions?.split(',').includes('VIEW')) {
                    const uniqueKey = `${type}-${id}`;
                    const observer = new IntersectionObserver(
                        (entries) => {
                            entries.forEach(entry => {
                                if (entry.isIntersecting && !seenElements.current.has(uniqueKey)) {
                                    seenElements.current.add(uniqueKey);
                                    console.log('%cGTRACK:', 'color: lightgreen;', `Element with GTRACK-ID ${uniqueKey} is now visible on the page.`);
                                    onVisible(entry.target); // Passa l'intero elemento
                                    console.log('%cGTRACK:', 'color: dodgerblue;', `Element with GTRACK-ID ${uniqueKey} has been tracked.`);
                                } else if (!entry.isIntersecting) {
                                    console.log('%cGTRACK:', 'color: violet;', `Element with GTRACK-ID ${uniqueKey} found in page, currently not visible.`);
                                } else {
                                    console.log('%cGTRACK:', 'color: orange;', `Element with GTRACK-ID ${uniqueKey} has already been viewed, skipping count.`);
                                }
                            });
                        },
                        { threshold }
                    );

                    observer.observe(element);
                    observers.current.push(observer); // Memorizza l'observer per una successiva disconnessione
                }
            });
        }
        return () => {
            observers.current.forEach(observer => {
                observer.disconnect();
                console.log('%cGTRACK:', 'color: orange;', 'Observer disconnected.');
            });
        };
    }, [onVisible]);

};
