import React, { useEffect, useState, useRef } from "react";
import styles from "./SatispayButton.module.scss";
import SVG from "../../../Utils/SVG";
import PrivateAreaUtils from "../../../Utils/PrivateAreaUtils";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { usePage } from "@inertiajs/react";

const SatispayButton = ({
	subscriptionData,
	onSatispaySubscriptionSuccess,
	onSatispaySubscriptionError,
	selectedAddressId,
	isInvoiceNeeded,
}) => {
	const { config } = usePage().props;
	const isProduction = config.env !== "development";

	const [satispay, setSatispay] = useState(null); // Memorizza l'istanza di satispay
	const buttonRef = useRef(null); // Ref per il bottone rosso di Satispay
	const [showButton, setShowButton] = useState(false); // Se mostrare o meno i bottone

	let subscriptionExtraData = {
		plan_id: subscriptionData.id,
		private_address_id: selectedAddressId,
		is_invoice_required: isInvoiceNeeded,
	};
	const subscriptionExtraDataRef = useRef(subscriptionExtraData);

	// Carica lo script di Satispay e lo rimuove quando il componente viene smontato
	useEffect(() => {
		const script = document.createElement("script");
		script.src = `https://${isProduction ? "" : "staging."}online.satispay.com/web-button.js`;
		script.async = true;
		script.onload = () => configureSatispay();
		document.body.appendChild(script);

		// Allo smontaggio rimuove lo script
		return () => {
			document.body.removeChild(script);
		};
	}, []);

	// Aggiunge listener al bottone di Satispay solo quando è visibile ed è arrivata la corretta configurazione
	useEffect(() => {
		const button = buttonRef.current;
		if (showButton && satispay && button) {
			const clickHandler = (e) => {
				e.preventDefault();
				satispay.open();
			};

			button.addEventListener("click", clickHandler);

			return () => {
				button.removeEventListener("click", clickHandler);
			};
		}
	}, [showButton, satispay]);

	useEffect(() => {
		subscriptionExtraDataRef.current = {
			...subscriptionExtraData,
			is_invoice_required: isInvoiceNeeded,
		};
	}, [isInvoiceNeeded, selectedAddressId, subscriptionData.id]);

	const configureSatispay = async () => {
		// Ottengo il preAuthorizedPaymentToken che devo utilizzare poi nel render del WebButton
		const response = await PrivateAreaUtils.subscription.create(
			subscriptionData.id,
			"satispay_subscription",
			subscriptionExtraDataRef.current
		);

		// Se la risposta è positiva e mi arriva qualcosa in data, proseguo
		if (response.result && response.data) {
			const preAuthorizedPaymentToken = response.data.id;

			// Inizio a configurare l'oggetto Satispay
			let satispay = window.SatispayWebButton.configure({
				preAuthTokenId: preAuthorizedPaymentToken,
				completed: async function() {
					// Inizia il check del successo dell'operazione
					try {
						const isSuccess = await PrivateAreaUtils.subscription.checkSatispaySubscriptionSuccess(
							preAuthorizedPaymentToken
						);
						if (isSuccess) {
							satispay.close(); // Chiude l'iframe di Satispay
							onSatispaySubscriptionSuccess(); // Chiama il callback di successo
						} else {
							onSatispaySubscriptionError("Stiamo verificando il tuo pagamento."); // Chiama il callback di errore con un messaggio
						}
					} catch (error) {
						console.error("Errore durante la verifica del pagamento:", error.message);
						onSatispaySubscriptionError(error); // Chiama il callback di errore con l'errore catturato
					}
				},
			});

			// Setta l'oggetto satispay e mostra il bottone
			setSatispay(satispay);
			setShowButton(true);
		} else {
			onSatispaySubscriptionError("Si è verificato un errore imprevisto. Per favore, riprova più tardi.");
		}
	};

	return (
		<>
			{showButton ? (
				<div className={`btn mt-4 w-100 ${styles.button}`} id="pay-with-satispay" ref={buttonRef}>
					<img
						src={`https://${isProduction ? "" : "staging."}online.satispay.com/images/it-pay-red.svg`}
						alt="Paga con Satispay"
					/>
					<div
						dangerouslySetInnerHTML={{
							__html: SVG.rightArrowWhite,
						}}
					/>
				</div>
			) : (
				<div className="mt-4">
					<Skeleton count={1} containerClassName={`${styles.skeletonBtn}`} inline={true} />
				</div>
			)}
		</>
	);
};

export default SatispayButton;
