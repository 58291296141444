import React, { useState, useEffect } from "react";
import styles from "./SubscriptionTab.module.scss";
import DateUtils from "../../../../Utils/DateUtils";
import SubscriptionList from "../../../Subscription/SubscriptionList/Index";
import { usePage } from "@inertiajs/react";
import PrivateAreaUtils from "../../../../Utils/PrivateAreaUtils";
import SubscriptionsUtils from "../../../../Utils/SubscriptionUtils";
import { useRefreshProps } from "../../../../Hooks";

export default function SubscriptionTab({ user }) {
    const { userLogged, locale } = usePage().props;
    const [userPackage, setUserPackage] = useState(null);
    const [isCancelButtonDisabled, setCancelButtonDisbled] = useState(false);
    const [subscriptionCancelErrorMessage, setSubscriptionCancelErrorMessage] = useState(null);
    const [subscriptionCancelSuccessMessage, setSubscriptionCancelSuccessMessage] = useState(null);

    const userHasSubscription = Boolean(user?.private_package_active);
    const subscriptionEndDate = userHasSubscription
        ? DateUtils.convertDateFromString(user?.private_package_active?.next_payment)
        : null;

    const userHasCanceledSubscription = !Boolean(user?.private_package_active?.is_autorenew);
    const paymentMethod = user?.private_package_active?.payment_type;

    const PAYPAL_CANCEL_URL = "https://www.paypal.com/myaccount/autopay/";

    // Ottengo le informazioni sul pacchetto corrente dell'utente, se abbonato
    useEffect(() => {
        if (userHasSubscription) {
            const getUserPackageDetails = async () => {
                if (userLogged && userLogged.private_package_active) {
                    try {
                        const activePackageInfo = await PrivateAreaUtils.subscription.get(
                            userLogged.private_package_active.private_package_id
                        );
                        setUserPackage(activePackageInfo);
                    } catch (error) {
                        setUserPackage(null);
                        console.error("Errore nel recuperare le informazioni del pacchetto: ", error);
                    }
                }
            };

            getUserPackageDetails();
        }
    }, [userLogged]);

    // console.log("userPackage", userPackage);

    // Gestisco la cancellazione dell'abbonamento corrente, se utente abbonato
    const handleSubscriptionCancel = async (subscriptionID, paymentMethod) => {
        setCancelButtonDisbled(true);
        setSubscriptionCancelErrorMessage(null);
        setSubscriptionCancelSuccessMessage(null);
        // Chiede conferma prima di procedere
        const isConfirmed = window.confirm("Sei sicuro di voler annullare il tuo abbonamento?");
        if (!isConfirmed) {
            return; // Interrompe se l'utente non conferma
        }

        // Chiamata API per cancellare l'abbonamento
        const isSubscriptionCanceled = await PrivateAreaUtils.subscription.cancel(subscriptionID, paymentMethod);

        if (isSubscriptionCanceled.result) {
            useRefreshProps(["userLogged"], 0, true);
            setSubscriptionCancelSuccessMessage(isSubscriptionCanceled.message);
        } else {
            setSubscriptionCancelErrorMessage({
                error: isSubscriptionCanceled.error,
                error_description: isSubscriptionCanceled.error_description,
            });
        }
        setCancelButtonDisbled(false);
    };

    const subscriptionValiditySpan =
        userPackage != null
            ? userPackage.day_of_validity == 30
                ? "mensile"
                : userPackage.day_of_validity == 365
                ? "annuale"
                : ""
            : "";

    const activeSubscription = (
        <>
            <h1 className="h2 mb-4">Il mio abbonamento</h1>
            {userPackage ? (
                <>
                    <p className="mt-2">
                        Ti confermiamo che il tuo{" "}
                        <strong>
                            {userPackage.title[locale]} {subscriptionValiditySpan}
                        </strong>{" "}
                        è attualmente attivo.
                        <br />
                        <br />
                    </p>
                    {userHasCanceledSubscription ? (
                        <p>Il rinnovo automatico non è attivo, il tuo abbonamento scadrà {subscriptionEndDate}.</p>
                    ) : (
                        <>
                            <p>
                                Il tuo prossimo pagamento di{" "}
                                <strong>{SubscriptionsUtils.toEuroString(userPackage.finalPrice)}</strong> per il tuo
                                piano{" "}
                                <strong>
                                    {userPackage.title[locale]} {subscriptionValiditySpan}
                                </strong>{" "}
                                verrà effettuato il giorno <strong>{subscriptionEndDate}</strong>.
                            </p>

                            {/* Bottone annullamento */}
                            {paymentMethod?.toLowerCase().includes("paypal") ? (
                                <>
                                    <p>
                                        <br />
                                        Per annullare il tuo abbonamento, accedi al tuo conto PayPal. Una volta entrato:
                                        <br />
                                        1. Vai alla sezione "Profilo" e clicca su "Pagamenti".
                                        <br />
                                        2. Seleziona "Gestisci i tuoi pagamenti automatici".
                                        <br />
                                        3. Trova l'abbonamento a ValleSabbiaNews e procedi con l'annullamento.
                                    </p>
                                    <a
                                        className="btn btn-danger mt-4"
                                        href={PAYPAL_CANCEL_URL}
                                        title="Naviga nella tua area riservata PayPal per disattivare l'abbonamento"
                                        rel="nofollow noopener"
                                        target="_blank"
                                        style={{ maxWidth: "max-content" }}
                                    >
                                        Annulla abbonamento su PayPal
                                    </a>
                                </>
                            ) : (
                                <button
                                    className="btn btn-danger mt-4"
                                    disabled={isCancelButtonDisabled}
                                    onClick={() =>
                                        handleSubscriptionCancel(
                                            user?.private_package_active?.id,
                                            user?.private_package_active?.payment_type
                                        )
                                    }
                                >
                                    Annulla l'abbonamento
                                </button>
                            )}

                            {/* Messaggio di errore */}
                            {subscriptionCancelErrorMessage && (
                                <small className="text-danger d-block mt-2">
                                    <strong className="d-block">{subscriptionCancelErrorMessage.error}</strong>
                                    {subscriptionCancelErrorMessage.error_description}
                                </small>
                            )}

                            {/* Messaggio di successo */}
                            {subscriptionCancelSuccessMessage && (
                                <small className="text-success d-block mt-2">
                                    <strong className="d-block">{subscriptionCancelSuccessMessage}</strong>
                                </small>
                            )}

                            {/* Info cancellazione */}
                            <small className="d-block mt-2">
                                Per aggiornare il tuo abbonamento, inizia annullando quello corrente, valido fino al{" "}
                                {subscriptionEndDate}, tramite il pulsante "Annulla l'abbonamento" qui sopra. Una volta
                                scaduto, puoi ritornare a questa pagina per attivare un nuovo tipo di abbonamento.
                            </small>
                        </>
                    )}
                </>
            ) : (
                <p>Carico le informazioni del tuo abbonamento...</p>
            )}
        </>
    );

    const subscribeNow = (
        <div className={styles.subscribeNow}>
            <h1 className="h2 mb-2">Il mio abbonamento</h1>
            <p>
                Non hai alcun abbonamento attivo su ValleSabbiaNews.
                <br />
                Puoi abbonarti al giornale online selezionando uno degli abbonamenti proposti qui di seguito.
            </p>
            <h2 className="h3 text-center mt-4">Scegli l’abbonamento che preferisci</h2>
            <SubscriptionList />
        </div>
    );

    return (
        <div
            className="tab-pane fade"
            id="subscriptionTab"
            role="tabpanel"
            aria-labelledby="subscriptionTab-pill"
            tabIndex="0"
        >
            {userHasSubscription ? activeSubscription : subscribeNow}
        </div>
    );
}
