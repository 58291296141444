import React, { useEffect } from "react";
import styles from "./Layout.Private.module.scss";
import Header from "../Components/Header/Index";
import Footer from "../Components/Footer/Index";
import LoginModal from "../Components/PrivateArea/LoginModal/Index";
import RegisterModal from "../Components/PrivateArea/RegisterModal/Index";
import RecoverPasswordModal from "../Components/PrivateArea/RecoverPasswordModal/Index";
import GTrack from "../Components/GTrack/Index";

const Layout = (props) => {
    const userHasSubscription = Boolean(props?.userLogged?.private_package_active);
    useEffect(() => {
        if (userHasSubscription) {
            document.body.classList.add('userHasSubscription');
        } else {
            document.body.classList.remove('userHasSubscription');
        }
        // Rimuove la classe quando il componente viene smontato
        return () => {
            document.body.classList.remove('userHasSubscription');
        };
    }, [userHasSubscription]);
    return (
        <>
            <GTrack />
            <Header />
            <div className="container mt-3" id="privateAreaWrapper">
                <div className="row">
                    <div className="col-12">
                        {props.isUserLogged ? (
                            <div className={`${styles.grid}`}>
                                <aside className={`${styles.menu} py-4 py-md-5`}>
                                    {props.menu}
                                </aside>
                                <hr className={`${styles.hr}`}></hr>
                                <main className={`${styles.main} py-4 py-md-5`}>
                                    {props.main}
                                </main>
                            </div>
                        ) : (
                            <main className={`${styles.main} py-4 py-md-5`}>
                                {props.main}
                            </main>
                        )}
                        
                    </div>
                </div>
            </div>
            <Footer />
            <LoginModal />
            <RegisterModal />
            <RecoverPasswordModal />
        </>
    );
};

export default Layout;