const FormUtils = {
    focusAndOpenKeyboard: (el, timeout) => {
        const initialScrollPosition = window.scrollY;
        if (!timeout) timeout = 100;
        if (el) {
            var __tempEl__ = document.createElement('input');
            __tempEl__.style.position = 'absolute';
            __tempEl__.style.top = window.scrollY + 'px';
            __tempEl__.style.left = -1000 + 'px';
            __tempEl__.style.height = 0;
            __tempEl__.style.opacity = 0;
            document.body.appendChild(__tempEl__);
            __tempEl__.focus();
            window.scrollTo({
                top: initialScrollPosition,
                left: 0,
                behavior: 'instant'
            });
            setTimeout(function () {
                el.focus();
                el.click();
                document.body.removeChild(__tempEl__);
            }, timeout);
        }
    }
};

export default FormUtils;  