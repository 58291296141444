import React from "react";
import { usePage } from "@inertiajs/react";
import SmartImage from "../../Images/SmartImage/Index";
import GTrackUtils from "../../../Utils/GTrackUtils";

function PartnerLink({ ...props }) {
    const { locale } = usePage().props;
    const { data } = props;

    const { shouldIncludeDataGTrack, gTrackAction } = GTrackUtils.generateGTrackAction(
        data?.custom_fields?.gTrack_click,
        data?.custom_fields?.gTrack_view
    );

    const url = data?.url?.[locale];
    const title = data?.title?.[locale] ? data?.title?.[locale] : data?.name;
    const image = data?.media1 ? data.media1?.[0] : null;

    return (
        <a
            href={url}
            title={title}
            target="_blank"
            rel="noreferrer"
            {...shouldIncludeDataGTrack && {
                'data-gtrack': true,
                'data-gtrack-id': data.id,
                'data-gtrack-type': 'core_banner',
                'data-gtrack-action': gTrackAction,
                'data-gtrack-link': url
            }}
        >
            {image &&
                <SmartImage
                    url={image.url}
                    conversions={image.conversions}
                    data={image.data}
                />
            }
        </a>
    );
}

export default PartnerLink;