import React from 'react';
import styles from './SubscriptionPaywall.module.scss';
import { usePage } from '@inertiajs/react'
import SubscriptionList from '../SubscriptionList/Index';

function SubscriptionPaywall() {

    const { userLogged } = usePage().props;

    return (
        <section className={`${styles.wrapper}`}>
            <div className={`text-center pt-4 pt-lg-5`}>
                {!userLogged && (
                    <>
                        <h2 className='h3 mb-4'>Hai già un account?</h2>
                        <button
                            className={`btn btn-primary ${styles.btnLogIn}`}
                            data-bs-toggle="modal"
                            data-bs-target="#loginModal"
                        >
                            Accedi
                        </button>
                        <p className='my-4'>oppure</p>
                    </>
                )}
                <h2 className='h3'>Scegli un abbonamento per continuare a leggere:</h2>
            </div>
            <SubscriptionList />
        </section>
    )
}

export default SubscriptionPaywall