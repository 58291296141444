import React, { useEffect, useState, useRef } from "react";
import styles from "./NexiButton.module.scss";
import SVG from "../../../Utils/SVG";
import PrivateAreaUtils from "../../../Utils/PrivateAreaUtils";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { usePage } from "@inertiajs/react";

const NexiButton = ({
	subscriptionData,
	onNexiSubscriptionSuccess,
	onNexiSubscriptionError,
	selectedAddressId,
	isInvoiceNeeded,
}) => {
	const { config, payments, locale, userLogged } = usePage().props;
	const isProduction = config.env !== "development";

	const buttonRef = useRef(null); // Ref per il bottone "Paga ora"
	const [showButton, setShowButton] = useState(false); // Se mostrare o meno il bottone

	const NEXI_ALIAS = payments.nexi.alias;

	const subscriptionExtraData = {
		private_address_id: selectedAddressId,
		is_invoice_required: isInvoiceNeeded,
	};
	const subscriptionExtraDataRef = useRef(subscriptionExtraData);

	// Carica lo script di Nexi XPay e lo rimuove quando il componente viene smontato
	useEffect(() => {
		const script = document.createElement("script");
		script.src = `https://${isProduction ? "" : "int-"}ecommerce.nexi.it/ecomm/XPayBuild/js?alias=${NEXI_ALIAS}`;
		script.async = true;
		script.onload = () => configureNexi();
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	useEffect(() => {
		subscriptionExtraDataRef.current = {
			private_address_id: selectedAddressId,
			is_invoice_required: isInvoiceNeeded,
		};
	}, [selectedAddressId, isInvoiceNeeded, subscriptionData.id]);

	// Aggiunge il listener che apre il lightbox di XPay
	useEffect(() => {
		const button = buttonRef.current;
		if (showButton && button && typeof window.XPay !== "undefined") {
			// Evento al click sul bottone
			const clickHandler = (e) => {
				e.preventDefault();
				XPay.openLightbox();
			};
			button.addEventListener("click", clickHandler);

			// Evento di risultato del pagamento
			const XPay_Payment_ResultHanlder = (e) => {
				if (e.detail.esito == "OK") {
					onNexiSubscriptionSuccess();
				} else {
					onNexiSubscriptionError("Si è verificato un errore imprevisto. Per favore, riprova più tardi.");
				}
			};
			window.addEventListener("XPay_Payment_Result", XPay_Payment_ResultHanlder);

			return () => {
				button.removeEventListener("click", clickHandler);
				window.removeEventListener("XPay_Payment_Result", XPay_Payment_ResultHanlder);
			};
		}
	}, [showButton]);

	const configureNexi = async () => {
		// Ottengo la configurazione di Nexi che devo utilizzare poi nel render del bottone "Paga ora"
		const response = await PrivateAreaUtils.subscription.create(
			subscriptionData.id,
			"nexi_subscription",
			subscriptionExtraDataRef.current
		);
		const nexiConfig = response.data;

		// Inizializzazione SDK
		XPay.init();

		// Configurazione lightbox
		XPay.initLightbox(nexiConfig);

		// Attivo il bottone "Paga ora"
		setShowButton(true);
	};

	return (
		<>
			{showButton ? (
				<button
					className={`btn btn-primary d-flex justify-content-between mt-4 w-100 ${styles.checkoutButton}`}
					ref={buttonRef}
				>
					Paga ora
					<div
						dangerouslySetInnerHTML={{
							__html: SVG.rightArrowWhite,
						}}
					/>
				</button>
			) : (
				<div className="mt-4 d-flex">
					<Skeleton count={1} containerClassName={`${styles.skeletonBtn}`} inline={true} />
				</div>
			)}
		</>
	);
};

export default NexiButton;
