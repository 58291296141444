import React from "react";
import styles from "./EventCard.module.scss";
import { Link } from "@inertiajs/react";
import SmartImage from "../../Images/SmartImage/Index";

const EventCard = ({ large, url, pretitle, title, place, image, summary }) => {
	const eventImage = image ? image[0] : null;

	return (
		<Link className={`${styles.eventCard} ${large ? styles.eventCardLarge : ""}`} href={url}>
			{large ? (
				<div className="row">
					{eventImage && (
						<div className="col-4 col-lg-2">
							<div style={{ "--aspect-ratio": "1/1.4142" }} className={``}>
								<SmartImage
									url={eventImage.url}
									conversions={eventImage.conversions}
									data={eventImage.data}
								/>
							</div>
						</div>
					)}

					<div className="col-8 col-lg-10 p-lg-0">
						<div className="pt-2">
							{/** Pretitolo */}
							{(pretitle || place) && (
								<span className="text-uppercase font-sans font-small">
									{pretitle} {place && ` - ${place}`}
								</span>
							)}

							{/** Titolo */}
							<h2 className="mt-1 pb-3 h3">{title}</h2>

							{/* Sommario */}
							{summary && <p className={`fst-italic mb-3 mt-3 ${styles.summary}`}>{summary}</p>}
						</div>
					</div>
				</div>
			) : (
				<>
					{/** Pretitolo */}
					{(pretitle || place) && (
						<span className="text-uppercase font-sans font-small">
							{pretitle} {place && ` - ${place}`}
						</span>
					)}
					{/** Titolo */}
					<h6 className="mt-1 pb-1">{title}</h6>
				</>
			)}
		</Link>
	);
};

export default EventCard;
