import React, { useState, useEffect } from "react";
import styles from './Book.module.scss';
import { usePage } from "@inertiajs/react";
import SmartImage from "../Images/SmartImage/Index";
import SVG from "../../Utils/SVG";
import SubscriptionsUtils from "../../Utils/SubscriptionUtils";
import GTrackUtils from "../../Utils/GTrackUtils";

function Book({ ...props }) {
    const { locale } = usePage().props;
    const { data } = props;

    const { shouldIncludeDataGTrack, gTrackAction } = GTrackUtils.generateGTrackAction(
        props.data?.custom_fields?.gTrack_click,
        props.data?.custom_fields?.gTrack_view
    );

    const title = data.title?.[locale] || data.name;
    const author = data.summary?.[locale] || null;
    const abstract = data.content?.[locale] || null;
    const isbn = data.custom_fields?.isbn || null;
    const year = data.custom_fields?.anno_pubblicazione || null;
    const price = data.custom_fields?.price || null;
    const image = data.media1?.[0];
    const url = data.url?.[locale];

    // Gestione lunghezza abstract
    const abstractBreakpoints = {
        xxs: 180,   // per dispositivi extra extra small
        xs: 260,    // per dispositivi extra small
        sm: 340,    // per dispositivi small
        md: 260,    // per dispositivi medium
        lg: 450,    // per dispositivi large
        xl: 500     // per dispositivi extra large
    };
    const [isAbstractExpanded, setIsAbstractExpanded] = useState(false);
    const [maxAbstractLength, setMaxAbstractLength] = useState(abstractBreakpoints.md);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 419) {
                setMaxAbstractLength(abstractBreakpoints.xxs);
            } else if (window.innerWidth < 576) { 
                setMaxAbstractLength(abstractBreakpoints.xs);
            } else if (window.innerWidth < 768) {
                setMaxAbstractLength(abstractBreakpoints.sm);
            } else if (window.innerWidth < 992) {
                setMaxAbstractLength(abstractBreakpoints.md);
            } else if (window.innerWidth < 1200) {
                setMaxAbstractLength(abstractBreakpoints.lg);
            } else {
                setMaxAbstractLength(abstractBreakpoints.xl);
            }
        };

        // Chiamata per il set del valore iniziale
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleAbstract = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsAbstractExpanded(!isAbstractExpanded);
    };

    const truncateAbstract = (text, maxLength) => {
        if (!text) return;
        if (text?.length <= maxLength) return text;
        return text.substr(0, text.lastIndexOf(' ', maxLength)) + '...';
    };

    const displayAbstract = isAbstractExpanded ? abstract : truncateAbstract(abstract, maxAbstractLength);

    return (
        <li
            {...shouldIncludeDataGTrack && {
                'data-gtrack': true,
                'data-gtrack-id': props.data.id,
                'data-gtrack-type': 'core_banner',
                'data-gtrack-action': gTrackAction,
                'data-gtrack-link': url
            }}
        >
            <a href={url} title={title} className={styles.card} target="_blank">

                {/** Immagine */}
                <div style={{'--aspect-ratio': '2/3'}} className={`${styles.imgWrapper}`}>
                    {
                        image && 
                        <SmartImage
                            url={image.url}
                            conversions={image.conversions}
                            data={image.data}
                        />
                    }
                </div>

                {/** Spaziatore */}
                <div></div>

                {/** Titolo + Abstract */}
                <div className={`${styles.info}`}>
                    <h3 className="h4">{title}</h3>
                    {abstract && 
                        <div className={`mt-2 ${styles.abstractWrapper}`}>
                            <p className={`font-sans ${styles.abstract}`} dangerouslySetInnerHTML={{ __html: displayAbstract }} />
                            {abstract.length > maxAbstractLength && (
                                <button onClick={(event) => toggleAbstract(event)} className={styles.readMoreButton}>
                                    {isAbstractExpanded ? 'Leggi di meno' : 'Leggi di più'}
                                </button>
                            )}
                        </div>
                    }
                </div>

                {/** Spaziatore */}
                <div></div>

                {/** Autore + Anno di pubblicazione + ISBN + Acquista ora */}
                <div className={`${styles.moreInfo} font-sans`}>
                    
                    {author &&
                        <div>
                            <span>Autore</span>
                            <span>{author}</span>
                        </div>
                    }

                    {year &&
                        <div>
                            <span>Anno di pubblicazione</span>
                            <span>{year}</span>
                        </div>
                    }

                    {isbn && 
                        <div>
                            <span>ISBN</span>
                            <span>{isbn}</span>
                        </div>
                    }

                    {price && 
                        <div>
                            <span>Prezzo</span>
                            <span>{SubscriptionsUtils.toEuroString(price)}</span>
                        </div>
                    }
                    <button
                        title="Acquista ora"
                        className="w-100 btn btn-primary d-flex justify-content-between"
                    >
                        Acquista ora
                        <div
                            className="svgWrapperAlt"
                            dangerouslySetInnerHTML={{ __html: SVG.rightArrowWhite }}
                        />
                    </button>
                </div>
            </a>
        </li>
    );
}

export default Book;
