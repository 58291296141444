import React from "react";
import "./bootstrap";
import { createInertiaApp } from "@inertiajs/react";
import { hydrateRoot } from "react-dom/client";
import "../scss/app.scss";
import { router } from "@inertiajs/react";

createInertiaApp({
    resolve: (name) => {
        // console.log("Template:", name);
        const pages = import.meta.glob("./Pages/**/*.jsx", { eager: true });
        return pages[`./Pages/${name}.jsx`];
    },
    setup({ el, App, props }) {
        hydrateRoot(el, <App {...props} />);
    },
    progress: {
        color: "#ff9900",
        showSpinner: true,
    },
});

// Google Analytics 4: al cambio pagina ri-lancia la configurazione
// come se fosse effettivamente una nuova page view
router.on("navigate", (event) => {
    gtag("js", new Date());
    gtag("config", "G-KZMGGFPPHG");
});
