import React from "react";
import styles from "./NewsBreaking.module.scss";
import Marquee from "react-fast-marquee";
import { usePage } from "@inertiajs/react";

const NewsBreaking = ({ ...props }) => {
	const { locale, components } = usePage().props;

	let totalNewsCount = 0;
	components?.headerBreakingNews?.forEach((news) => {
		news?.newsTags?.forEach((breakingNews) => {
			totalNewsCount += breakingNews.news.length;
		});
	});

	if (totalNewsCount === 0) return;

	return (
		<>
			{components?.headerBreakingNews?.map((news, index) => (
				<React.Fragment key={`headerBreakingNews-${index}`}>
					<section className={`container ${styles.wrapper} ${props.wrapperCSSClasses}`}>
						<div className={`${styles.title}`}>
							<strong className="font-sans text-white">{news?.component["title_" + locale]}</strong>
						</div>

						<div className={`${styles.newsText}`}>
							<Marquee
								pauseOnHover={false}
								pauseOnClick={true}
								gradient={true}
								gradientWidth={64}
								gradientColor={[245, 245, 245]}
							>
								{news?.newsTags?.map((breakingNews, index2) => (
									<React.Fragment key={`breakingNews-${index2}`}>
										{breakingNews.news.map((newsData, index3) => {
											let htmlContent = newsData?.content[locale];
											if (htmlContent) {
												let paragraphMatches = [...htmlContent?.matchAll(/<p>(.*?)<\/p>/g)];

												let firstNonEmptyParagraph = "";
												for (let match of paragraphMatches) {
													if (match?.[1]?.trim() !== "") {
														firstNonEmptyParagraph = match[1];
														break;
													}
												}

												let firstPeriodIndex = firstNonEmptyParagraph.indexOf(".");
												let truncatedText =
													firstPeriodIndex !== -1
														? firstNonEmptyParagraph.slice(0, firstPeriodIndex + 1)
														: firstNonEmptyParagraph;

												return (
													<React.Fragment key={`breakingNews-${index2}-${index3}`}>
														<div className={styles.innerWrapper}>
															<strong>
																{newsData?.title[locale]
																	? newsData?.title[locale]
																	: newsData?.name}
															</strong>
															&nbsp;-&nbsp;
															<div
																className="d-inline-block"
																dangerouslySetInnerHTML={{
																	__html: `${truncatedText}&nbsp;`,
																}}
															/>
														</div>
														{totalNewsCount > 1 && <div className="mx-2">•</div>}
													</React.Fragment>
												);
											} else {
												return (
													<React.Fragment key={`breakingNews-${index2}-${index3}`}>
														<div className={styles.innerWrapper}>
															<strong>
																{newsData?.title[locale]
																	? newsData?.title[locale]
																	: newsData?.name}
															</strong>
														</div>
														{totalNewsCount > 1 && <div className="mx-2">•</div>}
													</React.Fragment>
												);
											}
										})}
									</React.Fragment>
								))}
							</Marquee>
						</div>
					</section>
				</React.Fragment>
			))}
		</>
	);
};

export default NewsBreaking;
