import React, { useRef, useEffect, useState } from "react";
import styles from "./LoginModal.module.scss";
import Form from "../../Form/Index";
import CookieUtils from "../../../Utils/CookieUtils";
import { router, usePage } from "@inertiajs/react";

const SUBSCRIPTION_STATUS = {
    NONE: "none",
    EXPIRED: "expired",
    ACTIVE: "active",
};

const LoginModal = () => {
    const [Bootstrap, setBootstrap] = useState(null);
    const [subscriptionStatus, setSubscriptionStatus] = useState(SUBSCRIPTION_STATUS.NONE);
    const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
    const { modulesRoutes, locale } = usePage().props;

    const [resetForm, setResetForm] = useState(() => () => {});

    const handleResetForm = () => {
        resetForm();
    };

    useEffect(() => {
        import("bootstrap")
            .then((bootstrap) => {
                setBootstrap(bootstrap);
            })
            .catch((error) => {
                console.error("Errore durante l'importazione di bootstrap", error);
            });
    }, []);

    const LoginModalRef = useRef();

    const handleNoSubscriptionButtonClick = () => {
        if (Bootstrap) {
            const loginModalElement = LoginModalRef.current;
            const loginModal = Bootstrap.Modal.getOrCreateInstance(loginModalElement);
            if (loginModal) {
                loginModal.hide();
            }
        }

        // Resetto il form
        setTimeout(function () {
            handleResetForm();
        }, 300);

        // Redirect dell'utente verso il subscription tab
        router.visit(`${modulesRoutes.private[locale]}?activeTab=subscriptionTab`);
        //window.location.href = `${modulesRoutes.private[locale]}?activeTab=subscriptionTab`;
    };

    return (
        <div
            ref={LoginModalRef}
            className="modal fade"
            id="loginModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="loginModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    {/** Header */}
                    <div className="modal-header">
                        <div>
                            <h1 className="modal-title fs-5" id="loginModalLabel">
                                Accedi a Valle Sabbia News
                            </h1>
                            <p className="font-sans mt-2">
                                Non hai un account Valle Sabbia News?{" "}
                                <a
                                    href="#"
                                    className="text-primary fw-bold"
                                    data-bs-toggle="modal"
                                    data-bs-target="#registerModal"
                                >
                                    Registrati ora
                                </a>
                                .
                            </p>
                        </div>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Chiudi"
                        ></button>
                    </div>

                    {/** Body */}
                    <div className="modal-body">
                        <Form
                            apiUrl={"/api/auth/login"}
                            refreshDelay={0}
                            stateReset={false}
                            onResetStateSetter={setResetForm}
                            submissionSuccessMessage={"Accesso effettuato correttamente."}
                            fieldsHTML={
                                <>
                                    <div className="form-group">
                                        <label htmlFor="username" className="mb-1">
                                            E-Mail
                                        </label>
                                        <input
                                            type="text"
                                            name="email"
                                            className="form-control"
                                            placeholder="Inserisci qui la tua mail"
                                            inputMode="email"
                                        />
                                    </div>

                                    <div className="form-group mt-3">
                                        <label htmlFor="password" className="mb-1">
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            name="password"
                                            className="form-control"
                                            placeholder="Inserisci qui la tua password"
                                        />
                                    </div>

                                    <div className="w-100 d-flex justify-content-end">
                                        <button
                                            type="button"
                                            className="fw-bold mt-2 font-sans recoverPasswordButton"
                                            data-bs-toggle="modal"
                                            data-bs-target="#recoverPasswordModal"
                                        >
                                            Hai dimenticato la password?
                                        </button>
                                    </div>

                                    <input type="submit" className="btn btn-primary mt-4" value="Accedi" />
                                </>
                            }
                            onSuccess={(response, resetStates) => {
                                setIsSubmitSuccessful(true);
                                const user = response.user;
                                // console.log("user", user);
                                let shouldCloseModal = false;
                                if (!user.private_package_active) {
                                    // console.log("Nessun abbonamento attivo");
                                    setSubscriptionStatus(SUBSCRIPTION_STATUS.NONE);
                                } else {
                                    // console.log("Almeno una volta c'era un abbonamento o è ancora attivo");
                                    const nextPaymentDate = new Date(user.private_package_active.next_payment);
                                    if (nextPaymentDate < new Date()) {
                                        // console.log("Abbonamento scaduto");
                                        setSubscriptionStatus(SUBSCRIPTION_STATUS.EXPIRED);
                                    } else {
                                        // console.log("Abbonamento attivo");
                                        setSubscriptionStatus(SUBSCRIPTION_STATUS.ACTIVE);
                                        shouldCloseModal = true;
                                    }
                                }
                                // console.log("subscriptionStatus", subscriptionStatus);
                                if (shouldCloseModal) {
                                    // console.log("Chiudo modale perché abbonamento attivo");
                                    if (Bootstrap) {
                                        const loginModalElement = LoginModalRef.current;
                                        const loginModal = Bootstrap.Modal.getOrCreateInstance(loginModalElement);
                                        if (loginModal) {
                                            // Chiudo il modale
                                            setTimeout(() => {
                                                loginModal.hide();
                                                // Resetto la form
                                                setTimeout(() => {
                                                    resetStates();
                                                }, 500);
                                            }, 2000);
                                        }
                                    }
                                } else {
                                    // console.log("Non dovrei far resettare la form, vediamo se riesco");
                                }

                                // Salva il token utente in un cookie
                                const userToken = response.token;
                                const deviceIdentifier = response.device_identifier;
                                CookieUtils.setCookie("token", userToken, 30);
                                CookieUtils.setCookie("device", deviceIdentifier, 30);
                            }}
                        />

                        {/* Messaggio errore se nessun abbonamento o abbonamento scaduto */}
                        {isSubmitSuccessful && subscriptionStatus !== SUBSCRIPTION_STATUS.ACTIVE && (
                            <div className={styles.subscriptionStatusMessage}>
                                <p className="mt-2">Non hai nessun abbonamento attivo al momento.</p>
                                {subscriptionStatus !== "L’abbonamento è attivo" && (
                                    <button
                                        className="btn btn-primary mt-2 mx-auto"
                                        onClick={handleNoSubscriptionButtonClick}
                                        title={"Aggiungi o rinnova l'abbonamento"}
                                    >
                                        Aggiungi o rinnova l'abbonamento
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginModal;
