import React, { useState } from "react";
//import styles from './AudioPlayer.module.scss';
import H5AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const AudioPlayer = ({ file }) => {
	return (
		<div className={`font-sans`}>
			<H5AudioPlayer src={file} />
		</div>
	);
};

export default AudioPlayer;
