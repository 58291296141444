const ImageUtils = {
    prepareImagesForPhotoswipe: async (imagesList) => {
        const promisesList = [];
        imagesList.forEach((element) => {
            const promise = new Promise(function (resolve) {
                let image = new Image();
                image.src = element.getAttribute('href') || element.getAttribute('data-pswp-src');
                image.onload = () => {
                    element.dataset.pswpWidth = image.width;
                    element.dataset.pswpHeight = image.height;
                    resolve();
                }
                image.onerror = () => { resolve(); };
            });
            promisesList.push(promise);
        });
        await Promise.all(promisesList);
    }
};
    
export default ImageUtils;