import React, { useMemo, useState, useEffect } from "react";
import styles from "./CommentSection.module.scss";
import CommentItem from "../CommentItem/Index";
import CommentForm from "../CommentForm/Index";
import { usePage } from "@inertiajs/react";
import PrivateAreaUtils from "../../../Utils/PrivateAreaUtils";

function createCommentTree(comments) {
    const map = {};
    comments.forEach((comment) => {
        if (!map[comment.parent_id]) map[comment.parent_id] = [];
        map[comment.parent_id].push(comment);
    });

    function buildTree(parentId) {
        return (map[parentId] || []).map((comment) => ({
            ...comment,
            replies: buildTree(comment.id),
        }));
    }

    return buildTree(null); // Commenti top-level hanno padre null
}

function CommentSection({ comments, user }) {
    const { userLogged } = usePage().props;
    const [canComment, setCanComment] = useState(false);
    const commentTree = useMemo(() => createCommentTree(comments), [comments]);
    const [replyingTo, setReplyingTo] = useState(null);

    useEffect(() => {
        const checkMagazineCommentPermission = async () => {
            if (userLogged && userLogged.private_package_active) {
                try {
                    const activePackageInfo = await PrivateAreaUtils.subscription.get(
                        userLogged.private_package_active.private_package_id
                    );
                    //console.log('activePackageInfo', activePackageInfo)
                    if (activePackageInfo?.configuration?.MagazineComment == "1") {
                        setCanComment(true);
                    }
                } catch (error) {
                    console.error("Errore nel recuperare le informazioni del pacchetto: ", error);
                }
            }
        };

        checkMagazineCommentPermission();
    }, [userLogged]);

    const handleReplyClick = (commentID) => {
        setReplyingTo(commentID);
    };

    return (
        <div className={styles.wrapper}>
            <h5 className="h3 mb-3">Commenti:</h5>
            {userLogged ? (
                <>
                    {commentTree.map((comment) => (
                        <CommentItem
                            key={`CommentItem-${comment.id}`}
                            comment={comment}
                            user={user}
                            level={0}
                            onReplyClick={handleReplyClick}
                            replyingTo={replyingTo}
                            canComment={canComment}
                        />
                    ))}
                    {replyingTo === null && canComment && (
                        <>
                            <h6 className="h4 mt-3">Aggiungi un commento:</h6>
                            <CommentForm user={user} parentID={null} formID={"mainForm"} />
                        </>
                    )}
                    {!canComment && (
                        <small className="fst-italic">
                            Con il tuo abbonamento standard non puoi inserire nuovi commenti.
                        </small>
                    )}
                </>
            ) : (
                <div>
                    <p>
                        Per favore{" "}
                        <a
                            data-bs-toggle="modal"
                            data-bs-target="#loginModal"
                            style={{ color: "var(--bs-primary)", textDecoration: "underline" }}
                        >
                            effettua l'accesso
                        </a>{" "}
                        o{" "}
                        <a
                            data-bs-toggle="modal"
                            data-bs-target="#registerModal"
                            style={{ color: "var(--bs-primary)", textDecoration: "underline" }}
                        >
                            registrati
                        </a>{" "}
                        per vedere o scrivere i commenti.
                    </p>
                </div>
            )}
        </div>
    );
}

export default CommentSection;
