import React, { useState } from "react";
import styles from "./NecrologiForm.module.scss";

function NecrologiForm({ onFormSubmit }) {
    const [nome, setNome] = useState("");
    const [luogo, setLuogo] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        onFormSubmit({ nome, luogo });
    };

    return (
        <form className={`row g-2 g-xl-3`} onSubmit={handleSubmit}>
            {/* Nome */}
            <div className="col-lg-4">
                <div className="form-floating">
                    <input
                        className={`form-control ${styles.formControl}`}
                        id="formNecrologiNome"
                        type="text"
                        placeholder="Nome"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                    />
                    <label htmlFor="formNecrologiNome">Nome</label>
                </div>
            </div>

            {/* Luogo */}
            <div className="col-lg-4">
                <div className="form-floating">
                    <input
                        className={`form-control ${styles.formControl}`}
                        id="formNecrologiLuogo"
                        type="text"
                        placeholder="Luogo"
                        value={luogo}
                        onChange={(e) => setLuogo(e.target.value)}
                    />
                    <label htmlFor="formNecrologiLuogo">Luogo</label>
                </div>
            </div>

            {/* Submit */}
            <div className="col-lg-4">
                <button className={`btn btn-primary ${styles.buttonControl}`}>Cerca</button>
            </div>
        </form>
    );
}

export default NecrologiForm;
