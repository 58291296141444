import React, { useEffect, useState } from "react";
import styles from "./Video.module.scss";

function ShortcodeVideo({ ...props }) {
    const videoUrl = props.data?.images?.[0]?.url;

    console.log('ShortcodeVideo props', videoUrl);

    return (
        <div className="raw-html-embed mt-3">
            <video controls autoPlay>
                {videoUrl ? (
                    <source src={videoUrl} type="video/mp4" />
                ) : (
                    <p>Video non disponibile</p>
                )}
            </video>
        </div>
    );
}

export default ShortcodeVideo;
