import React, { useEffect, useState } from "react";
import Swiper, { Navigation } from "swiper";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/dist/photoswipe.css";
import "swiper/swiper-bundle.css";
import styles from "./Gallery.module.scss";
import SVG from "../../../../Utils/SVG";
import SmartImage from "../../../Images/SmartImage/Index";

function ShortcodeGallery({ ...props }) {
    const images = props.data.images;
    const [swiper, setSwiper] = useState(null);
    //const galleryID = "gallery-" + Math.random().toString(36).substring(2, 15) + "-" + Date.now().toString(36);
    const galleryID = props.galleryID;
    useEffect(() => {
        if (swiper) {
            //console.log("Swiper già attivo", swiper);
            //swiper.update();
        } else {
            const newSwiper = new Swiper(".swiper-" + galleryID, {
                modules: [Navigation],
                slidesPerView: 1,
                spaceBetween: 0,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            });
            setSwiper(newSwiper);
        }
    }, [swiper]);

    useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
            gallery: "#" + galleryID,
            children: "a",
            showHideAnimationType: "fade",
            pswpModule: () => import("photoswipe"),
        });
        lightbox.init();

        return () => {
            lightbox.destroy();
            lightbox = null;
        };
    }, []);

    return (
        <div className={`swiper swiper-${galleryID}`} id={galleryID}>
            <ul className="swiper-wrapper" itemScope itemType="http://schema.org/ImageGallery">
                {images.map((image, index) => (
                    <li
                        key={`${galleryID}-slide-${index}`}
                        className="swiper-slide"
                        itemProp="associatedMedia"
                        itemScope
                        itemType="http://schema.org/ImageObject"
                    >
                        <a
                            className="d-block position-relative"
                            itemProp="contentUrl"
                            href={image.url}
                            data-pswp-width={image.width}
                            data-pswp-height={image.height}
                            data-cropped="true"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div style={{ "--aspect-ratio": "16/9" }}>
                                <img
                                    className={styles.img}
                                    src={image.url}
                                    loading="lazy"
                                    alt={image?.data?.alt?.[0] || undefined}
                                    title={image?.data?.title?.[0] || undefined}
                                    itemProp="thumbnail"
                                />
                            </div>
                            {image?.data?.title && (
                                <div className={`${styles.imgDescriptionWrapper}`}>
                                    <label itemProp="name" className="fst-italic">
                                        {image.data.title[0]}
                                        {image.data.copyright?.[0]?.trim() && ` - ${image.data.copyright[0]}`}
                                    </label>
                                </div>
                            )}
                        </a>
                    </li>
                ))}
            </ul>

            <div
                className={`swiper-button-prev swiperButtonPrev`}
                dangerouslySetInnerHTML={{ __html: SVG.leftSwiperArrow }}
            ></div>
            <div
                className={`swiper-button-next swiperButtonNext`}
                dangerouslySetInnerHTML={{ __html: SVG.rightSwiperArrow }}
            ></div>
        </div>
    );
}

export default ShortcodeGallery;
