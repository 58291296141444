import React, { useRef, useEffect } from "react";
import styles from "./CommentForm.module.scss";
import { usePage } from "@inertiajs/react";
import FormUtils from "../../../Utils/FormUtils";
import Form from "../../Form/Index";

function CommentForm({ user, replyTo, parentID, formID, autoFocus }) {
	const titleInputRef = useRef(null);

	const { config } = usePage().props;

	const defaultActiveCommentStatus = config.conf_modules.magazine.comment.defaultActiveCommentStatus;

	const additionalProps = defaultActiveCommentStatus
		? { refreshDelay: 0, stateResetDelay: 2000 }
		: { stateReset: false };

	// Auto-focus del primo campo della form non appena clicchi su "Rispondi"
	useEffect(() => {
		if (autoFocus && titleInputRef.current) {
			FormUtils.focusAndOpenKeyboard(titleInputRef.current);
		}
	}, [autoFocus]);

	return (
		<Form
			{...additionalProps}
			apiUrl={"/api/news/comments/add"}
			extraProps={{
				className: `${styles.formWrapper} ${replyTo ? styles.replyForm : "mt-3"}`,
			}}
			fieldsHTML={
				<>
					<input type="hidden" name="magazineNewsID" value={usePage().props?.magazineNews.id} />
					<input type="hidden" name="privateUserID" value={usePage().props?.userLogged.id} />
					<input type="hidden" name="locale" value={usePage().props?.locale} />
					<input type="hidden" name="parentID" value={parentID} />

					{/* Titolo */}
					<div className="col-lg-12 font-sans">
						<label
							htmlFor={`CommentForm-${formID}-titolo`}
							className={`form-label font-small text-uppercase ${replyTo ? "mb-0" : ""}`}
						>
							Titolo <i className="text-danger">*</i>
						</label>
						<input
							type="text"
							className="form-control"
							id={`CommentForm-${formID}-titolo`}
							name="title"
							placeholder="Inserisci qui il titolo del tuo commento"
							ref={titleInputRef}
							required
						/>
					</div>

					{/* Contenuto */}
					<div className={`col-12 font-sans mt-2 ${replyTo ? "" : "mt-lg-3"}`}>
						<label
							htmlFor={`CommentForm-${formID}-content`}
							className={`form-label font-small text-uppercase ${replyTo ? "mb-0" : ""}`}
						>
							Commento <i className="text-danger">*</i>
						</label>
						<textarea
							className="form-control"
							id={`CommentForm-${formID}-content`}
							rows={3}
							name="content"
							required
							placeholder={
								replyTo
									? "Inserisci qui il testo della tua risposta"
									: "Inserisci qui il testo del tuo commento"
							}
						></textarea>
					</div>

					{/* Firma */}
					<div className={`col-12 font-sans mt-2 ${replyTo ? "" : "mt-lg-3"}`}>
						<label
							htmlFor={`CommentForm-${formID}-firma`}
							className={`form-label font-small text-uppercase ${replyTo ? "mb-0" : ""}`}
						>
							Firma
						</label>
						<input
							type="text"
							className="form-control"
							id={`CommentForm-${formID}-firma`}
							name="firma"
							placeholder="Inserisci qui la tua firma"
							defaultValue={
								usePage().props.userLogged
									? usePage().props.userLogged?.name + " " + usePage().props.userLogged?.surname
									: ""
							}
						/>
					</div>

					<div className="col-lg-12 font-sans mt-3">
						<input
							type="submit"
							className={`${styles.btnSubmit} btn-primary btn`}
							value={replyTo ? "Rispondi al commento" : "Commenta ora"}
						/>
					</div>
				</>
			}
		/>
	);
}

export default CommentForm;
