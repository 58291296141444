import React from "react";
import NecrologiCard from "../NecrologiCard/Index";
import styles from "./NecrologiList.module.scss";
import Skeleton from "react-loading-skeleton";

const NecrologiList = ({ filteredObituaries, isFormSubmitting }) => {
    return (
        <>
            <ul className={`${styles.list} mt-3`}>
                {filteredObituaries?.length > 0 ? (
                    filteredObituaries.map((necrologio) => (
                        <NecrologiCard key={`NecrologiCard-${necrologio.id}`} ndata={necrologio} />
                    ))
                ) : isFormSubmitting ? (
                    <li className={`py-3 py-sm-4 ${styles.skeletonWrapper}`}>
                        <Skeleton count={3} />
                    </li>
                ) : (
                    <li className="py-3 py-sm-4">Nessun risultato trovato.</li>
                )}
            </ul>

            {/*
            <button className={`${styles.loadList} btn btn-tertiary mt-3 mt-lg-4`}>
                Carica altri
            </button>
            */}
        </>
    );
};

export default NecrologiList;
