import React, { useEffect, useState } from "react";
import styles from "./Media.module.scss";

function ShortcodeMedia({ ...props }) {
    const videoUrl = props.data?.images?.[0]?.url;

    console.log('ShortcodeMedia props', videoUrl);
    return <span>Media</span>;
}

export default ShortcodeMedia;
