import React, { useState, useEffect, forwardRef } from "react";
import styles from "./UserAddress.module.scss";
import { usePage } from "@inertiajs/react";
import Form from "../../../Form/Index";
import PrivateAreaUtils from "../../../../Utils/PrivateAreaUtils";

const UserAddressModal = forwardRef(({ address, action, extraBackdrop, onUpdateAddresses }, ref) => {
    const { userLogged, locale, modulesRoutes } = usePage().props;

    // Bootstrap
    const [Bootstrap, setBootstrap] = useState(null);

    // Importa bootstrap asincronamente
    useEffect(() => {
        import("bootstrap")
            .then((bootstrap) => {
                setBootstrap(bootstrap);
            })
            .catch((error) => {
                console.error("Errore durante l'importazione di bootstrap", error);
            });
    }, []);

    const uniqueId = `${new Date().getTime()}_${Math.random().toString(36).substr(2, 9)}`;

    // Recupero città, province e regioni
    const [regions, setRegions] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [cities, setCities] = useState([]);

    const [selectedRegion, setSelectedRegion] = useState("");
    const [selectedProvince, setSelectedProvince] = useState("");
    const [selectedCity, setSelectedCity] = useState("");

    const [isCompany, setIsCompany] = useState(false);
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [company, setCompany] = useState("");
    const [piva, setPiva] = useState("");
    const [codDest, setCodDest] = useState("");
    const [emailPec, setEmailPec] = useState("");
    const [codFisc, setCodFisc] = useState("");
    const [addressInput, setAddressInput] = useState("");
    const [note, setNote] = useState("");

    // Carica i dati iniziali delle regioni
    useEffect(() => {
        const fetchData = async () => {
            try {
                const regionsData = await PrivateAreaUtils.address.getRegionsByCountryId(
                    101 // Italia
                );
                setRegions(regionsData);

                // Preseleziona la regione se action è 'edit'
                if (action === "edit" && address.core_region_id) {
                    setSelectedRegion(address.core_region_id);
                }
            } catch (error) {
                console.error("Errore nel caricamento delle regioni:", error);
            }
        };

        fetchData();
    }, [action, address]);

    //
    useEffect(() => {
        if (action === "edit" && address) {
            // Valori di default per la modalità "edit"
            setIsCompany(address.is_company || false);
            setName(address.name || "");
            setSurname(address.surname || "");
            setEmail(address.email || "");
            setTelephone(address.telephone || "");
            setCompany(address.company || "");
            setPiva(address.piva || "");
            setCodDest(address.cod_dest || "");
            setEmailPec(address.email_pec || "");
            setCodFisc(address.codfisc || "");
            setAddressInput(address.address || "");
            setNote(address.note || "");
        } else if (action === "add") {
            // Resetta i valori per la modalità "add"
            setSelectedRegion("");
            setSelectedProvince("");
            setSelectedCity("");
            setIsCompany(false);
            setName("");
            setSurname("");
            setEmail("");
            setTelephone("");
            setPiva("");
            setCodDest("");
            setEmailPec("");
            setCodFisc("");
            setAddressInput("");
            setNote("");
        }
    }, [action, address]);

    // Carica le province quando viene selezionata una regione
    useEffect(() => {
        const fetchProvinces = async () => {
            try {
                const provincesData = await PrivateAreaUtils.address.getProvincesByRegionId(selectedRegion);
                setProvinces(provincesData);

                if (action === "edit" && address.core_province_id) {
                    setSelectedProvince(address.core_province_id);
                }
            } catch (error) {
                console.error("Errore nel caricamento delle province:", error);
            }
        };

        if (selectedRegion) {
            fetchProvinces();
        }
    }, [selectedRegion, action, address]);

    // Carica le città quando viene selezionata una provincia
    useEffect(() => {
        const fetchCities = async () => {
            try {
                const citiesData = await PrivateAreaUtils.address.getCitiesByProvinceId(selectedProvince);
                setCities(citiesData);
                // console.log("cities", citiesData);

                if (action === "edit" && address.core_city_id) {
                    setSelectedCity(address.core_city_id);
                }
            } catch (error) {
                console.error("Errore nel caricamento delle città:", error);
            }
        };

        if (selectedProvince) {
            fetchCities();
        }
    }, [selectedProvince, action, address]);

    // Gestione del titolo del modale
    let modalTitle = "Indirizzo utente";
    let modalAction = "";

    if (action === "edit") {
        modalTitle = "Modifica indirizzo";
        modalAction = "Modifica indirizzo";
    } else if (action === "add") {
        modalTitle = "Aggiungi un nuovo indirizzo";
        modalAction = "Aggiungi indirizzo";
    }

    // Validazione input Codice SDI ed Email PED, ne basta uno dei due
    const companyDataValidation = (formData, formElements) => {
        const errors = {};

        let codDest = formData.get("cod_dest");
        let emailPec = formData.get("email_pec");

        // Assumendo che tu abbia accesso allo stato `isCompany`, `codDest`, e `emailPec` qui
        if (isCompany) {
            // Validazione per Codice Univoco (SDI)
            if (!codDest && !emailPec) {
                errors.cod_dest = "Il codice SDI o l’indirizzo PEC è richiesto.";
                errors.email_pec = "Il codice SDI o l’indirizzo PEC è richiesto.";
            }
        }

        return errors;
    };

    return (
        <div
            className={`modal fade ${extraBackdrop ? "extraBackdrop" : ""}`}
            tabIndex="-1"
            aria-hidden="true"
            ref={ref}
        >
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className={`${styles.header} modal-header`}>
                        <h1 className="modal-title fs-5" id="recoverPasswordModalLabel">
                            {modalTitle}
                        </h1>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Chiudi"
                            data-bs-dismiss="modal"
                        ></button>
                    </div>

                    <div className={`${styles.body} modal-body`}>
                        <Form
                            apiUrl={`${
                                action === "edit"
                                    ? `/api/private/address/${address.id}/update`
                                    : `/api/private/address/store`
                            }`}
                            method={`${action === "edit" ? `POST` : `PUT`}`}
                            refreshDelay={0}
                            stateReset={true}
                            stateResetDelay={2000}
                            customValidation={companyDataValidation}
                            onSuccess={(response, resetStates) => {
                                // console.log("Submit con successo");
                                onUpdateAddresses();
                                if (Bootstrap) {
                                    const modal = ref.current;
                                    const userAddressModal = Bootstrap.Modal.getOrCreateInstance(modal);
                                    if (userAddressModal) {
                                        setTimeout(function () {
                                            userAddressModal.hide();
                                        }, 1800);
                                    }
                                }
                            }}
                            fieldsHTML={
                                <>
                                    {/** User ID */}
                                    <input type="hidden" name="private_user_id" value={userLogged.id} />
                                    {/** Is default */}
                                    <input type="hidden" name="is_default" value={1} />
                                    {/** Country */}
                                    <input type="hidden" name="core_country_id" value={101} />
                                    {/** CAP */}
                                    <input
                                        type="hidden"
                                        name="cap"
                                        value={cities?.find((city) => city.id == selectedCity)?.cap || ""}
                                    />

                                    {/** Privato o Azienda is_company */}
                                    <div className="row">
                                        <div className="col-sm-6 col-lg-3 d-flex align-items-center">
                                            <label className={`font-sans fw-bold`}>Tipologia:</label>
                                        </div>
                                        <div className="mt-1 mt-sm-0 col-sm-6 col-lg-9">
                                            <div className="d-flex">
                                                <div className="form-check">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        id={`userAddressModalPrivato_${uniqueId}`}
                                                        autocomplete="off"
                                                        name="is_company"
                                                        value={0}
                                                        checked={!isCompany}
                                                        onChange={() => setIsCompany(false)}
                                                    />
                                                    <label
                                                        htmlFor={`userAddressModalPrivato_${uniqueId}`}
                                                        className="form-check-label"
                                                    >
                                                        Privato
                                                    </label>
                                                </div>
                                                <div className="form-check ms-3">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        id={`userAddressModalAzienda_${uniqueId}`}
                                                        autocomplete="off"
                                                        name="is_company"
                                                        value={1}
                                                        checked={isCompany}
                                                        onChange={() => setIsCompany(true)}
                                                    />
                                                    <label
                                                        htmlFor={`userAddressModalAzienda_${uniqueId}`}
                                                        className="form-check-label"
                                                    >
                                                        Azienda
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/** Nome e cognome */}
                                    <div className="row mt-2">
                                        <div className="form-group col-md-6">
                                            <label htmlFor={`userAddressModalNome_${uniqueId}`} className="mb-1">
                                                Nome <i className="text-danger">*</i>
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                placeholder="Nome"
                                                id={`userAddressModalNome_${uniqueId}`}
                                                required
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group col-md-6 mt-3 mt-md-0">
                                            <label htmlFor={`userAddressModalCognome_${uniqueId}`} className="mb-1">
                                                Cognome <i className="text-danger">*</i>
                                            </label>
                                            <input
                                                type="text"
                                                name="surname"
                                                className="form-control"
                                                placeholder="Cognome"
                                                id={`userAddressModalCognome_${uniqueId}`}
                                                required
                                                value={surname}
                                                onChange={(e) => setSurname(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    {/** E-Mail e Telefono */}
                                    <div className="row mt-3">
                                        <div className="form-group col-md-6">
                                            <label htmlFor={`userAddressModalMail_${uniqueId}`} className="mb-1">
                                                E-Mail <i className="text-danger">*</i>
                                            </label>
                                            <input
                                                type="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="E-Mail"
                                                id={`userAddressModalMail_${uniqueId}`}
                                                required
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group col-md-6 mt-3 mt-md-0">
                                            <label htmlFor={`userAddressModalTelefono_${uniqueId}`} className="mb-1">
                                                Telefono
                                            </label>
                                            <input
                                                type="tel"
                                                name="telephone"
                                                className="form-control"
                                                placeholder="Telefono"
                                                id={`userAddressModalTelefono_${uniqueId}`}
                                                value={telephone}
                                                onChange={(e) => setTelephone(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    {/** Ragione sociale - Partita IVA - Codice univoco (SDI) - Email PEC */}
                                    {isCompany == true && (
                                        <div className="row mt-3">
                                            <div className="form-group col-md-6 col-lg-3">
                                                <label
                                                    htmlFor={`userAddressModalRagioneSociale_${uniqueId}`}
                                                    className="mb-1"
                                                >
                                                    Ragione sociale {isCompany && <i className="text-danger">*</i>}
                                                </label>
                                                <input
                                                    type="text"
                                                    name="company"
                                                    className="form-control"
                                                    placeholder="Ragione sociale"
                                                    id={`userAddressModalRagioneSociale_${uniqueId}`}
                                                    required={isCompany}
                                                    value={company}
                                                    onChange={(e) => setCompany(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group col-md-6 col-lg-3 mt-3 mt-md-0">
                                                <label htmlFor={`userAddressModalIVA_${uniqueId}`} className="mb-1">
                                                    Partita IVA {isCompany && <i className="text-danger">*</i>}
                                                </label>
                                                <input
                                                    type="text"
                                                    name="piva"
                                                    className="form-control"
                                                    placeholder="Partita IVA"
                                                    id={`userAddressModalIVA_${uniqueId}`}
                                                    required={isCompany}
                                                    value={piva}
                                                    onChange={(e) => setPiva(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group col-md-6 col-lg-3 mt-3 mt-lg-0">
                                                <label htmlFor={`userAddressModalSDI_${uniqueId}`} className="mb-1">
                                                    Codice univoco (SDI){" "}
                                                    {isCompany && <i className="text-danger">**</i>}
                                                </label>
                                                <input
                                                    type="text"
                                                    name="cod_dest"
                                                    className="form-control"
                                                    placeholder="Codice univoco (SDI)"
                                                    id={`userAddressModalSDI_${uniqueId}`}
                                                    value={codDest}
                                                    onChange={(e) => setCodDest(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group col-md-6 col-lg-3 mt-3 mt-lg-0">
                                                <label htmlFor={`userAddressModalPEC_${uniqueId}`} className="mb-1">
                                                    E-Mail PEC {isCompany && <i className="text-danger">**</i>}
                                                </label>
                                                <input
                                                    type="email"
                                                    name="email_pec"
                                                    className="form-control"
                                                    placeholder="E-Mail PEC"
                                                    id={`userAddressModalPEC_${uniqueId}`}
                                                    value={emailPec}
                                                    onChange={(e) => setEmailPec(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {/** Codice fiscale */}
                                    <div className="form-group mt-3">
                                        <label htmlFor={`userAddressModalCodFisc_${uniqueId}`} className="mb-1">
                                            Codice Fiscale <i className="text-danger">*</i>
                                        </label>
                                        <input
                                            type="text"
                                            name="codfisc"
                                            className="form-control"
                                            placeholder="Codice Fiscale"
                                            id={`userAddressModalCodFisc_${uniqueId}`}
                                            required
                                            value={codFisc}
                                            onChange={(e) => setCodFisc(e.target.value)}
                                        />
                                    </div>
                                    {/** Indirizzo */}
                                    <div className="form-group mt-3">
                                        <label htmlFor={`userAddressModalIndirizzo_${uniqueId}`} className="mb-1">
                                            Indirizzo <i className="text-danger">*</i>
                                        </label>
                                        <input
                                            type="text"
                                            name="address"
                                            className="form-control"
                                            placeholder="Indirizzo"
                                            id={`userAddressModalIndirizzo_${uniqueId}`}
                                            required
                                            inputMode="address"
                                            value={addressInput}
                                            onChange={(e) => setAddressInput(e.target.value)}
                                        />
                                    </div>
                                    {/** Regione - Provincia - Città */}
                                    <div className="row mt-3">
                                        {/* Select regione */}
                                        <div className="form-group col-md-6 col-lg-4">
                                            <label htmlFor={`userAddressModalRegion_${uniqueId}`} className="mb-1">
                                                Regione
                                            </label>
                                            <select
                                                id={`userAddressModalRegion_${uniqueId}`}
                                                className="form-select"
                                                name="core_region_id"
                                                value={selectedRegion}
                                                onChange={(e) => setSelectedRegion(e.target.value)}
                                            >
                                                <option value="">Seleziona una regione</option>
                                                {regions.map((region) => (
                                                    <option key={region.id} value={region.id}>
                                                        {region.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {/* Select provincia */}
                                        <div className="form-group col-md-6 col-lg-4 mt-3 mt-md-0">
                                            <label htmlFor={`userAddressModalProvince_${uniqueId}`} className="mb-1">
                                                Provincia
                                            </label>
                                            <select
                                                id={`userAddressModalProvince_${uniqueId}`}
                                                className="form-select"
                                                value={selectedProvince}
                                                onChange={(e) => setSelectedProvince(e.target.value)}
                                                disabled={!selectedRegion}
                                                name="core_province_id"
                                            >
                                                <option value="">Seleziona una provincia</option>
                                                {provinces.map((province) => (
                                                    <option key={province.id} value={province.id}>
                                                        {province.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {/* Select città */}
                                        <div className="form-group col-lg-4 mt-3 mt-lg-0">
                                            <label htmlFor={`userAddressModalCity_${uniqueId}`} className="mb-1">
                                                Città
                                            </label>
                                            <select
                                                id={`userAddressModalCity_${uniqueId}`}
                                                className="form-select"
                                                name="core_city_id"
                                                value={selectedCity}
                                                onChange={(e) => setSelectedCity(e.target.value)}
                                                disabled={!selectedProvince}
                                            >
                                                <option value="">Seleziona una città</option>
                                                {cities.map((city) => (
                                                    <option key={city.id} value={city.id}>
                                                        {city.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    {/** Note */}
                                    <div className="form-group mt-3">
                                        <label htmlFor={`userAddressModalNote_${uniqueId}`} className="mb-1">
                                            Note
                                        </label>
                                        <textarea
                                            name="note"
                                            className="form-control"
                                            placeholder="Note"
                                            id={`userAddressModalNote_${uniqueId}`}
                                            rows={3}
                                            value={note}
                                            onChange={(e) => setNote(e.target.value)}
                                        />
                                    </div>
                                    <input type="submit" className="btn btn-primary mt-4" value={modalAction} />
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default UserAddressModal;
