import SVG from "./SVG";

const ShareUtils = {
    getCurrentUrl: () => typeof window !== 'undefined' ? window.location.href : '',
    get shareLinks() {
        const currentUrl = this.getCurrentUrl();
        return [
            {
                icon: SVG.facebookRoundIconWhite,
                href: `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`,
                title: "Condividi su Facebook",
            },
            {
                icon: SVG.whatsappIconWhite,
                href: `https://api.whatsapp.com/send?text=${currentUrl}`,
                title: "Condividi su Whatsapp",
            },
            {
                icon: SVG.telegramRoundIconWhite,
                href: `https://t.me/share/url?url=${currentUrl}`,
                title: "Condividi su Telegram",
            },
            {
                icon: SVG.xIconWhite,
                href: `https://twitter.com/intent/tweet?url=${currentUrl}`,
                title: "Condividi su Twitter",
            },
            {
                icon: SVG.tumblrIconWhite,
                href: `https://www.tumblr.com/share/link?url=${currentUrl}`,
                title: "Condividi su Tumblr",
            },
        ];
    },
};

export default ShareUtils;
