import React from "react";
import HeadSEO from "../../Components/Head/Index";
//import styles from "./ErrorPage.module.scss";
import Layout from "../../Layout/Generico";
import { Link } from "@inertiajs/react";
import SEOUtils from "../../Utils/SEOUtils";

function ErrorPage(props) {
	const { page, locale, error_message, ziggy } = props;
	/*const title = {
        503: "503: Service Unavailable",
        500: "500: Server Error",
        404: "404: Page Not Found",
        403: "403: Forbidden",
    }[status];

    const description = {
        503: "Sorry, we are doing some maintenance. Please check back soon.",
        500: "Whoops, something went wrong on our servers.",
        404: "Sorry, the page you are looking for could not be found.",
        403: "Sorry, you are forbidden from accessing this page.",
    }[status];*/

	const main = (
		<>
			<h1>Impossibile trovare la pagina richiesta</h1>

			<p className="mt-2">
				{error_message
					? error_message
					: "La pagina che stai cercando non esiste o è stata rimossa. Ti invitiamo a tornare alla homepage."}
			</p>

			<div className="row mt-3">
				<div className="col-sm-5 col-md-4 col-xl-3">
					<Link href="/" className="btn btn-primary">
						Torna alla home
					</Link>
				</div>
			</div>
		</>
	);

	const seoData = SEOUtils.generateSEOData({
		page: page,
		locale: locale,
		type: "default",
		ziggy: ziggy,
		otherProps: {},
	});

	return (
		<>
			<HeadSEO data={seoData} />
			<Layout {...props} main={main} />
		</>
	);
}

export default ErrorPage;
