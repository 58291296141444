import React from 'react';

import PersonalDataTab from './PersonalDataTab/Index';
import SubscriptionTab from './SubscriptionTab/Index';
import DevicesTab from './DevicesTab/Index';
import PaymentsTab from './PaymentsTab/Index';

export default function UserTabContent({ user }) {
    return (
        <div className="tab-content" id="pills-tabContent">
            <PersonalDataTab user={user} />
            <SubscriptionTab user={user} />
            <DevicesTab user={user} />
            <PaymentsTab user={user} />
        </div>
    );
}