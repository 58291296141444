import React, { useEffect, useState, useRef } from "react";
import styles from "./Timeline.module.scss";
import Layout from "../../Layout/FullWidth";
import HeadSEO from "../../Components/Head/Index";
import axios from "axios";
import { componentFactory } from "../../Utils/ComponentsFactory";
import ComponentsUtils from "../../Utils/ComponentsUtils";
import DateUtils from "../../Utils/DateUtils";
import NewsCard from "../../Components/News/NewsCard/Index";
import SEOUtils from "../../Utils/SEOUtils";

function Timeline(props) {
	const { page, locale, ziggy, components, coreBannersAllPage } = props;
	const [articles, setArticles] = useState([]);
	const [cachedArticles, setCachedArticles] = useState({});
	const [currentDate, setCurrentDate] = useState(new Date());
	const [loading, setLoading] = useState(false);
	const source = axios.CancelToken.source();

	const componentMap = componentFactory();
	const zones = ["popup"];
	const FINAL_COMPONENTS = ComponentsUtils.getComponents(components, coreBannersAllPage, zones);

	const containerRef = useRef(null);

	useEffect(() => {
		const centerContent = () => {
			const container = containerRef.current;
			if (container) {
				container.scrollLeft = (container.scrollWidth - container.clientWidth) / 2;
			}
		};
		centerContent();
	}, []);

	useEffect(() => {
		setLoading(true);
		// Utilizza le notizie in cache se disponibili, altrimenti effettua la chiamata API
		if (cachedArticles[currentDate]) {
			setArticles(cachedArticles[currentDate]);
			setLoading(false);
		} else {
			const endDate = formatDate(currentDate);
			const startDate = formatDate(new Date(currentDate - 6 * 24 * 60 * 60 * 1000));

			axios
				.post(
					"/api/news/filter-dates/get",
					{
						dateFrom: startDate,
						dateTo: endDate,
					},
					{
						cancelToken: source.token,
					}
				)
				.then((response) => {
					setLoading(false);
					if (response.data.result === true) {
						setArticles(response.data.data);

						// Aggiorna la cache
						setCachedArticles((prevCache) => ({
							...prevCache,
							[currentDate]: response.data.data,
						}));
					} else {
						setArticles([]);
					}
				})
				.catch((error) => {
					if (axios.isCancel(error)) {
						console.log("Richiesta annullata", error.message);
					} else {
						console.error("Errore durante il caricamento degli articoli:", error);
					}
					setLoading(false);
				});
		}

		// Pulizia
		return () => {
			source.cancel("Componente smontato: chiamata annullata");
		};
	}, [currentDate, cachedArticles]);

	const formatDate = (date) => {
		return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(
			2,
			"0"
		)}`;
	};

	const getDateRange = () => {
		const dates = [];
		for (let i = 6; i >= 0; i--) {
			const date = new Date(currentDate - i * 24 * 60 * 60 * 1000);
			dates.push(formatDate(date));
		}
		return dates;
	};

	const updateDate = (offset, unit) => {
		const newDate = new Date(currentDate);
		switch (unit) {
			case "day":
				newDate.setDate(currentDate.getDate() + offset);
				break;
			case "week":
				newDate.setDate(currentDate.getDate() + offset * 7);
				break;
			case "month":
				newDate.setMonth(currentDate.getMonth() + offset);
				break;
			default:
				break;
		}
		setCurrentDate(newDate);
	};

	const groupArticlesByDate = () => {
		const grouped = {};
		articles.forEach((article) => {
			const date = article.publish_datetime.split("T")[0];
			if (!grouped[date]) {
				grouped[date] = [];
			}
			grouped[date].push(article);
		});
		return grouped;
	};

	const groupedArticles = groupArticlesByDate();
	const dateRange = getDateRange();

	const main = (
		<div className={styles.TimelineContainer} ref={containerRef}>
			<div className={`container-fluid font-sans ${styles.TimelineWrapper}`}>
				<div>
					<div className={styles.TimelineNavigation}>
						<div>Visualizzazione: settimana</div>
						<ul>
							<li>
								<button onClick={() => updateDate(-1, "day")}>1 giorno prima</button>
							</li>
							<li>
								<button onClick={() => updateDate(-1, "week")}>1 settimana prima</button>
							</li>
							<li>
								<button onClick={() => updateDate(-1, "month")}>1 mese prima</button>
							</li>
							<li>
								<button onClick={() => setCurrentDate(new Date())}>OGGI</button>
							</li>
							<li>
								<button onClick={() => updateDate(1, "month")}>1 mese dopo</button>
							</li>
							<li>
								<button onClick={() => updateDate(1, "week")}>1 settimana dopo</button>
							</li>
							<li>
								<button onClick={() => updateDate(1, "day")}>1 giorno dopo</button>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div className={`container-fluid ${styles.TimelineBodyWrapper}`}>
				<div className={`row ${styles.TimelineDays}`}>
					<div className={`col-12 ${styles.TimelineContent}`}>
						{dateRange.map((date, index) => (
							<div key={`DateRange-DayColumn-${index}`} className={styles.dayColumn}>
								<h4 className={`font-sans text-center ${styles.dateHeader}`}>
									{DateUtils.convertDateFromString(date)}
								</h4>
							</div>
						))}
					</div>
				</div>
				<div className={`row ${styles.TimelineContent}`}>
					<div className={`col-12 ${styles.TimelineContent}`}>
						{dateRange.map((date, index) => (
							<div
								key={`DateRange-NewsColumn-${index}`}
								className={`${styles.dayColumn} ${styles.newsColumn}`}
							>
								{loading ? (
									<div className={styles.loadingIndicator}>Caricamento in corso...</div>
								) : (
									<>
										{groupedArticles[date]?.map((news, newsIndex) => (
											<React.Fragment key={`GroupedArticles-${news.id}`}>
												<NewsCard data={news} timeline={true} />
												{newsIndex < groupedArticles[date].length - 1 && (
													<hr className={`${styles.separatore}`} />
												)}
											</React.Fragment>
										))}
										{!groupedArticles[date]?.length && (
											<div className={styles.noNews}>Nessuna notizia per questa data.</div>
										)}
									</>
								)}
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);

	const seoData = SEOUtils.generateSEOData({
		page: page,
		locale: locale,
		type: "default",
		ziggy: ziggy,
		otherProps: {
			// Altre props
		},
	});

	return (
		<>
			<HeadSEO data={seoData} />
			<Layout {...props} main={main} />
			{/** Componenti popup */}
			{ComponentsUtils.renderPopupComponents(FINAL_COMPONENTS.popup, componentMap)}
		</>
	);
}

export default Timeline;
