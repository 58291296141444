import React from "react";
import { usePage } from "@inertiajs/react";
import GTrackUtils from "../../Utils/GTrackUtils";

const BannerOnlyContent = ({ ...props }) => {
    const { locale } = usePage().props;

    const { shouldIncludeDataGTrack, gTrackAction } = GTrackUtils.generateGTrackAction(
        props.data?.custom_fields?.gTrack_click,
        props.data?.custom_fields?.gTrack_view
    );

    return (
        <div
            className="bannerOnlyContent"
            {...shouldIncludeDataGTrack && {
                'data-gtrack': true,
                'data-gtrack-id': props.data.id,
                'data-gtrack-type': 'core_banner',
                'data-gtrack-action': gTrackAction
            }}
            dangerouslySetInnerHTML={{ __html: props?.data?.content?.[locale] }}
        />
    );
};

export default BannerOnlyContent;