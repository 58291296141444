import React, { useState, useEffect } from "react";
import styles from "./DevicesTab.module.scss";

import SVG from "../../../../Utils/SVG";
import PrivateAreaUtils from "../../../../Utils/PrivateAreaUtils";
import CookieUtils from "../../../../Utils/CookieUtils";

export default function DevicesTab({ user }) {
	const currentDeviceIdentifier = CookieUtils.getCookie("device");

	const [isLoading, setIsLoading] = useState(true);
	const [userDevices, setUserDevices] = useState([]);

	useEffect(() => {
		const loadUserDevices = async () => {
			setIsLoading(true);
			const userDevices = await PrivateAreaUtils.device.getAll();
			setIsLoading(false);
			if (userDevices.length > 0) {
				setUserDevices(userDevices);
			}
		};

		loadUserDevices();
	}, []);

	const deviceIcon = (type) => {
		switch (type) {
			case "DESKTOP":
				return SVG.paDesktop;
			case "TABLET":
				return SVG.paTablet;
			case "MOBILE":
				return SVG.paMobile;
			default:
				return "";
		}
	};

	const getOperatingSystem = (userAgent) => {
		if (userAgent.includes("CrOS")) {
			return "ChromeOS";
		} else if (userAgent.includes("Android")) {
			return "Android";
		} else if (userAgent.includes("iPhone")) {
			return "iPhone";
		} else if (userAgent.includes("iPad")) {
			return "iPad";
		} else if (userAgent.includes("Macintosh") || userAgent.includes("Mac OS")) {
			return "Mac";
		} else if (userAgent.includes("Windows")) {
			return "Windows";
		} else {
			return "Sconosciuto";
		}
	};

	const removeDevice = async (deviceId) => {
		// Il vero identifier esclude la presenza di eventuali "DESKTOP-" / "MOBILE-"
		//const actualIdentifier = deviceId.split('-').pop();

		// Chiede conferma prima di procedere
		const isConfirmed = window.confirm("Sei sicuro di voler rimuovere questo dispositivo?");
		if (!isConfirmed) {
			return; // Interrompe se l'utente non conferma
		}

		try {
			// Esegue la chiamata API per rimuovere il dispositivo
			const isDeviceRemoved = await PrivateAreaUtils.device.remove(deviceId);
			if (isDeviceRemoved) {
				// Rimuove il dispositivo dallo stato se la rimozione è andata a buon fine
				setUserDevices((prevDevices) => prevDevices.filter((device) => device.identifier !== deviceId));
			} else {
				console.error("Errore durante la rimozione del dispositivo");
			}
		} catch (error) {
			console.error("Errore API: ", error);
		}
	};

	return (
		<div className="tab-pane fade" id="devicesTab" role="tabpanel" aria-labelledby="devicesTab-pill" tabIndex="0">
			<h1 className="h2 mb-4">I miei dispositivi</h1>
			<p className="mt-2">
				In questa sezione puoi avere una panoramica completa di tutti i dispositivi con cui hai effettuato
				l'accesso a ValleSabbiaNews.it.
				<br />
				Qui potrai monitorare le tue sessioni attive e verificare l'ultimo accesso effettuato per ciascun
				dispositivo.
				<br />
				Se desideri liberare spazio per nuovi accessi o garantire ulteriormente la sicurezza del tuo account,
				puoi facilmente rimuovere qualsiasi dispositivo dalla lista.
			</p>

			{/* Elenco dispositivi */}
			{isLoading ? (
				<p className="mt-4">Caricamento dei dispositivi in corso...</p>
			) : userDevices.length === 0 ? (
				<p className="mt-4">Non ci sono dispostivi attivi al momento.</p>
			) : (
				<ul className={`mt-4 ${styles.devicesList}`}>
					{userDevices.map((device, index) => (
						<li key={index}>
							<div
								className={styles.iconDevice}
								dangerouslySetInnerHTML={{ __html: deviceIcon(device.type) }}
							/>

							<div className={styles.infoDevice}>
								<span className={styles.pretitle}>{device.type}</span>
								<h3 className="h5">{getOperatingSystem(device.userAgent)}</h3>

								<div className={`${styles.actionsWrapper} font-sans`}>
									<span>
										<div dangerouslySetInnerHTML={{ __html: SVG.paTime }} />
										Ultimo accesso: {device.date} alle ore {device.time}
									</span>

									{device.identifier !== currentDeviceIdentifier && currentDeviceIdentifier != "" && (
										<>
											<span>&middot;</span>
											<button
												className={`text-danger`}
												onClick={() => removeDevice(device.identifier)}
											>
												<div dangerouslySetInnerHTML={{ __html: SVG.xDanger }} />
												Rimuovi dispositivo
											</button>
										</>
									)}

									{device.identifier == currentDeviceIdentifier && (
										<>
											<span>&middot;</span>
											<span className="text-success">Dispositivo corrente</span>
										</>
									)}
								</div>
							</div>
						</li>
					))}
				</ul>
			)}
		</div>
	);
}
