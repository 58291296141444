import React from "react";
import styles from "./EventsCalendar.module.scss";
import FullCalendar from '@fullcalendar/react';
import itLocale from '@fullcalendar/core/locales/it';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import EventsUtils from "../../../Utils/EventsUtils";
import { router, usePage } from "@inertiajs/react";

const EventsCalendar = ({ ...props }) => {
    const { locale, modulesRoutes } = usePage().props;
    const wrapperCSSClasses = props.data.wrapperCSSClasses;

    let events = [];
    if (props.data.data) {
        events = EventsUtils.transformEvents(props.data.data, locale);
    } else if (props.data.components) {
        const calendarComponent = props.data.components.find(component => component.item_type === 'calendar' && component.events);
        if (calendarComponent) {
            events = calendarComponent.events;
        }
    }

    const daysWithEvents = events.length > 0 ? EventsUtils.getEventDates(events) : [];
    
    let title;
    if (props.title) {
        title = props.title;
    } else if (props.data && props.data.component && props.data.component[`title_${locale}`]) {
        title = props.data.component[`title_${locale}`];
    } else if (props.data && props.data.title) {
        title = props.data.title[locale];
    }

    return (
        <section className={`${styles.wrapper} ${wrapperCSSClasses}`}>
            {title && <h5 className={`font-sans text-uppercase pb-3`}>{title}</h5>}
            <div className="mt-3 font-sans">
                <FullCalendar
                    locale={itLocale}
                    plugins={[ dayGridPlugin, interactionPlugin ]}
                    initialView="dayGridMonth"
                    events={daysWithEvents}
                    contentHeight={'auto'}
                    headerToolbar={{
                        start: 'title',
                        center: '',
                        end: 'prev,next'
                    }}
                    dayHeaderContent={(arg) => {
                        return arg.text.slice(0, 1).toUpperCase()
                    }}
                    eventDisplay={'list-item'}
                    dateClick={(info) => {
                        router.visit(`${modulesRoutes?.event[locale]}`, {
                            method: 'get',
                            // Viene passata la data (intesa come start_date) nel formato YYYY-MM-DD
                            data: {
                                date: info.dateStr
                            },
                            preserveScroll: false
                        })
                    }}
                />
            </div>
        </section>
    );
};

export default EventsCalendar;