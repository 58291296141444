import React, { useState, useEffect, useRef } from "react";
import { usePage } from "@inertiajs/react";
import { useRefreshProps } from "../../Hooks";

function Form({
    fieldsHTML,
    apiUrl,
    method = "POST",
    onSuccess,
    stateReset = true,
    refreshPageProps = true,
    refreshDelay = 7000,
    stateResetDelay = 7000,
    customValidation = () => ({}),
    extraProps = {},
    submissionSuccessMessage = null,
    autoSubmit = false,
    onResetStateSetter,
}) {
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const csrf_token = usePage().props.csrf_token;
    const formRef = useRef();

    const resetStates = () => {
        setErrors({});
        setSuccessMessage(null);
        setIsSubmitted(false);
        setIsSubmitting(false);
    };

    useEffect(() => {
        if (typeof onResetStateSetter === "function") {
            onResetStateSetter(() => resetStates);
        }
    }, [onResetStateSetter]);

    useEffect(() => {
        // console.log("autoSubmit", autoSubmit);
        if (autoSubmit) {
            handleSubmit(new Event("submit"));
        }
    }, [autoSubmit]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // console.log("Chiamo handleSubmit()");

        setIsSubmitting(true); // Il form si sta submittando
        setErrors({}); // Svuota errori precedenti

        if (!validate()) {
            // console.log("Validazione fallita:", errors);
            setIsSubmitting(false); // Non è più in submit
            return;
        }

        const formElement = formRef.current;
        const formData = new FormData(formElement);

        try {
            // console.log("Invio questi dati:", formData);

            const options = {
                method: method === "PUT" ? "POST" : method,
                headers: {
                    "X-CSRF-TOKEN": csrf_token,
                    Accept: "application/json",
                },
            };

            let finalApiUrl = apiUrl;

            if (method === "PUT") {
                formData.append("_method", "PUT");
            }

            if (method === "POST" || method === "PUT") {
                options.body = formData;
            } else {
                const cleanParams = {};
                formData.forEach((value, key) => {
                    if (value && value !== "") {
                        cleanParams[key] = value;
                    }
                });

                const queryParams = new URLSearchParams(cleanParams).toString();
                finalApiUrl = `${apiUrl}?${queryParams}`;
            }

            const response = await fetch(finalApiUrl, options);
            const responseBody = await response.json();
            // console.log("Risposta dal server:", responseBody);

            if (responseBody?.result === true) {
                setIsSubmitted(true);
                setSuccessMessage(submissionSuccessMessage || responseBody.message);
                if (onSuccess) onSuccess(responseBody, resetStates);
            } else {
                // Se c'è un errore
                let newErrors = {
                    apiError: "Qualcosa è andato storto, riprova.",
                };
                if (responseBody.message) newErrors.message = responseBody.message;
                setErrors((prev) => ({ ...prev, ...newErrors }));
            }

            setIsSubmitting(false);
        } catch (error) {
            const errorMsg = "Qualcosa è andato storto, riprova. Errore: " + error;
            console.error(errorMsg);
            setErrors((prev) => ({ ...prev, networkError: errorMsg }));
            setIsSubmitting(false);
        }
    };

    const validate = () => {
        let newErrors = {}; // Svuota gli errori pre-esistenti

        const formElements = formRef.current.elements;

        for (let i = 0; i < formElements.length; i++) {
            const element = formElements[i];
            const { name, value, type, required } = element;

            if (!name) continue;

            if (required && value.trim() === "") {
                newErrors[name] = `Il campo è richiesto.`;
                continue;
            }

            switch (type) {
                case "email":
                    if (value && !/\S+@\S+\.\S+/.test(value)) {
                        newErrors[name] = "Indirizzo email non valido.";
                    }
                    break;

                // Qui altri casi di validazione

                default:
                    break;
            }
        }

        if (customValidation && typeof customValidation === "function") {
            const formElement = formRef.current;
            const formData = new FormData(formElement);
            const customErrors = customValidation(formData, formElement.elements);
            if (customErrors && typeof customErrors === "object") {
                newErrors = { ...newErrors, ...customErrors };
            }
        }

        setErrors(newErrors);
        // console.log("Errori di validazione:", newErrors);

        return Object.keys(newErrors).length === 0;
    };

    useEffect(() => {
        if (isSubmitted === true) {
            /*console.log(
                "Inzio la fase di aggiornamento. refreshPageProps: " + refreshPageProps + ". stateReset: " + stateReset
            );*/
            if (refreshPageProps) useRefreshProps("*", refreshDelay, true);
            if (stateReset) setTimeout(resetStates, stateResetDelay);
        }
    }, [isSubmitted]);

    const renderFieldsWithErrors = (children) => {
        return React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) {
                return child;
            }

            let className = child.props.className || "";
            const errorMessage = errors[child.props.name];

            if (errorMessage) {
                className += " is-invalid";
            }

            const props = { ...child.props, className };

            // Controllo per disabilitare sia il button che l'input type="submit"
            if (
                (child.type === "button" && child.props.type === "submit") ||
                (child.type === "input" && child.props.type === "submit")
            ) {
                props.disabled = isSubmitting; // Disabilita se il form è in fase di invio
            }

            if (child.props.children) {
                props.children = renderFieldsWithErrors(child.props.children);
            }

            return errorMessage ? (
                <React.Fragment>
                    {React.cloneElement(child, props)}
                    <div className="invalid-feedback">{errorMessage}</div>
                </React.Fragment>
            ) : (
                React.cloneElement(child, props)
            );
        });
    };

    return (
        <>
            {!isSubmitted ? (
                <>
                    {Object.keys(errors).length > 0 && (
                        <div className="errorMessages mb-2">
                            {[...new Set(Object.values(errors))].map((errorMessage, index) => (
                                <p key={`FormError-${index}`} className="error text-danger">
                                    {errorMessage}
                                </p>
                            ))}
                        </div>
                    )}
                    <form ref={formRef} onSubmit={(e) => handleSubmit(e)} {...extraProps}>
                        {renderFieldsWithErrors(fieldsHTML.props.children)}
                    </form>
                </>
            ) : (
                <div className="text-success">
                    {successMessage ? (
                        <p dangerouslySetInnerHTML={{ __html: successMessage }} />
                    ) : (
                        "Form inviato con successo"
                    )}
                </div>
            )}
        </>
    );
}

export default Form;
