import React, { useState, useEffect, useRef } from "react";
import SVG from "../../../Utils/SVG";
import logoValleSabbiaNewsLavoro from "./../../../../img/logo_work.jpg";

const CACHE_DURATION = 60 * 60 * 1000 * 0.5; // Durata della cache in millisecondi (0.5 ore, 30 min)

const ValleSabbiaNewsLavoro = () => {
    const intervalRef = useRef(null);
    const [lavoroData, setLavoroData] = useState(null);
    const [currentJobIndex, setCurrentJobIndex] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            // Controlla prima nel localStorage
            const cache = localStorage.getItem("lavoroDataCache");
            const now = new Date().getTime();

            let data;
            if (cache) {
                const { lastFetch, data: cachedData } = JSON.parse(cache);
                if (now - lastFetch < CACHE_DURATION && cachedData.length > 0) {
                    data = cachedData; // Usa i dati della cache se sono ancora validi
                }
            }

            if (!data) {
                try {
                    const response = await fetch("https://lavoro.vallesabbianews.it/share_api.php?function=lastWork");
                    const jsonData = await response.json();
                    data = jsonData.data.estates;

                    // Salva i dati nel localStorage con timestamp
                    localStorage.setItem("lavoroDataCache", JSON.stringify({ data, lastFetch: now }));
                } catch (error) {
                    console.error("Errore nella richiesta dei dati:", error);
                    // Usa i dati della cache se disponibili e validi
                    if (cache) {
                        data = JSON.parse(cache).data;
                    }
                }
            }

            setLavoroData(data);
        };

        fetchData();
    }, []);

    useEffect(() => {
        const handleInterval = () => {
            setCurrentJobIndex((prev) => (prev + 1) % (lavoroData ? lavoroData.length : 1));
        };

        intervalRef.current = setInterval(handleInterval, 5000);

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [lavoroData]);

    const handleMouseEnter = () => {
        clearInterval(intervalRef.current);
    };

    const handleMouseLeave = () => {
        intervalRef.current = setInterval(() => {
            setCurrentJobIndex((prev) => (prev + 1) % (lavoroData ? lavoroData.length : 1));
        }, 5000);
    };

    const convertCSSStringToObject = (cssString) => {
        return cssString
            .split(";")
            .filter((style) => style.trim() !== "")
            .map((style) => style.split(":"))
            .reduce((styles, keyValue) => {
                const key = keyValue[0].trim().replace(/-([a-z])/g, (match) => match[1].toUpperCase());
                styles[key] = keyValue[1].trim();
                return styles;
            }, {});
    };

    return (
        <section className="vsnCasaLavoro">
            <a href="https://lavoro.vallesabbianews.it/" target="_blank" aria-label="ValleSabbiaNews Annunci di Lavoro">
                <img className="img-fluid w-100" src={logoValleSabbiaNewsLavoro} alt="Logo ValleSabbiaNews Lavoro" />
            </a>
            <hr />

            {lavoroData &&
                lavoroData.map((job, index) => (
                    <div
                        key={index}
                        style={{ display: index === currentJobIndex ? "block" : "none" }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <label
                            className="lavoro font-sans text-uppercase font-small px-2 py-1"
                            style={convertCSSStringToObject(job.offer_style)}
                        >
                            {job.offer_name}
                        </label>
                        <div>
                            <h6 className="h5 my-2">{job.title}</h6>
                            <ul className="font-sans">
                                {job.description && (
                                    <li>
                                        <p
                                            className="fw-regular jobDescription"
                                            dangerouslySetInnerHTML={{ __html: job.description }}
                                        />
                                    </li>
                                )}
                                {job.paese && (
                                    <li className="mt-3">
                                        {job.paese_label}: <strong dangerouslySetInnerHTML={{ __html: job.paese }} />
                                    </li>
                                )}
                                {job.mansione && (
                                    <li className="mt-2">
                                        {job.mansione_label}{" "}
                                        <strong dangerouslySetInnerHTML={{ __html: job.mansione }} />
                                    </li>
                                )}
                                {job.tipologia && (
                                    <li className="mt-2">
                                        {job.tipologia_label}{" "}
                                        <strong dangerouslySetInnerHTML={{ __html: job.tipologia }} />
                                    </li>
                                )}
                                {job.orari && (
                                    <li className="mt-2">
                                        {job.orari_label} <strong dangerouslySetInnerHTML={{ __html: job.orari }} />
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                ))}

            <a
                href="https://lavoro.vallesabbianews.it/"
                target="_blank"
                aria-label="Scopri tutti gli annunci di lavoro"
                title="Scopri tutti gli annunci di lavoro"
                className="btn btn-primary d-flex justify-content-between mt-3"
            >
                Scopri tutti gli annunci
                <div className="svgWrapperAlt" dangerouslySetInnerHTML={{ __html: SVG.rightArrowWhite }} />
            </a>
        </section>
    );
};

export default ValleSabbiaNewsLavoro;
