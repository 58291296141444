const SubscriptionsUtils = {
    toEuroString: (amount) => {
        const numberAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
        if (isNaN(numberAmount)) {
            return 'Importo non valido';
        }

        return numberAmount.toLocaleString('it-IT', {
            style: 'currency',
            currency: 'EUR',
        });
    }
};

export default SubscriptionsUtils;