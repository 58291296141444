import React, { useState, useEffect } from "react";
import styles from "./NewsTopTen.module.scss";
import { usePage } from "@inertiajs/react";
import NewsCard from "../../News/NewsCard/Index";

const NewsTopTen = ({ ...props }) => {
    const { locale } = usePage().props;

    const [news, setNews] = useState(props.data.data.filter((n) => !n.exclude_from_components));

    const requiredNewsCount = props.data.component.nr_item_show;

    const fetchAdditionalNews = async (offset, limit, maxIterations, iteration = 0) => {
        // console.log('fetchAdditionalNews()')
        if (iteration >= maxIterations) {
            console.warn("Numero massimo di iterazioni raggiunto");
            return;
        }

        try {
            const response = await fetch(`/api/news/get/${offset}/${limit}/DESC`);
            const data = await response.json();

            if (data.result === true) {
                const news = data.data;
                const validNews = news.filter((n) => !n.exclude_from_components);
                // console.log('validNews', validNews)
                setNews((prevNews) => [...prevNews, ...validNews]);

                // Verifico se ho ancora bisogno di news e se ho ricevuto almeno tante news quante ne ho indicato come limite
                if (validNews.length < limit && news.length >= limit) {
                    await fetchAdditionalNews(offset + limit, limit, maxIterations, iteration + 1);
                }
            }
        } catch (error) {
            console.error("Errore durante il fetch delle notizie:", error);
        }
    };

    useEffect(() => {
        if (requiredNewsCount && news.length < requiredNewsCount) {
            const offset = news.length;
            let limit = requiredNewsCount - news.length;
            // Aumenta il limite per compensare le notizie escluse
            limit = Math.min(limit * 2, 10); // Rimane comunque un massimo di 10

            fetchAdditionalNews(offset, limit, 10);
        }
    }, [props.data.component.nr_item_show]);

    const wrapperCSSClasses = props.wrapperCSSClasses ? props.wrapperCSSClasses : null;
    const title = props.title || props.data.component[`title_${locale}`];
    const displayedNews = news.slice(0, requiredNewsCount);

    return (
        <section className={`${styles.wrapper} ${wrapperCSSClasses}`}>
            {title && <h5 className={`font-sans text-uppercase mt-2 mb-3`}>{title}</h5>}
            <ul>
                {displayedNews.map((n, index) => (
                    <li key={index}>
                        <NewsCard top10={true} data={n} />
                        {index !== displayedNews.length - 1 && <hr />}
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default NewsTopTen;
