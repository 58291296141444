import { router } from '@inertiajs/react';

// Hook per aggiornare le props a intervalli
export const useRefreshProps = (propsToRefresh, interval, isTimeout = false) => {
    const updateProps = () => {
        if (typeof propsToRefresh === 'string' && propsToRefresh === '*') {
            router.reload();
        } else {
            router.reload({ only: propsToRefresh });
        }
    };
    const intervalId = isTimeout ? setTimeout(updateProps, interval) : setInterval(updateProps, interval);
    return intervalId;
};